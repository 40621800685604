import utilsParameters from '@/utils/parameters.js'
import getAPI from '@/utils/getData'
import errorMessageUtils from '@/utils/errorMessage'
import blocService from '@/services/bloc.service'
import store from '@/store'
import { HYDRA_MEMBER } from '@/utils/api'
import router from '@/router'
import {
	SET_DATE_RESULTATS_ADMISSIBILITE,
	SET_VERBATIMS,
	SET_ADMISIBILITY_RESULTS,
	SET_ADMISSION_RESULTS,
	SET_RESULTS_NOTE_BENE,
	SET_ADMISSION_PUBLICATION,
	SET_ADMISSION_PUBLICATION_ERROR,
} from './results.mutations'

const state = {
	dateResultatsAdmissibilite: undefined,
	verbatims: [],
	admissibilityResults: undefined,
	admissionResults: undefined,
	resultsNoteBene: [],
	admissionPublication: null,
	admissionPublicationError: null,
}

const mutations = {
	[SET_DATE_RESULTATS_ADMISSIBILITE] (state, payload) {
		state.dateResultatsAdmissibilite = payload ? new Date(payload) : undefined
	},
	[SET_VERBATIMS] (state, payload) {
		state.verbatims = payload
	},
	[SET_RESULTS_NOTE_BENE] (state, payload) {
		state.resultsNoteBene = payload
	},
	[SET_ADMISIBILITY_RESULTS] (state, payload) {
		state.admissibilityResults = payload
	},
	[SET_ADMISSION_RESULTS] (state, payload) {
		state.admissionResults = payload
	},
	[SET_ADMISSION_PUBLICATION] (state, payload) {
		state.admissionPublication = payload
	},
	[SET_ADMISSION_PUBLICATION_ERROR] (state, payload) {
		state.admissionPublicationError = payload
	},
}

const actions = {
	initDateResultatsAdmissibilite ({ commit }) {
		const { dateResultatsAdmissibilite } = state
		if (dateResultatsAdmissibilite) {
			return Promise.resolve(dateResultatsAdmissibilite)
		}
		return getAPI.get('api/parameters?key.name=dateResultatsAdmissibilite')
			.then((res) => {
				const date = utilsParameters.filterProgramChannel(res.data[HYDRA_MEMBER])
				commit(SET_DATE_RESULTATS_ADMISSIBILITE, date.value)
			})
	},
	initVerbatims ({ commit }) {
		blocService.getBloc({ label: 'ADMISSIBILITY_RESULT', key: 'ADMISSIBILITY_RESULT_VERBATIM' })
			.then((res) => commit(SET_VERBATIMS, res.data[HYDRA_MEMBER]))
	},
	initResultsNoteBene ({ commit }) {
		blocService.getBloc({ label: 'RESULTS_NOTE_BENE' })
			.then((res) => commit(SET_RESULTS_NOTE_BENE, res.data[HYDRA_MEMBER]))
	},
	initAdmissibilityResults ({ commit }) {
		commit(SET_ADMISIBILITY_RESULTS, undefined)
		getAPI.getWithToken(`api/students/${store.state.profile.me.student.id}/admissibility_result`)
			.then((res) => {
				commit(SET_ADMISIBILITY_RESULTS, res.data)
			})
	},
	initAdmissionResults ({ commit }) {
		commit(SET_ADMISSION_RESULTS, undefined)
		getAPI.getWithToken(`api/students/${store.state.profile.me.student.id}/admission_result`)
			.then((res) => {
				commit(SET_ADMISSION_RESULTS, res.data)
			})
	},
	initLandingAdmission ({ commit }) {
		const { token } = router.history.current.query
		return getAPI.get(`api/students/landing_admission_publication?token=${token}`)
			.then((res) => {
				commit(SET_ADMISSION_PUBLICATION, res.data)
			})
			.catch((err) => {
				commit(SET_ADMISSION_PUBLICATION_ERROR, errorMessageUtils.getErrorMessage(err))
			})
	},
}

const getters = {
	isBCE (state, getters, rootState) {
		const { programChannel } = state.admissionPublication
		return programChannel.substring(0, 3).toUpperCase() === 'BCE' || false
	},
	isAST (statet, getters) {
		return !getters.isBCE
	},
	admissionResultsStatus (state, getters, rootState) {
		const isAST = store.getters['profile/isAST']
		const admittedString = isAST ? 'Admis' : 'CLASSÉ.E'
		const notAdmittedString = isAST ? 'Non admis' : 'NON CLASSÉ.E'
		return state.admissionResults?.admitted ? admittedString : notAdmittedString
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}

<template>
	<div class="modal card">
		<div
			class="modal__content"
			v-html="props.message"
		></div>
		<div
			class="actions"
			:class="flexGapSupported ? '' : 'actions-rg'">
			<button
				class="red-button"
				@click="noAction"
			>Non</button>
			<button
				class="white-button"
				@click="yesAction"
			>Oui</button>
		</div>
	</div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
	name: 'ModalAccountPayment',
	props: ['props'],
	methods: {
		...mapActions('auth', ['disconnect']),
		...mapMutations('modal', {
			showModal: 'SHOW_MODAL',
			hideModal: 'HIDE_MODAL',
		}),
		noAction () {
			this.props.noAction()
			this.$parent.hideModal()
		},
		yesAction () {
			this.props.yesAction()
			this.$parent.hideModal()
		},
	},
	computed: {
		...mapState('global', ['flexGapSupported']),
	},
}
</script>

<style scoped>
.modal {
    width: 590px;
    padding: 32px;
}
.modal__content {
	text-align: center;
	padding: 20px;
}

.actions {
    display: flex;
    justify-content: center;
    gap: 20px;
}
.actions-rg > *:not(:last-child)  {
	margin-right: 20px;
}
</style>

import { HYDRA_MEMBER } from '@/utils/api'
import getAPI from '@/utils/getData'
import filtersBloc from '@/utils/filtersBloc'
import blocService from '@/services/bloc.service'

const state = {
	campuses: [],
	popinVerbatim: [],
}

const SET_CAMPUSES = 'SET_CAMPUSES'
const SET_POPIN_VERBATIM = 'SET_POPIN_VERBATIM'

const mutations = {
	[SET_CAMPUSES] (state, payload) {
		state.campuses = payload
	},
	[SET_POPIN_VERBATIM] (state, payload) {
		state.popinVerbatim = payload
	},
}

const actions = {
	initCampuses ({ commit }) {
		return getAPI.get('/api/campuses')
			.then((res) => {
				commit(SET_CAMPUSES, res.data[HYDRA_MEMBER])
			})
	},
	initVerbatims ({ commit }) {
		return blocService.getBlocByKey({ key: 'CONTACT_NOTIFICATION' })
			.then((res) => commit(SET_POPIN_VERBATIM, res.data[HYDRA_MEMBER]))
	},
}

const getters = {
	filteredCampuses () {
		return state.campuses.filter((c) => c.assignmentCampus === true)
	},
	filteredNotificationContact () {
		return filtersBloc.filterActive(state.popinVerbatim)
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}

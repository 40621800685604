<template>
	<div class="input-text-container">
		<input
			:class="[isPassword ? 'input-password' : '', errorMsg !== '' ? 'error-input' : '']"
			:type="type"
			:value="value"
			:disabled="disabled"
			:minlength="minlength"
			:maxlength="maxlength"
			v-on="{
				...$listeners,
				input:(event) => updateInput(event.target.value)
			}"
			v-bind="$attrs"
		>
		<p class="error msg">
			{{ errorMsg }}
		</p>
		<span :class="['placeholder', (value !== null && value !== '') ? 'active' : '', errorMsg !== '' ? 'error' : '']">
			{{ placeholder }}
		</span>
		<div @click.stop.prevent="passwordVisibility">
			<component
				v-if="isPassword"
				v-bind:is="type === 'text' ? 'EyeIcon' : 'EyeOffIcon'"
			/>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import EyeIcon from '@/assets/icons/EyeIcon.vue'
import EyeOffIcon from '@/assets/icons/EyeOffIcon.vue'

export default {
	name: 'InputText',
	inheritAttrs: false,
	components: {
		EyeIcon,
		EyeOffIcon,
	},
	props: {
		placeholder: {
			type: String,
			default: 'Je suis un placeholder',
		},
		value: {
			default: '',
		},
		isPassword: {
			type: Boolean,
			default: false,
		},
		regexValidation: {
			type: RegExp,
			default: null,
		},
		isRequired: {
			type: Boolean,
			default: false,
		},
		invalidMsg: {
			type: String,
			default: 'Champ invalide',
		},
		disabled: {
			tybe: Boolean,
			default: false,
		},
		minlength: {
			default: '',
		},
		maxlength: {
			default: '',
		},
	},
	data () {
		return {
			type: this.isPassword ? 'password' : 'text',
			errorMsg: '',
		}
	},
	computed: {
		...mapState('inputsRequirement', ['activateRequired']),
	},
	methods: {
		passwordVisibility () {
			if (this.type === 'text') {
				this.type = 'password'
			} else {
				this.type = 'text'
			}
		},
		updateInput (value) {
			const testValue = value || ''
			if (testValue.toString().match(this.regexValidation) !== null) {
				this.errorMsg = ''
			} else if (testValue === '') {
				this.errorMsg = this.isRequired && this.activateRequired ? 'Ce champ est obligatoire' : ''
			} else if (testValue.length < this.minlength) {
				this.errorMsg = `${this.minlength} caractères minimum`
			} else if (this.regexValidation !== null) {
				this.errorMsg = this.invalidMsg
			} else {
				this.errorMsg = ''
			}
			this.$emit('input', value)
		},
		externalError (msg) {
			this.errorMsg = msg
		},
	},
}
</script>

<style scoped>
.input-text-container {
	position: relative;
	display: flex;
	flex-direction: column;
}
.placeholder {
	position: absolute;
	left: 10px;
	top: 13px;
	pointer-events: none;
	font-family: "Raleway", sans-serif;
	background: white;
	padding: 0 2px;
	height: min-content;
	transition: all 0.2s ease;
	color: var(--grey-2);
}
input:focus ~ .placeholder,
.placeholder.active {
	top: -8px;
	font-size: 13px;
}
input[disabled] {
	background-color: var(--light-grey-1);
	border:  none;
	color: var(--light-grey-2) !important;
}
input[disabled] ~ .placeholder {
	background: transparent !important;
}
.input-password {
	padding-right: 40px;
}
svg {
	position: absolute;
	cursor: pointer;
	width: 24px;
	height: 24px;
	top: 13px;
	right: 15px;
}
.error.msg {
	font-size: 12px;
	padding-left: 12px;
	position: absolute;
    top: 50px;
}
.error {
	color: var(--red-1);
}
@media screen and (min-width: 820px) {
.input-text-container {
	display: flex;
	flex-direction: column;
}
}
</style>

import blocService from '@/services/bloc.service'
import filtersBloc from '@/utils/filtersBloc'
import { HYDRA_MEMBER } from '@/utils/api'

const state = {
	heading: [],
	reasons: {
		title: [],
		cards: [],
		detail: [],
	},
}

const SET_HEADING = 'SET_HEADING'
const SET_REASON_TITLE = 'SET_REASON_TITLE'
const SET_REASON_CARDS = 'SET_REASON_CARDS'
const SET_REASON_DETAIL = 'SET_REASON_DETAIL'

const mutations = {
	[SET_HEADING] (state, payload) {
		state.heading = payload
	},
	[SET_REASON_TITLE] (state, payload) {
		state.reasons.title = payload
	},
	[SET_REASON_CARDS] (state, payload) {
		state.reasons.cards = payload
	},
	[SET_REASON_DETAIL] (state, payload) {
		state.reasons.detail = payload
	},
}

const actions = {
	initHeading ({ commit }) {
		return blocService.getBloc({ label: 'HOME_GE' })
			.then((res) => commit(SET_HEADING, res.data[HYDRA_MEMBER]))
	},
	initReasons ({ commit }) {
		return Promise.all([
			blocService.getBloc({ label: 'HP_RAISON_TITLE' })
				.then((res) => commit(SET_REASON_TITLE, res.data[HYDRA_MEMBER])),

			blocService.getBloc({ label: 'HP_RAISON_CONTENT' })
				.then((res) => commit(SET_REASON_CARDS, res.data[HYDRA_MEMBER])),

			blocService.getBloc({ label: 'HP_RAISON_DETAIL' })
				.then((res) => commit(SET_REASON_DETAIL, res.data[HYDRA_MEMBER])),
		])
	},
}

const getters = {
	filteredCards () {
		return filtersBloc.filterActive(state.heading)
	},
	filteredReasonsTitle () {
		return filtersBloc.filterActive(state.reasons.title)
	},
	filteredReasonsCards () {
		return filtersBloc.filterActive(state.reasons.cards)
	},
	filteredReasonsDetail () {
		return filtersBloc.filterActive(state.reasons.detail)
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}

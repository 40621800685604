import { HYDRA_MEMBER } from '@/utils/api'
import { STUDENT_STATE } from '@/utils/student'
import router from '@/router'
import oralTestStudentService from '@/services/oralTestStudent.service'
import getAPI from '@/utils/getData'
import utilsParameters from '@/utils/parameters.js'
import filtersBloc from '@/utils/filtersBloc'
import blocService from '@/services/bloc.service'
import store from '@/store'
import utilsDate from '@/utils/dates'
import utilsActivityLogs from '@/utils/activityLogs'

const ORAL_TEST_STUDENT_STATE = {
	WAITING_FOR_TREATMENT: 'waiting_for_treatment',
	VALIDATED: 'validated',
	REJECTED: 'rejected',
}

const state = {
	dateOuvertureRDV: undefined,
	dateFermetureRDV: undefined,
	oralTestVerbatim: [],
	oralTestComplementaryInfo: [],
	oralTestNotaBene: [],
	oralTestNoSlotMessage: [],
	oralTestWaitingMessage: [],
	oralTestRejectedMessage: [],
	oralTestNotModifiableMessage: [],
	oralTestNoSlotUnbalanceMessage: [],
	oralTestValid: null,
	dashboardOrauxRecapTitre: [],
	dashboardOrauxRecapEpreuve: [],
	dashboardOrauxPrepa: [],
	campusOralDays: [],
	oralTestStudents: [],
	loading: true,
	oralDateFin: undefined,
}

const SET_DATE_OUVERTURE_RDV = 'SET_DATE_OUVERTURE_RDV'
const SET_DATE_FERMETURE_RDV = 'SET_DATE_FERMETURE_RDV'
const SET_ORAL_TEST_VERBATIM = 'SET_ORAL_TEST_VERBATIM'
const SET_ORAL_TEST_COMPLEMENTARY_INFO = 'SET_ORAL_TEST_COMPLEMENTARY_INFO'
const SET_ORAL_TEST_NOTABENE = 'SET_ORAL_TEST_NOTABENE'
const SET_DASHBOARD_ORAUX_RECAP_TITRE = 'SET_DASHBOARD_ORAUX_RECAP_TITRE'
const SET_DASHBOARD_ORAUX_RECAP_EPREUVE = 'SET_DASHBOARD_ORAUX_RECAP_EPREUVE'
const SET_DASHBOARD_ORAUX_PREPA = 'SET_DASHBOARD_ORAUX_PREPA'
const SET_CAMPUS_ORAL_DAYS = 'SET_CAMPUS_ORAL_DAYS'
const SET_ORAL_TEST_NO_SLOT_MESSAGE = 'SET_ORAL_TEST_NO_SLOT_MESSAGE'
const SET_ORAL_TEST_STUDENTS = 'SET_ORAL_TEST_STUDENTS'
const SET_ORAL_TEST_WAITING_MESSAGE = 'SET_ORAL_TEST_WAITING_MESSAGE'
const SET_ORAL_TEST_REJECTED_MESSAGE = 'SET_ORAL_TEST_REJECTED_MESSAGE'
const SET_ORAL_TEST_NOT_MODIFIABLE_MESSAGE = 'SET_ORAL_TEST_NOT_MODIFIABLE_MESSAGE'
const SET_LOADING = 'SET_LOADING'
const SET_ORAL_TEST_VALID = 'SET_ORAL_TEST_VALID'
const SET_ORAL_DATE_FIN = 'SET_ORAL_DATE_FIN'
const SET_ORAL_TEST_NO_SLOT_UNBALANCE_MESSAGE = 'SET_ORAL_TEST_NO_SLOT_UNBALANCE_MESSAGE'

const mutations = {
	[SET_DATE_OUVERTURE_RDV] (state, payload) {
		state.dateOuvertureRDV = payload
	},
	[SET_DATE_FERMETURE_RDV] (state, payload) {
		state.dateFermetureRDV = payload
	},
	[SET_ORAL_TEST_VERBATIM] (state, payload) {
		state.oralTestVerbatim = payload
	},
	[SET_ORAL_TEST_COMPLEMENTARY_INFO] (state, payload) {
		state.oralTestComplementaryInfo = payload
	},
	[SET_ORAL_TEST_NOTABENE] (state, payload) {
		state.oralTestNotaBene = payload
	},
	[SET_DASHBOARD_ORAUX_RECAP_TITRE] (state, payload) {
		state.dashboardOrauxRecapTitre = payload
	},
	[SET_DASHBOARD_ORAUX_RECAP_EPREUVE] (state, payload) {
		state.dashboardOrauxRecapEpreuve = payload
	},
	[SET_DASHBOARD_ORAUX_PREPA] (state, payload) {
		state.dashboardOrauxPrepa = payload
	},
	[SET_CAMPUS_ORAL_DAYS] (state, payload) {
		state.campusOralDays = payload
	},
	[SET_ORAL_TEST_NO_SLOT_MESSAGE] (state, payload) {
		state.oralTestNoSlotMessage = payload
	},
	[SET_ORAL_TEST_STUDENTS] (state, payload) {
		state.oralTestStudents = payload
	},
	[SET_ORAL_TEST_WAITING_MESSAGE] (state, payload) {
		state.oralTestWaitingMessage = payload
	},
	[SET_ORAL_TEST_REJECTED_MESSAGE] (state, payload) {
		state.oralTestRejectedMessage = payload
	},
	[SET_ORAL_TEST_NOT_MODIFIABLE_MESSAGE] (state, payload) {
		state.oralTestNotModifiableMessage = payload
	},
	[SET_LOADING] (state, payload) {
		state.loading = payload
	},
	[SET_ORAL_TEST_VALID] (state, payload) {
		state.oralTestValid = payload
	},
	[SET_ORAL_DATE_FIN] (state, payload) {
		state.oralDateFin = payload
	},
	[SET_ORAL_TEST_NO_SLOT_UNBALANCE_MESSAGE] (state, payload) {
		state.oralTestNoSlotUnbalanceMessage = payload
	},
}

const redirectOralTestStudents = (oralTestStudent) => {
	if (oralTestStudent.state === ORAL_TEST_STUDENT_STATE.VALIDATED) {
		router.push({ name: 'dashboard' })
	}
}

const actions = {
	initDateResultats ({ commit, getters }) {
		getAPI.get('api/parameters?key.name=dateOuvertureRDV')
			.then((res) => {
				const date = utilsParameters.filterProgramChannel(res.data[HYDRA_MEMBER])
				commit(SET_DATE_OUVERTURE_RDV, date?.value)
			})

		getAPI.get('api/parameters?key.name=dateFermetureRDV')
			.then((res) => {
				const date = utilsParameters.filterProgramChannel(res.data[HYDRA_MEMBER])
				commit(SET_DATE_FERMETURE_RDV, date?.value)
			})

		getAPI.get('api/parameters?key.name=oralDateFin')
			.then((res) => {
				const date = utilsParameters.filterProgramChannel(res.data[HYDRA_MEMBER])
				commit(SET_ORAL_DATE_FIN, date?.value)
			})
	},
	initVerbatims ({ commit, getters }) {
		blocService.getBloc({ label: getters.isASTProgramChannel ? 'ORAL_TEST_AST_VERBATIM' : 'ORAL_TEST_BCE_VERBATIM' })
			.then((res) => commit(SET_ORAL_TEST_VERBATIM, res.data[HYDRA_MEMBER]))
		blocService.getBloc({ label: getters.isASTProgramChannel ? 'ORAL_TEST_AST_COMPLEMENTARY_INFO' : 'ORAL_TEST_BCE_COMPLEMENTARY_INFO' })
			.then((res) => commit(SET_ORAL_TEST_COMPLEMENTARY_INFO, res.data[HYDRA_MEMBER]))
		blocService.getBloc({ label: 'ORAL_TEST_NOTABENE' })
			.then((res) => commit(SET_ORAL_TEST_NOTABENE, res.data[HYDRA_MEMBER]))
		blocService.getBloc({ label: 'ORAL_TEST', key: 'ORAL_TEST_NO_SLOT_MESSAGE' })
			.then((res) => commit(SET_ORAL_TEST_NO_SLOT_MESSAGE, res.data[HYDRA_MEMBER]))
		blocService.getBloc({ label: 'ORAL_TEST', key: 'ORAL_TEST_WAITING_MESSAGE' })
			.then((res) => commit(SET_ORAL_TEST_WAITING_MESSAGE, res.data[HYDRA_MEMBER]))
		blocService.getBloc({ label: 'ORAL_TEST', key: 'ORAL_TEST_REJECTED_MESSAGE' })
			.then((res) => commit(SET_ORAL_TEST_REJECTED_MESSAGE, res.data[HYDRA_MEMBER]))
		blocService.getBloc({ label: 'ORAL_TEST', key: 'ORAL_TEST_NOT_MODIFIABLE_MESSAGE' })
			.then((res) => commit(SET_ORAL_TEST_NOT_MODIFIABLE_MESSAGE, res.data[HYDRA_MEMBER]))

		blocService.getBloc({ label: 'ORAL_TEST', key: 'ORAL_TEST_NO_SLOT_UNBALANCE_MESSAGE' })
			.then((res) => commit(SET_ORAL_TEST_NO_SLOT_UNBALANCE_MESSAGE, res.data[HYDRA_MEMBER]))
	},
	initDashboardOralTests ({ commit, getters }) {
		blocService.getBloc({ label: 'DASHBOARD_ORAUX_RECAP_TITRE', programChannels: [{ id: getters.getProgChan }] })
			.then((res) => {
				commit(SET_DASHBOARD_ORAUX_RECAP_TITRE, res.data[HYDRA_MEMBER])
			})
		blocService.getBloc({ label: 'DASHBOARD_ORAUX_RECAP_EPREUVE', programChannels: [{ id: getters.getProgChan }] })
			.then((res) => {
				commit(SET_DASHBOARD_ORAUX_RECAP_EPREUVE, res.data[HYDRA_MEMBER])
			})
		blocService.getBloc({ label: 'DASHBOARD_ORAUX_PREPA', programChannels: [{ id: getters.getProgChan }] })
			.then((res) => {
				commit(SET_DASHBOARD_ORAUX_PREPA, res.data[HYDRA_MEMBER])
			})
	},
	initOralTests ({ commit, getters }) {
		return new Promise((resolve) => {
			if (store.getters['profile/isRejectedAdmissible']) {
				resolve('student state reject')
				return
			}

			const studentId = store.state.profile.me.student['@id']
			Promise.all([
				oralTestStudentService.getSlotByStudentId(studentId)
					.then((res) => {
						commit(SET_ORAL_TEST_STUDENTS, res.data[HYDRA_MEMBER])
					}),
				getAPI.getWithToken('api/campus_oral_days/available_slots')
					.then((res) => {
						commit(SET_LOADING, false)
						commit(SET_CAMPUS_ORAL_DAYS, res.data[HYDRA_MEMBER])
					}),
			])
				.then(() => {
					if (getters.hasValidOralTestStudent) {
						const oralTest = state.oralTestStudents.find((slot) => slot.state === ORAL_TEST_STUDENT_STATE.VALIDATED)
						getAPI.getWithToken(oralTest['@id'])
							.then((res) => commit(SET_ORAL_TEST_VALID, res.data))
					}
					resolve('init')
				})
		})
	},
	valid ({ commit, dispatch }, campusOralDay) {
		return new Promise((resolve, reject) => {
			oralTestStudentService.valid(campusOralDay)
				.then((res) => {
					const oralTestStudent = res.data
					dispatch('checkStatus', oralTestStudent)
					resolve(res)
				})
				.catch((error) => reject(error))
		})
	},
	cancel ({ commit, dispatch }, id) {
		return new Promise((resolve, reject) => {
			oralTestStudentService.cancel(id).then((res) => resolve(res)).catch((error) => reject(error))
		})
	},
	checkStatus ({ commit, dispatch, getters }, oralTestStudent) {
		const { VALIDATED, REJECTED } = ORAL_TEST_STUDENT_STATE
		store.dispatch('activityLogs/fetchActivityLogs')

		if ([VALIDATED, REJECTED].includes(oralTestStudent.state)) {
			return
		}

		if (oralTestStudent.state === ORAL_TEST_STUDENT_STATE.WAITING_FOR_TREATMENT) {
			store.commit('modal/SHOW_MODAL', {
				name: 'ModalInfos',
				props: {
					message: state.oralTestWaitingMessage[0]?.content ?? 'ORAL_TEST_WAITING_MESSAGE',
					showDashboardButton: false,
					closeIcon: false,
					displayIcon: false,
					loader: true,
				},
			})
		}

		redirectOralTestStudents(oralTestStudent)

		const interval = setInterval(() => {
			oralTestStudentService.checkSlotStatus(oralTestStudent['@id'])
				.then((res) => {
					const { status } = res.data
					if (status !== ORAL_TEST_STUDENT_STATE.WAITING_FOR_TREATMENT) {
						const campusInfos = store.state.contact.campuses.find((c) => c['@id'] === oralTestStudent.campusOralDay.configuration.campus['@id'])

						const activityOutput = {
							...utilsActivityLogs.formatActivityOuput(store.state.activityLogs.activityLogs.activityOutput),
							campusName: campusInfos.name,
							campusCp: campusInfos.postalCode,
							campusCity: campusInfos.city,
							campusCountry: campusInfos.country,
							valid: status === 'validated' ? 'OK' : 'KO',
							eventType: 'RDV',
							date: utilsDate.toDateString(),
						}
						store.dispatch('activityLogs/postActivityLogs', { activityOutput })

						clearInterval(interval)
						store.commit('modal/HIDE_MODAL')
						if (status !== ORAL_TEST_STUDENT_STATE.REJECTED) {
							redirectOralTestStudents({ state: status })
						} else {
							const { label, content } = getters.hasValidOralTestStudent ? getters.filteredOralTestNoSlotUnbalanceMessage[0] : getters.filteredOralTestRejectedMessage[0]
							store.commit('modal/SHOW_MODAL', {
								name: 'ModalError',
								props: {
									title: label || 'Erreur lors de la validation',
									message: content,
								},
							})
							dispatch('initOralTests')
						}
					}
				})
		}, 5000)
	},
}

const getters = {
	isASTProgramChannel () {
		const { key } = store.getters['global/programChannelByIri'](store.state.profile.me.student.programChannel)
		return key && key.slice(0, -1) === 'ast'
	},
	getProgChan (state, getters, rootState) {
		return store.state.profile.me.student.programChannel.slice(-1)
	},
	filteredDashboardOrauxRecapTitre () {
		return filtersBloc.filterActive(state.dashboardOrauxRecapTitre)
	},
	filteredDashboardOrauxRecapEpreuve () {
		return filtersBloc
			.filterActive(state.dashboardOrauxRecapEpreuve)
			.sort((a, b) => a.position - b.position)
	},
	filteredDashboardOrauxPrepa () {
		return filtersBloc.filterActive(state.dashboardOrauxPrepa)
	},
	filteredOralTestNoSlotUnbalanceMessage () {
		return filtersBloc.filterActive(state.oralTestNoSlotUnbalanceMessage)
	},
	hasValidOralTestStudent () {
		return state.oralTestStudents.filter((slot) => slot.state === ORAL_TEST_STUDENT_STATE.VALIDATED).length > 0
	},
	isForbidden (state, getters, rootState) {
		const openDate = state.dateOuvertureRDV
		const closeDate = state.dateFermetureRDV || state.oralDateFin || undefined
		const { state: studentState } = rootState.profile.me.student
		if (!openDate || !closeDate) {
			return true
		}

		const now = new Date().getTime()
		const isOpen = now >= new Date(openDate).getTime() && now <= new Date(closeDate).getTime()

		if (isOpen) {
			if (store.getters['profile/isRejectedAdmissible']) {
				return true
			}

			if ([STUDENT_STATE.admissible, STUDENT_STATE.REGISTERED_EO].includes(studentState)) {
				return false
			}
		}

		return true
	},
	filteredOralTestRejectedMessage () {
		return filtersBloc.filterActive(state.oralTestRejectedMessage)
	},
	filteredOralTestNotModifiableMessage () {
		return filtersBloc.filterActive(state.oralTestNotModifiableMessage)
	},
	distinctCampusesFromOralDays () {
		const { campusOralDays } = state
		const allCampuses = JSON.parse(JSON.stringify(campusOralDays.map((oralDay) => oralDay.configuration.campus)))

		// La méthode reduce() itère sur chaque objet dans le tableau allCampuses.
		// Pour chaque objet, nous vérifions s'il existe déjà dans le tableau résultant en utilisant la méthode find().
		// Si l'objet n'existe pas encore, nous l'ajoutons au tableau résultant en utilisant la méthode push().
		// La méthode reduce() renvoie finalement le tableau résultant qui contient uniquement les objets uniques.
		const uniqueArr = allCampuses.reduce((accumulator, current) => {
			const existingObj = accumulator.find((obj) => obj.id === current.id)
			if (!existingObj) {
				accumulator.push(current)
			}
			return accumulator
		}, [])

		return uniqueArr
	},
	fullCampuses (state, getters, rootState) {
		const availableCampuses = getters.distinctCampusesFromOralDays.map((campus) => campus['@id'])
		const allCampuses = store.getters['contact/filteredCampuses'].map((campus) => campus['@id'])
		const diff = allCampuses.filter((campus) => !availableCampuses.includes(campus))
		return diff.map((id) => {
			const campus = store.getters['contact/filteredCampuses'].find((campus) => campus['@id'] === id)
			return {
				...campus,
				name: `${campus.name} - complet`,
				isFull: true,
			}
		})
	},
	availableAndFullCampuses (state, getters) {
		return [...getters.distinctCampusesFromOralDays, ...getters.fullCampuses]
	},
	availableAndFullCampusesSelectAdapter (state, getters) {
		return getters.availableAndFullCampuses.map((campus) => {
			return {
				label: campus.name,
				selectable: campus.isFull ? !campus.isFull : true,
			}
		})
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
	ORAL_TEST_STUDENT_STATE,
}

import axios from './axios'
import store from '../store'

export default {
	get (query) {
		const headerConfig = {
			'Content-type': 'application/json',
		}
		return axios.get(`${query}`, headerConfig)
	},
	getWithToken (query, token) {
		const headerConfig = {
			'Content-type': 'application/json;charset=UTF-8',
			Authorization: `Bearer ${store.state.auth.token}`,
		}
		return axios.get(`${query}`, { headers: headerConfig })
	},
}

import filtersBloc from '@/utils/filtersBloc'
import getAPI from '@/utils/getData'
import payment from '@/store/modules/payment.js'
import blocService from '@/services/bloc.service'
import { HYDRA_MEMBER } from '@/utils/api'

const state = {
	verbatims: [],
	orders: [],
}

const SET_VERBATIMS = 'SET_VERBATIMS'
const SET_ORDERS = 'SET_ORDERS'

const mutations = {
	[SET_VERBATIMS] (state, payload) {
		state.verbatims = payload
	},
	[SET_ORDERS] (state, payload) {
		state.orders = payload
	},
}

const actions = {
	initVerbatims ({ commit }) {
		return blocService.getBloc({ label: 'HISTORY_ORDERS_PAGE_VERBATIM' })
			.then((res) => commit(SET_VERBATIMS, res.data[HYDRA_MEMBER]))
	},
	getOrders ({ commit }) {
		return getAPI.getWithToken('api/orders')
			.then((res) => commit(SET_ORDERS, res.data[HYDRA_MEMBER]))
	},
}

const getters = {
	filteredVerbatims () {
		return filtersBloc.filterActive(state.verbatims)
	},
	convertOrders () {
		return state.orders.map((order) => {
			return {
				...order,
				type: `${payment.PAYMENT_TYPE[order.type].label} ${order.examSession.examClassification.name}`,
				amount: order.amount / 100,
				state: payment.HISTO_PAYMENT_STATE[order.state],
			}
		})
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}

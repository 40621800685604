import blocService from '@/services/bloc.service'
import store from '@/store'
import deleteAPI from '@/utils/deleteData'
import filtersBloc from '@/utils/filtersBloc'
import getAPI from '@/utils/getData'
import mediaAPI, { MEDIA_CODES } from '@/utils/medias.js'
import { MEDIA_STATUS } from '@/utils/medias.types'
import utilsParameters from '@/utils/parameters.js'
import postAPI from '@/utils/postData'
import putAPI from '@/utils/putData'
import { HYDRA_MEMBER } from '@/utils/api'
import errorMessageUtils from '@/utils/errorMessage'

const BACSUPS_CODES = [
	{ annuel: 'L1', semestriel: ['L1_S1', 'L1_S2'] },
	{ annuel: 'L2', semestriel: ['L2_S3', 'L2_S4'] },
	{ annuel: 'L3', semestriel: ['L3_S5', 'L3_S6'] },
	{ annuel: 'M1', semestriel: ['M1_S1', 'M1_S2'] },
	{ annuel: 'M2', semestriel: ['M2_S3', 'M2_S4'] },
]

function findBacSupDeep (bacSups, srId) {
	let bsIndex = -1
	let isDual = false
	bacSups.forEach((bs, index) => {
		bsIndex = bs['@id'] === srId ? index : -1
		if (bs.dualPathBacSup && bs.dualPathBacSup['@id'] === srId) {
			bsIndex = index
			isDual = true
		}
	})
	return { bsIndex, isDual }
}

function deletePreviousMedia (media) {
	if (media && media.state !== MEDIA_STATUS.STATE_CANCELLED) {
		return mediaAPI.deleteMedia(media['@id'])
	}
	return Promise.resolve()
}
function deletePreviousSchoolReportMedia (bacSup, schoolReportIndex) {
	return deletePreviousMedia(bacSup.schoolReports[schoolReportIndex]?.media)
}

const state = {
	verbatims: [],
	studentCv: [],
	bac: null,
	bacChannels: [],
	bacTypes: [],
	bacOptions: [],
	bacSups: [],
	experiences: [],
	distinctionList: [],
	languages: [],
	startDate: undefined,
	closeDate: undefined,
	expVerbatims: [],
	popinValidations: [],
	endDate: undefined,
	registrationsClosedCvInProgressOfControl: [],
}

const mutations = {
	SET_VERBATIMS (state, payload) {
		state.verbatims = payload
	},
	SET_STUDENT_CV (state, payload) {
		state.studentCv = payload
	},
	SET_BAC (state, payload) {
		state.studentCv.bac = payload
	},
	SET_BAC_SUPS (state, payload) {
		state.studentCv.bacSups.push(payload)
	},
	UPDATE_BAC_SUP (state, payload) {
		const index = state.studentCv.bacSups.findIndex((bs) => bs['@id'] === payload['@id'])
		state.studentCv.bacSups[index] = payload
	},
	SET_BAC_MEDIA (state, payload) {
		state.studentCv.bac.media = [payload]
	},
	SET_BAC_CHANNELS (state, payload) {
		state.bacChannels = payload
	},
	SET_BAC_TYPES (state, payload) {
		state.bacTypes = payload
	},
	SET_BAC_OPTIONS (state, payload) {
		state.bacOptions = payload
	},
	SET_EXPERIENCES (state, payload) {
		state.studentCv.experiences.push(payload)
	},
	SET_SCHOOL_REPORT (state, payload) {
		const { bsIndex, isDual } = findBacSupDeep(state.studentCv.bacSups, payload.bacSup['@id'])
		if (isDual) {
			state.studentCv.bacSups[bsIndex].dualPathBacSup.schoolReports.push(payload.schoolReport)
		} else {
			state.studentCv.bacSups[bsIndex].schoolReports.push(payload.schoolReport)
		}
	},
	UPDATE_SCHOOL_REPORT (state, payload) {
		const { bsIndex, isDual } = findBacSupDeep(state.studentCv.bacSups, payload.bacSup['@id'])
		if (isDual) {
			const srIndex = state.studentCv.bacSups[bsIndex].dualPathBacSup.schoolReports.findIndex((sr) => sr['@id'] === payload.schoolReport['@id'])
			state.studentCv.bacSups[bsIndex].dualPathBacSup.schoolReports[srIndex] = payload.schoolReport
		} else {
			const srIndex = state.studentCv.bacSups[bsIndex].dualPathBacSup.schoolReports.findIndex((sr) => sr['@id'] === payload.schoolReport['@id'])
			state.studentCv.bacSups[bsIndex].schoolReports[srIndex] = payload.schoolReport
		}
	},
	SET_DISTINCTIONS (state, payload) {
		state.distinctionList = payload
	},
	SET_LANGUAGES (state, payload) {
		state.languages = payload
	},
	DELETE_EXPERIENCE (state, payload) {
		const expIndex = state.studentCv.experiences.findIndex((p) => p['@id'] === payload)
		state.studentCv.experiences.splice(expIndex, 1)
	},
	SET_START_DATE (state, payload) {
		state.startDate = new Date(payload)
	},
	SET_CLOSE_DATE (state, payload) {
		state.closeDate = new Date(payload)
	},
	EDIT_EXPERIENCES (state, payload) {
		const expIndex = state.studentCv.experiences.findIndex((p) => p['@id'] === payload.idExp)
		const newData = {
			establishment: payload.establishment,
			timeType: payload.timeType,
			beginAt: payload.beginAt,
			endAt: payload.endAt,
			description: payload.description,
			experienceType: payload.experienceType,
			cv: store.state.profile.me.student.cv,
			hoursPerWeek: parseInt(payload.hoursPerWeek, 10),
			duration: payload.duration,
			'@id': payload.idExp,

		}

		state.studentCv.experiences.splice(expIndex, 1, newData)
	},
	SET_CV_POPIN_VALIDATION (state, payload) {
		state.popinValidations = payload
	},
	SET_END_DATE (state, payload) {
		state.endDate = new Date(payload)
	},
	SET_REGISTRATIONS_CLOSED_CV_IN_PROGRESS_OF_CONTROL (state, payload) {
		state.registrationsClosedCvInProgressOfControl = payload
	},
}

const actions = {
	initCv ({ commit }) {
		const programChannelId = store.state.profile.me.student ? store.getters['profile/programChannel'] : '1'

		blocService.getBloc({ label: 'CV_VERBATIM', programChannels: [{ id: programChannelId }] })
			.then((res) => commit('SET_VERBATIMS', res.data[HYDRA_MEMBER]))

		blocService.getBloc({ label: 'CV_POPIN_VALIDATION' })
			.then((res) => commit('SET_CV_POPIN_VALIDATION', res.data[HYDRA_MEMBER]))
	},
	initCvLanguages ({ commit }) {
		return getAPI.get('api/languages?page=&pagination=false')
			.then((res) => {
				commit('SET_LANGUAGES', res.data[HYDRA_MEMBER])
			})
	},
	async createCv ({ commit }) {
		const postData = {
			student: store.state.profile.me.student['@id'],
			languages: [],
			noProfessionalExperience: false,
			noAssociativeExperience: false,
			noInternationnalExperience: false,
		}
		return postAPI.postWithToken('api/student_cvs', postData).then((res) => {
			commit('SET_STUDENT_CV', res.data)
		})
	},
	async initStudentCv ({ commit }) {
		return getAPI.getWithToken(`${store.state.profile.me.student.cv ?? store.state.cv.studentCv['@id']}`)
			.then((res) => commit('SET_STUDENT_CV', res.data))
	},
	async initDateFinCV ({ commit }) {
		return getAPI.get('api/parameters?key.name=dateFinCV')
			.then((res) => {
				const date = utilsParameters.filterProgramChannel(res.data[HYDRA_MEMBER])
				commit('SET_END_DATE', date.value)
			})
	},
	initDateCV ({ commit }) {
		getAPI.get('api/parameters?key.name=dateDebutInscriptions')
			.then((res) => {
				const date = utilsParameters.filterProgramChannel(res.data[HYDRA_MEMBER])
				commit('SET_START_DATE', date.value)
			})
		getAPI.get('api/parameters?key.name=dateClotureInscriptions')
			.then((res) => {
				const date = utilsParameters.filterProgramChannel(res.data[HYDRA_MEMBER])
				commit('SET_CLOSE_DATE', date.value)
			})
	},
	initBacData ({ commit }) {
		getAPI.get('api/bac_channels')
			.then((res) => {
				commit('SET_BAC_CHANNELS', res.data[HYDRA_MEMBER])
			})
		getAPI.get('api/bac_types')
			.then((res) => {
				commit('SET_BAC_TYPES', res.data[HYDRA_MEMBER])
			})
		getAPI.get('api/bac_options')
			.then((res) => {
				commit('SET_BAC_OPTIONS', res.data[HYDRA_MEMBER])
			})
		getAPI.get('api/bac_distinctions')
			.then((res) => {
				commit('SET_DISTINCTIONS', res.data[HYDRA_MEMBER])
			})
	},
	initRegistrationClosed ({ commit }) {
		const programChannelId = store.state.profile.me.student ? store.state.profile.me.student.programChannel.substr(-1) : '1'

		blocService.getBloc({ label: 'REGISTRATIONS_CLOSED_CV_IN_PROGRESS_OF_CONTROL', programChannels: [{ id: programChannelId }] })
			.then((res) => commit('SET_REGISTRATIONS_CLOSED_CV_IN_PROGRESS_OF_CONTROL', res.data[HYDRA_MEMBER]))
	},
	getBacTypesByFilters ({ commit }, payload) {
		getAPI.get(`api/bac_types?bacChannel=${payload.bacChannelId}&year=${payload.yearBac}`)
			.then((res) => {
				commit('SET_BAC_TYPES', res.data[HYDRA_MEMBER])
			})
	},
	async putBac ({ commit, state, dispatch }, payload) {
		const postData = {
			ine: state.studentCv.bac.ine,
			rewardedYear: state.studentCv.bac.rewardedYear,
			bacDistinction: state.studentCv.bac.bacDistinction,
			bacChannel: state.studentCv.bac.bacChannel,
			bacOption: state.studentCv.bac.bacOption,
			media: null,
			detail: state.studentCv.bac.detail,
			bacTypes: state.studentCv.bac.bacTypes,
			cv: store.state.profile.me.student.cv ?? store.state.cv.studentCv['@id'],
		}

		deletePreviousMedia(state.studentCv.bac?.media)
		await dispatch('postMedia', {
			media: payload,
			mediaCode: MEDIA_CODES.bac,
			commitName: 'SET_BAC_MEDIA',
		})
			.then((res) => {
				postData.media = res['@id']
			})
		putAPI.putWithToken(state.studentCv.bac['@id'], postData).then((res) => {
			commit('SET_BAC', res.data)
			dispatch('initStudentCv')
			return true
		})
	},
	async postBac ({
		commit, state, dispatch, getters,
	}, payload) {
		store.commit('global/SET_LOADER', true)

		const bacChannel = getters.bacChannelsLabelValue
		const postData = {
			ine: payload.ineInput,
			rewardedYear: parseFloat(payload.yearInput, 10),
			bacDistinction: state.distinctionList[payload.bacDisctinctionInput]['@id'],
			bacChannel: bacChannel[payload.bacChannelInput].value,
			bacOption: typeof payload.bacOptionInput === 'number' ? state.bacTypes[0].bacOptions[payload.bacOptionInput]['@id'] : null,
			media: payload.bacMediaInput ? payload.bacMediaInput : null,
			detail: payload.bacDetailInput,
			bacTypes: [
				typeof payload.bacTypeInput === 'number' ? state.bacTypes[payload.bacTypeInput]['@id'] : '',
				typeof payload.bacTypeInput1 === 'number' ? state.bacTypes[payload.bacTypeInput1]['@id'] : '',
				typeof payload.bacTypeInput2 === 'number' ? state.bacTypes[payload.bacTypeInput2]['@id'] : '',
			],
			cv: store.state.profile.me.student.cv ?? store.state.cv.studentCv['@id'],
		}
		let mediaBac = ''
		postData.bacTypes = postData.bacTypes.filter((element) => element !== '')

		if (postData.media) {
			mediaBac = await dispatch('postMedia', {
				media: payload.bacMediaInput,
				mediaCode: MEDIA_CODES.bac,
				commitName: 'SET_BAC_MEDIA',
			})
				.then((res) => {
					postData.media = res['@id']
				})
		}

		try {
			await postAPI.postWithToken('api/bacs', postData).then((res) => {
				commit('SET_BAC', res.data)
				dispatch('initStudentCv')
				return true
			})
		} catch (err) {
			const message = errorMessageUtils.getErrorMessage(err)
			await deletePreviousMedia(mediaBac)
			store.commit('global/SET_LOADER', false)
			throw new Error(message)
		}

		store.commit('global/SET_LOADER', false)
	},
	async postBacSup ({
		commit, state, dispatch, getters,
	}, payload) {
		const diplomaList = store.getters['global/filteredDiplomas'](payload.programChannel, payload.isDual)
		const postDiploma = diplomaList[payload.diploma]
		const { isDual } = payload
		const { parent } = payload
		const bacSupCode = BACSUPS_CODES[parent ? getters.getBacSupByIndexId(parent) : state.studentCv.bacSups.length]
		let mediaYear = ''
		let mediaSem1 = ''
		let mediaSem2 = ''

		const postData = {
			cv: state.studentCv['@id'],
			year: parseInt(payload.year, 10),
			diploma: postDiploma['@id'],
			diplomaChannel: payload.diplomaChannel === '' || payload.diplomaChannel === -1 ? null : postDiploma.diplomaChannels[payload.diplomaChannel]['@id'],
			detail: payload.detail === '' ? null : payload.detail,
			establishment: payload.school,
			postalCode: payload.postalCode,
			city: payload.city,
			country: store.getters['global/countryByIndex'](payload.country)['@id'],
			type: payload.bacSupTypeInput,
			dualPathBacSup: payload.dualPathBacSup ? payload.dualPathBacSup['@id'] : undefined,
			parent: payload.parent,
		}
		delete postData.bacSupMediaInput1
		delete postData.bacSupMediaInput2
		delete postData.bacSupScoreInput1
		delete postData.bacSupScoreInput2
		delete postData.bacSupTypeInput
		delete postData.isDual

		// Upload medias for error checking before creating bacSup
		if (payload.bacSupTypeInput === 'annuel') {
			if (payload.bacSupMediaInput1) {
				mediaYear = await dispatch('postMedia', {
					media: payload.bacSupMediaInput1,
					mediaCode: bacSupCode.annuel,
				})
			}
		} else {
			if (payload.bacSupMediaInput1) {
				mediaSem1 = await dispatch('postMedia', {
					media: payload.bacSupMediaInput1,
					mediaCode: bacSupCode.semestriel[0],
				})
			}

			if (payload.bacSupMediaInput2) {
				mediaSem2 = await dispatch('postMedia', {
					media: payload.bacSupMediaInput2,
					mediaCode: bacSupCode.semestriel[1],
				})
			}
		}

		// create bacsup
		let bacSup = {}
		try {
			bacSup = await postAPI.postWithToken('api/bac_sups', postData)
		} catch (err) {
			const message = errorMessageUtils.getErrorMessage(err)

			if (mediaYear) {
				await deletePreviousMedia(mediaYear)
			} else {
				await deletePreviousMedia(mediaSem1)
				await deletePreviousMedia(mediaSem2)
			}

			throw new Error(message)
		}

		bacSup.data.dualPathBacSup = payload.dualPathBacSup
		const resolveBacSup = JSON.parse(JSON.stringify(bacSup))
		if (!isDual) {
			await commit('SET_BAC_SUPS', bacSup.data)
		}

		if (payload.bacSupTypeInput === 'annuel') {
			if (payload.bacSupMediaInput1) {
				try {
					// post school_reports
					const sr1 = await postAPI.postWithToken('api/school_reports', {
						score: parseFloat(payload.bacSupScoreInput1, 10),
						media: mediaYear['@id'],
						bacSup: bacSup.data['@id'],
						additionnal: true,
						scoreNotOutOfTwenty: payload.scoreNotOutOfTwenty1,
					})

					resolveBacSup.data.schoolReports = [sr1.data]
					if (!isDual) {
						await commit('SET_SCHOOL_REPORT', { bacSup: bacSup.data, schoolReport: sr1.data })
					}
				} catch (err) {
					await deletePreviousMedia(mediaYear)
				}
			}
		} else {
			try {
				let sr1 = null
				let sr2 = null
				// post school_reports
				sr1 = await postAPI.postWithToken('api/school_reports', {
					score: parseFloat(payload.bacSupScoreInput1, 10),
					media: mediaSem1['@id'],
					bacSup: bacSup.data['@id'],
					additionnal: true,
					scoreNotOutOfTwenty: payload.scoreNotOutOfTwenty1,
				})

				if (!isDual) {
					await commit('SET_SCHOOL_REPORT', { bacSup: bacSup.data, schoolReport: sr1.data })
				}

				// post school_reports
				sr2 = await postAPI.postWithToken('api/school_reports', {
					score: parseFloat(payload.bacSupScoreInput2, 10),
					media: mediaSem2['@id'],
					bacSup: bacSup.data['@id'],
					additionnal: true,
					scoreNotOutOfTwenty: payload.scoreNotOutOfTwenty2,
				})

				if (!isDual) {
					await commit('SET_SCHOOL_REPORT', { bacSup: bacSup.data, schoolReport: sr2.data })
				}

				if (sr1?.data && !sr2?.data) {
					resolveBacSup.data.schoolReports = [sr1.data]
				} else if (!sr1?.data && sr2?.data) {
					resolveBacSup.data.schoolReports = [undefined, sr2.data]
				} else if (sr1?.data && sr2?.data) {
					resolveBacSup.data.schoolReports = [sr1.data, sr2.data]
				}
			} catch (err) {
				await deletePreviousMedia(mediaSem1)
				await deletePreviousMedia(mediaSem2)
			}
		}
		return Promise.resolve(resolveBacSup.data)
	},
	async putBacSup ({ commit, state, dispatch }, payload) {
		const {
			selectedBacSupIndex,
			bacSupTypeInput,
			bacSupMediaInput1,
			bacSupMediaInput2,
			bacSupScoreInput1,
			scoreNotOutOfTwenty1,
			bacSupScoreInput2,
			scoreNotOutOfTwenty2,
			isDual,
			previousDualPathBacSup,
		} = payload

		const storeBacSup = isDual ? state.studentCv.bacSups[selectedBacSupIndex].dualPathBacSup : state.studentCv.bacSups[selectedBacSupIndex]
		let media1 = storeBacSup.schoolReports[0]?.media || null
		if (payload.bacSupTypeInput === 'annuel') {
			const mediaCode1 = BACSUPS_CODES[selectedBacSupIndex].annuel
			let sr = null
			if (isDual) {
				sr = state.studentCv.bacSups[selectedBacSupIndex].dualPathBacSup?.schoolReports
			} else {
				sr = state.studentCv.bacSups[selectedBacSupIndex].schoolReports
			}
			// const sr1 = isDual ? state.studentCv.bacSups[selectedBacSupIndex].dualPathBacSup?.schoolReports : state.studentCv.bacSups[selectedBacSupIndex].schoolReports
			media1 = sr.length > 0 ? sr.find((sr) => sr?.media?.code === mediaCode1)?.media : null
		}

		// let media2 = storeBacSup.schoolReports.length === 2 ? storeBacSup.schoolReports[1]?.media : null
		let media2 = null
		if (payload.bacSupTypeInput === 'semestriel') {
			const mediaCode1 = BACSUPS_CODES[selectedBacSupIndex].semestriel[0]
			// const sr1 = isDual ? state.studentCv.bacSups[selectedBacSupIndex].dualPathBacSup?.schoolReports : state.studentCv.bacSups[selectedBacSupIndex].schoolReports
			let srs = null
			if (isDual) {
				srs = state.studentCv.bacSups[selectedBacSupIndex].dualPathBacSup?.schoolReports
			} else {
				srs = state.studentCv.bacSups[selectedBacSupIndex].schoolReports
			}
			media1 = srs.length > 0 ? srs.find((sr) => sr?.media?.code === mediaCode1)?.media : null

			const mediaCode2 = BACSUPS_CODES[selectedBacSupIndex].semestriel[1]
			// const sr2 = isDual ? state.studentCv.bacSups[selectedBacSupIndex].dualPathBacSup?.schoolReports : state.studentCv.bacSups[selectedBacSupIndex].schoolReports
			media2 = srs.length > 0 ? srs.find((sr) => sr?.media?.code === mediaCode2)?.media : null
		}

		// delete dualPathBacSup ?
		if (!isDual && !previousDualPathBacSup) {
			const previousDualPathBacSupId = state.studentCv.bacSups[selectedBacSupIndex].dualPathBacSup ? state.studentCv.bacSups[selectedBacSupIndex].dualPathBacSup['@id'] : null
			if (previousDualPathBacSupId) {
				await deleteAPI.deleteWithToken(`${previousDualPathBacSupId}`)
			}
		}

		// UPLOAD MEDIA1
		if (bacSupMediaInput1?.name) {
			deletePreviousMedia(media1)
			// deletePreviousSchoolReportMedia(storeBacSup, 0)
			media1 = await dispatch('postMedia', {
				media: bacSupMediaInput1,
				mediaCode: bacSupTypeInput === 'annuel' ? BACSUPS_CODES[selectedBacSupIndex].annuel : BACSUPS_CODES[selectedBacSupIndex].semestriel[0],
			})
		} else if (typeof bacSupMediaInput1 !== 'string') {
			media1 = null
		}

		// UPLOAD MEDIA2
		if (bacSupMediaInput2?.name) {
			deletePreviousMedia(media2)
			// deletePreviousSchoolReportMedia(storeBacSup, 1)
			media2 = await dispatch('postMedia', {
				media: bacSupMediaInput2,
				mediaCode: BACSUPS_CODES[selectedBacSupIndex].semestriel[1],
			})
		} else if (typeof bacSupMediaInput2 !== 'string') {
			media2 = null
		}

		let sr1
		if (payload.bacSupTypeInput === 'annuel') {
			// PUT SCHOOLREPORT1
			// const schoolReportId = storeBacSup.schoolReports[0] ? storeBacSup.schoolReports[0]['@id'] : null
			const idx = storeBacSup.schoolReports.findIndex((sr) => sr.media?.code === BACSUPS_CODES[payload.selectedBacSupIndex].annuel)
			const schoolReportId = idx > -1 ? storeBacSup.schoolReports[idx]['@id'] : null
			if ((typeof media1?.file === 'string' || media1 === null) && schoolReportId) {
				sr1 = await putAPI.putWithToken(schoolReportId, {
					score: parseFloat(bacSupScoreInput1, 10),
					media: media1 ? media1['@id'] : null,
					bacSup: storeBacSup['@id'],
					additionnal: false,
					scoreNotOutOfTwenty: scoreNotOutOfTwenty1 || false,
				})
			} else if (media1) {
				sr1 = await postAPI.postWithToken('api/school_reports', {
					score: parseFloat(bacSupScoreInput1, 10),
					media: media1 ? media1['@id'] : null,
					bacSup: storeBacSup['@id'],
					additionnal: false,
					scoreNotOutOfTwenty: scoreNotOutOfTwenty1 || false,
				})
			}
		}

		// PUT SCHOOLREPORT2
		let sr2
		if (payload.bacSupTypeInput === 'semestriel') {
			// PUT SCHOOLREPORT1
			// const schoolReportId = storeBacSup.schoolReports[0] ? storeBacSup.schoolReports[0]['@id'] : null
			const idx1 = storeBacSup.schoolReports.findIndex((sr) => sr.media?.code === BACSUPS_CODES[payload.selectedBacSupIndex].semestriel[0])
			const schoolReport1 = idx1 > -1 ? storeBacSup.schoolReports[idx1] : null
			const schoolReportId1 = schoolReport1 ? schoolReport1['@id'] : null
			if ((typeof media1?.file === 'string' || media1 === null) && schoolReportId1 && (bacSupScoreInput1 || scoreNotOutOfTwenty1)) {
				sr1 = await putAPI.putWithToken(schoolReportId1, {
					score: parseFloat(bacSupScoreInput1, 10),
					// media: schoolReport1.media ? schoolReport1.media['@id'] : null,
					media: media1 ? media1['@id'] : null,
					bacSup: storeBacSup['@id'],
					additionnal: false,
					scoreNotOutOfTwenty: scoreNotOutOfTwenty1 || false,
				})
				media1 = sr1.data.media
			} else if (media1 && (bacSupScoreInput1 || scoreNotOutOfTwenty1)) {
				sr1 = await postAPI.postWithToken('api/school_reports', {
					score: parseFloat(bacSupScoreInput1, 10),
					media: media1 ? media1['@id'] : null,
					bacSup: storeBacSup['@id'],
					additionnal: false,
					scoreNotOutOfTwenty: scoreNotOutOfTwenty1 || false,
				})
			}

			// const schoolReportId2 = storeBacSup.schoolReports[1] ? storeBacSup.schoolReports[1]['@id'] : null

			const idx2 = storeBacSup.schoolReports.findIndex((sr) => sr.media?.code === BACSUPS_CODES[payload.selectedBacSupIndex].semestriel[1])
			const schoolReport2 = idx2 > -1 ? storeBacSup.schoolReports[idx2] : null
			const schoolReportId2 = schoolReport2 ? schoolReport2['@id'] : null

			if ((typeof media2?.file === 'string' || media2 === null) && schoolReportId2 && sr1) {
				sr2 = await putAPI.putWithToken(schoolReportId2, {
					score: parseFloat(bacSupScoreInput2, 10),
					// media: schoolReport2.media ? schoolReport2.media['@id'] : null,
					media: media2 ? media2['@id'] : null,
					bacSup: storeBacSup['@id'],
					additionnal: false,
					scoreNotOutOfTwenty: scoreNotOutOfTwenty2 || false,
				})
				media2 = sr2.data.media
			} else if (media2 && sr1) {
				sr2 = await postAPI.postWithToken('api/school_reports', {
					score: parseFloat(bacSupScoreInput2, 10),
					media: media2 ? media2['@id'] : null,
					bacSup: storeBacSup['@id'],
					additionnal: false,
					scoreNotOutOfTwenty: scoreNotOutOfTwenty2 || false,
				})
			} else if (media2 && !sr1) {
				throw new Error('Bulletin semestre 2 : un bulletin pour le semestre 1 est nécessaire')
			}
		}

		// PUT BACSUP
		const diplomaList = store.getters['global/filteredDiplomas'](payload.programChannel, isDual)
		const putDiploma = diplomaList[payload.diploma]
		const putSchoolReports = []
		if (sr1) {
			const sr1id = sr1.data ? sr1.data['@id'] : sr1
			putSchoolReports.push(sr1id)
		}
		if (sr2) {
			const sr2id = sr2.data ? sr2.data['@id'] : sr2
			putSchoolReports.push(sr2id)
		}
		const putData = {
			cv: state.studentCv['@id'],
			year: parseInt(payload.year, 10),
			diploma: putDiploma['@id'],
			diplomaChannel: payload.diplomaChannel === '' || payload.diplomaChannel === -1 ? null : putDiploma.diplomaChannels[payload.diplomaChannel]['@id'],
			detail: payload.detail === '' ? null : payload.detail,
			establishment: payload.school,
			postalCode: payload.postalCode,
			city: payload.city,
			country: store.getters['global/countryByIndex'](payload.country)['@id'],
			type: payload.bacSupTypeInput,
			dualPathBacSup: payload.previousDualPathBacSup ? payload.previousDualPathBacSup['@id'] : null,
			parent: payload.parent,
			schoolReports: putSchoolReports,
		}
		delete putData.bacSupMediaInput1
		delete putData.bacSupMediaInput2
		delete putData.bacSupScoreInput1
		delete putData.bacSupScoreInput2
		delete putData.bacSupTypeInput
		delete putData.isDual

		let newBacSup = {}
		try {
			newBacSup = await putAPI.putWithToken(storeBacSup['@id'], putData).catch((err) => Promise.reject(err))
		} catch (err) {
			const message = errorMessageUtils.getErrorMessage(err)

			if (media1) {
				await deletePreviousMedia(media1)
			}

			if (media2) {
				await deletePreviousMedia(media2)
			}

			throw new Error(message)
		}

		if (media1) {
			const idx1 = newBacSup.data.schoolReports.findIndex((sr) => sr.media === media1['@id'])
			newBacSup.data.schoolReports[idx1].media = media1
		}

		if (media2) {
			const idx2 = newBacSup.data.schoolReports.findIndex((sr) => sr.media === media2['@id'])
			newBacSup.data.schoolReports[idx2].media = media2
		}

		if (!isDual) {
			if (previousDualPathBacSup) {
				newBacSup.data.dualPathBacSup = previousDualPathBacSup
			}
			commit('UPDATE_BAC_SUP', newBacSup.data)
		}

		return Promise.resolve(newBacSup.data)
	},
	getDuration ({ commit }, exp) {
		return postAPI.postWithToken('/api/experiences/duration', exp)
	},
	postExperience ({
		commit, state, dispatch, getters,
	}, payload) {
		return new Promise((resolve, reject) => {
			const postData = {
				establishment: payload.establishment,
				timeType: payload.timeType,
				beginAt: new Date(payload.beginAt).toISOString(),
				endAt: new Date(payload.endAt).toISOString(),
				description: payload.description,
				experienceType: payload.experienceType,
				cv: state.studentCv['@id'],
				hoursPerWeek: parseInt(payload.hoursPerWeek, 10),
				duration: payload.duration,

			}

			postAPI.postWithToken('api/experiences', postData).then((res) => {
				commit('SET_EXPERIENCES', res.data)
				resolve()
			})
				.catch((err) => reject(err))
		})
	},
	async deleteExperience ({
		commit, state, dispatch, getters,
	}, payload) {
		deleteAPI.deleteWithToken(`${payload}`).then((res) => commit('DELETE_EXPERIENCE', payload))
	},
	putExperience ({
		commit, state, dispatch, getters,
	}, payload) {
		return new Promise((resolve, reject) => {
			const postData = {
				establishment: payload.establishment,
				timeType: payload.timeType,
				beginAt: new Date(payload.beginAt).toISOString(),
				endAt: new Date(payload.endAt).toISOString(),
				description: payload.description,
				experienceType: payload.experienceType,
				cv: state.studentCv['@id'],
				hoursPerWeek: parseInt(payload.hoursPerWeek, 10),
				duration: payload.duration,

			}
			putAPI.putWithToken(`${payload.idExp}`, postData).then((res) => {
				commit('EDIT_EXPERIENCES', payload)

				resolve()
			})
		})
	},
	postMedia ({ commit }, payload) {
		return new Promise((resolve, reject) => {
			if (payload.media && payload.media['@id']) {
				resolve(payload.media['@id'])
			}

			mediaAPI.postMedia(
				payload.media,
				payload.mediaCode,
			).then((res) => {
				// commit(payload.commitName, res)
				resolve(res)
			}).catch((err) => {
				reject(err)
				store.commit('global/SET_LOADER', false)
			})
		})
	},
	async postMissingMedia ({ commit, dispatch }, payload) {
		// upload media
		const media = await dispatch('postMedia', {
			media: payload.media,
			mediaCode: payload.mediaCode,
		})

		let sr1 = null
		if (payload.schoolReport['@id']) {
			sr1 = payload.schoolReport
			// put school_reports
			sr1 = await putAPI.putWithToken(sr1['@id'], {
				score: parseFloat(payload.score, 10),
				media: media['@id'],
				bacSup: payload.bacSup['@id'],
				additionnal: true,
				scoreNotOutOfTwenty: payload.scoreNotOutOfTwenty,
			})
			sr1.media = media
			await commit('UPDATE_SCHOOL_REPORT', { bacSup: payload.bacSup, schoolReport: sr1.data })
		} else {
			// post school_reports
			sr1 = await postAPI.postWithToken('api/school_reports', {
				score: parseFloat(payload.score, 10),
				media: media['@id'],
				bacSup: payload.bacSup['@id'],
				additionnal: true,
				scoreNotOutOfTwenty: payload.scoreNotOutOfTwenty,
			})
			sr1.media = media
			await commit('SET_SCHOOL_REPORT', { bacSup: payload.bacSup, schoolReport: sr1.data })
		}
	},
	saveCv ({ commit, dispatch }, payload) {
		return new Promise((resolve, reject) => {
			const selectedLanguages = payload.languages.slice()
			const selectedExperiences = state.studentCv.experiences.slice()
			const selectedBacSups = state.studentCv.bacSups.slice()
			selectedLanguages.forEach((element, index) => {
				selectedLanguages[index] = element.value
			})
			selectedExperiences.forEach((element, index) => {
				selectedExperiences[index] = element['@id']
			})
			selectedBacSups.forEach((element, index) => {
				selectedBacSups[index] = element['@id']
			})
			const postData = {
				languages: selectedLanguages,
				noProfessionalExperience: payload.noProfessionalExperience,
				noAssociativeExperience: payload.noAssociativeExperience,
				noInternationnalExperience: payload.noInternationnalExperience,
			}
			putAPI.putWithToken(`${state.studentCv['@id']}`, postData)
				.then((res) => {
					commit('SET_STUDENT_CV', res.data)
					resolve()
				})
		})
	},
	validateCv ({ commit, state }) {
		return postAPI.postWithToken(`${state.studentCv['@id']}/validate`, {})
	},
}

function getBacChannelByKey (key) {
	return state?.bacChannels?.find((bc) => bc.key === key)['@id'].split('/').at(-1) ?? ''
}

const getters = {
	TECHNOLOGIC_ID () {
		return getBacChannelByKey('technologique')
	},
	PROFESSIONAL_ID () {
		return getBacChannelByKey('professional')
	},
	GENERAL_ID () {
		return getBacChannelByKey('general')
	},
	bacChannelsLabelValue () {
		return state.bacChannels.map((bc) => ({ label: bc.name, value: bc['@id'] }))
	},
	bacTypesLabelValue () {
		return state.bacTypes.map((bt) => ({ label: bt.name, value: bt['@id'] }))
	},
	bacOptionsLabelValue () {
		return state.bacOptions.map((bo) => ({ label: bo.name, value: bo['@id'] }))
	},
	languagesMultiSelectOptions () {
		return state.languages.map((l) => ({ label: l.label, value: l['@id'] }))
	},
	filteredPopinValidations () {
		return filtersBloc.filterActive(state.popinValidations)
	},
	getBacSupByIndex () {
		return (index) => {
			return state.studentCv.bacSups[index]
		}
	},
	getDualPathBacSupByIndex () {
		return (index) => {
			return state.studentCv.bacSups[index].dualPathBacSup
		}
	},
	getBacSupByIndexId () {
		return (id) => state.studentCv.bacSups.findIndex((bs) => bs['@id'] === id)
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}

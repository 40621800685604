import VueMask from 'v-mask'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Importation du CSS
import '@/assets/css/animations.css'
import '@/assets/css/class.css'
import '@/assets/css/fonts.css'
import '@/assets/css/global.css'
import '@/assets/css/variables.css'

Vue.config.productionTip = false
Vue.use(VueMask)

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app')

import axios from './axios'
import store from '../store'

export default {
	post (query, payload) {
		const headerConfig = {
			'Content-type': 'application/json',
		}
		return axios.post(`${query}`, payload, headerConfig)
	},
	postWithToken (query, payload) {
		const headerConfig = {
			'Content-type': 'application/json;charset=UTF-8',
			Authorization: `Bearer ${store.state.auth.token}`,
		}
		return axios.post(`${query}`, payload, { headers: headerConfig })
	},
	postPayment (query, payload) {
		const headerConfig = {
			'Content-type': 'application/json;charset=UTF-8',
			Authorization: `Bearer ${store.state.auth.token}`,
		}
		return axios.post(`${query}`, payload, {
			validateStatus: (status) => status < 500,
			headers: headerConfig,
		})
	},
}

import blocService from '@/services/bloc.service'
import filtersBloc from '@/utils/filtersBloc'
import getAPI from '@/utils/getData'
import postAPI from '@/utils/postData'
import putAPI from '@/utils/putData'
import store from '@/store'

const state = {
	ptv: {
		state: '',
		studentAccommodation: {},
		studentLunchs: [],
		studentTransports: [],
		diet: {},
		infoOtherDiet: '',
	},
	transports: [],
	accommodations: [],
	lunches: [],
	diets: [],
	blocDashboardPTV: [],
	blocDashboardPTVIncomplet: [],
	blocRAZPTV: [],
	blocHebergementPTV: [],
	blocRepasPayantPTV: [],
}

const SET_PTV = 'SET_PTV'
const SET_PTV_TRANSPORTS = 'SET_PTV_TRANSPORT'
const SET_PTV_ACCOMMODATIONS = 'SET_PTV_ACCOMMODATIONS'
const SET_PTV_LUNCHES = 'SET_PTV_LUNCHES'
const SET_PTV_DIETS = 'SET_PTV_DIETS'
const SET_BLOC_DASHBOARD_PTV = 'SET_BLOC_DASHBOARD_PTV'
const SET_BLOC_DASHBOARD_PTV_INCOMPLET = 'SET_BLOC_DASHBOARD_PTV_INCOMPLET'
const SET_BLOC_RAZ_PTV = 'SET_BLOC_RAZ_PTV'
const SET_BLOC_HEBERGEMENT_PTV = 'SET_BLOC_HEBERGEMENT_PTV'
const SET_BLOC_REPAS_PAYANT_PTV = 'SET_BLOC_REPAS_PAYANT_PTV'

const mutations = {
	[SET_PTV] (state, payload) {
		state.ptv = payload
	},
	[SET_BLOC_DASHBOARD_PTV] (state, payload) {
		state.blocDashboardPTV = payload
	},
	[SET_BLOC_DASHBOARD_PTV_INCOMPLET] (state, payload) {
		state.blocDashboardPTVIncomplet = payload
	},
	[SET_BLOC_RAZ_PTV] (state, payload) {
		state.blocRAZPTV = payload
	},
	[SET_BLOC_HEBERGEMENT_PTV] (state, payload) {
		state.blocHebergementPTV = payload
	},
	[SET_BLOC_REPAS_PAYANT_PTV] (state, payload) {
		state.blocRepasPayantPTV = payload
	},
	[SET_PTV_TRANSPORTS] (state, payload) {
		state.transports = payload
	},
	[SET_PTV_ACCOMMODATIONS] (state, payload) {
		state.accommodations = payload
	},
	[SET_PTV_LUNCHES] (state, payload) {
		state.lunches = payload
	},
	[SET_PTV_DIETS] (state, payload) {
		state.diets = payload
	},
}

const HYDRA_MEMBER = 'hydra:member'

const actions = {
	initPTV ({ commit, getters }) {
		getAPI.getWithToken('api/transports')
			.then((res) => commit(SET_PTV_TRANSPORTS, res.data[HYDRA_MEMBER]))

		getAPI.getWithToken('api/accommodations')
			.then((res) => commit(SET_PTV_ACCOMMODATIONS, res.data[HYDRA_MEMBER]))

		getAPI.getWithToken('api/lunches')
			.then((res) => commit(SET_PTV_LUNCHES, res.data[HYDRA_MEMBER]))

		getAPI.getWithToken('api/diets')
			.then((res) => commit(SET_PTV_DIETS, res.data[HYDRA_MEMBER]))

		blocService.getBloc({ label: 'PTV_HEBERGEMENT_INFO' })
			.then((res) => {
				commit(SET_BLOC_HEBERGEMENT_PTV, res.data[HYDRA_MEMBER])
			})

		blocService.getBloc({ label: 'PTV_CAMPUS_REPAS_PAYANT' })
			.then((res) => {
				commit(SET_BLOC_REPAS_PAYANT_PTV, res.data[HYDRA_MEMBER])
			})
	},
	getStudentPTV ({ commit }) {
		getAPI.getWithToken(`${store.state.profile.me.student.activeStudentPTV}`)
			.then((res) => commit(SET_PTV, res.data))
	},
	blocDashboardPTV ({ commit }) {
		blocService.getBloc({ label: 'DASHBOARD_PTV', key: 'GO_TO_PTV' })
			.then((res) => {
				commit(SET_BLOC_DASHBOARD_PTV, res.data[HYDRA_MEMBER])
			})
	},
	blocDashboardPTVIncomplet ({ commit }) {
		blocService.getBloc({ label: 'PTV_DASHBOARD_INCOMPLET' })
			.then((res) => {
				commit(SET_BLOC_DASHBOARD_PTV_INCOMPLET, res.data[HYDRA_MEMBER])
			})
	},
	blocRAZPTV ({ commit }) {
		blocService.getBloc({ label: 'PTV_MESSAGE', key: 'PTV_RAZ_MESSAGE' })
			.then((res) => {
				commit(SET_BLOC_RAZ_PTV, res.data[HYDRA_MEMBER])
			})
	},
	async postPTV ({
		commit, state, dispatch, getters,
	}, payload) {
		const ptv = await postAPI.postWithToken('api/ptvs', payload).catch((err) => Promise.reject(err))
		commit('SET_PTV', ptv.data)
	},
	validatePTV () {
		return putAPI.putWithToken(`${store.state.profile.me.student.activeStudentPTV}/terminate`, {})
	},
	async putPTV ({
		commit, state, dispatch, getters,
	}, payload) {
		await putAPI.putWithToken(store.state.profile.me.student.activeStudentPTV, payload)
	},
}

const getters = {
	filteredBlocDashboardPTV () {
		return filtersBloc.filterActive(state.blocDashboardPTV)
	},
	filteredBlocDashboardPTVIncomplet () {
		return filtersBloc.filterActive(state.blocDashboardPTVIncomplet)
	},
	filteredBlocRAZPTV () {
		return filtersBloc.filterActive(state.blocRAZPTV)
	},
	filteredBlocHebergementPTV () {
		return filtersBloc.filterActive(state.blocHebergementPTV)
	},
	filteredBlocRepasPayantPTV () {
		return filtersBloc.filterActive(state.blocRepasPayantPTV)
	},
	formattedTransports () {
		const formattedTransports = []
		const transportsGroupedByType = Map.groupBy(state.transports, (transport) => transport.type)
		transportsGroupedByType.forEach((transport) => {
			formattedTransports.push({ label: transport[0].typeLabel, value: transport[0].type })
		})

		return formattedTransports
	},
	formattedTrains () {
		return state.transports.filter((t) => t.type === 'train')
	},
	formattedAircrafts () {
		return state.transports.filter((t) => t.type === 'aircraft')
	},
	formattedOwnMeans () {
		return state.transports.filter((t) => t.type === 'own_means')
	},
	formattedDiets () {
		if (state.diets.length > 0) {
			const diets = [{ label: 'Aucun', value: '0' }]
			return [...diets, ...state.diets.map((d, index) => ({ label: d.label, value: index + 1 }))]
		}

		return []
	},
	getTransportIndexById () {
		return (data, transportType) => {
			return transportType.findIndex((t) => t['@id'] === data.transport)
		}
	},
	getTerminalIndexById () {
		return (data, transportType) => {
			return transportType.findIndex((t) => t['@id'] === data.transport)
		}
	},
	getAccommodationIndexById () {
		return (accommodation) => {
			return state.accommodations.findIndex((a) => a['@id'] === accommodation?.accommodation['@id'])
		}
	},
	getDietIndexById () {
		return (id) => {
			return state.diets.findIndex((d) => d['@id'] === id) + 1
		}
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}

import Vue from 'vue'
import VueRouter from 'vue-router'
import { STUDENT_STATE } from '@/utils/student'
import store from '../store'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'home',
		component: HomeView,
		beforeEnter: (to, from, next) => {
			const isAuthenticated = store.getters['auth/isAuthenticated']
			if (isAuthenticated) {
				next('/dashboard')
			} else {
				store.dispatch('home/initHeading')
				store.dispatch('home/initReasons')
				store.commit('global/SET_LAST_ROUTE', '')
				next()
			}
		},
		meta: {
			displayLoader: false,
		},
	},
	{
		path: '/ast',
		name: 'ast',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "ast" */ '../views/ProgramChannelsView.vue'),
		beforeEnter: (to, from, next) => {
			store.dispatch('global/initProgramChannels')
				.then(() => {
					store.dispatch('programChannels/initHeadingAst')
					store.dispatch('programChannels/initInfosContest')
					store.dispatch('programChannels/initInfos')
					store.dispatch('programChannels/initDocuments')
					store.dispatch('programChannels/initCalendar')
				})
			store.commit('global/SET_LAST_ROUTE', 'ast')
			next()
		},
		meta: {
			displayLoader: false,
		},
	},
	{
		path: '/switchUser',
		name: 'switchUser',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "ast" */ '../views/SwitchUserView.vue'),
		beforeEnter: (to, from, next) => {
			next()
		},
		meta: {
			displayLoader: false,
		},

	},
	{
		path: '/bce_eco',
		name: 'bce_eco',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "bce_eco" */ '../views/ProgramChannelsView.vue'),
		beforeEnter: (to, from, next) => {
			store.dispatch('global/initProgramChannels')
				.then(() => {
					store.dispatch('programChannels/initHeadingAst')
					store.dispatch('programChannels/initInfosContest')
					store.dispatch('programChannels/initInfos')
					store.dispatch('programChannels/initDocuments')
					store.dispatch('programChannels/initCalendar')
				})
			store.commit('global/SET_LAST_ROUTE', 'bce_eco')
			next()
		},
		meta: {
			displayLoader: false,
		},
	},
	{
		path: '/bce_lit',
		name: 'bce_lit',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "bce_lit" */ '../views/ProgramChannelsView.vue'),
		beforeEnter: (to, from, next) => {
			store.dispatch('global/initProgramChannels')
				.then(() => {
					store.dispatch('programChannels/initHeadingAst')
					store.dispatch('programChannels/initInfosContest')
					store.dispatch('programChannels/initInfos')
					store.dispatch('programChannels/initDocuments')
					store.dispatch('programChannels/initCalendar')
				})
			store.commit('global/SET_LAST_ROUTE', 'bce_lit')
			next()
		},
		meta: {
			displayLoader: false,
		},
	},
	{
		path: '/signup',
		name: 'signup',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "signup" */ '../views/SignupView.vue'),
		beforeEnter: (to, from, next) => {
			store.dispatch('signup/initSignup')
			store.dispatch('global/initProgramChannels')
			store.dispatch('global/initCountries')
			store.dispatch('global/initDiplomas')
			store.dispatch('global/initEstablishments')
			store.dispatch('gcu/initLegalText')
			next()
		},
		meta: {
			displayLoader: false,
		},
	},
	{
		path: '/connected-faq',
		name: 'connected-faq',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "connected_faq" */ '../views/ConnectedFaqView.vue'),
		beforeEnter: (to, from, next) => {
			store.dispatch('faq/initTopics')
			next()
		},
		meta: {
			requiresAuth: true,
			displayLoader: true,
		},
	},
	{
		path: '/faq',
		name: 'faq',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "faq" */ '../views/FaqView.vue'),
		beforeEnter: (to, from, next) => {
			store.dispatch('global/initProgramChannels')
			store.dispatch('faq/initTopics')
			next()
		},
		meta: {
			displayLoader: true,
		},
	},
	{
		path: '/reset-password',
		name: 'reset-password',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "reset-password" */ '../views/ResetPasswordView.vue'),
		beforeEnter: (to, from, next) => {
			store.dispatch('gcu/initLegalText')
			store.dispatch('password/initErrorReinitPassword')
			next()
		},
		meta: {
			displayLoader: false,
		},
	},
	{
		path: '/connected-contact',
		name: 'connected-contact',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "connected-contact" */ '../views/ConnectedContactView.vue'),
		beforeEnter: (to, from, next) => {
			store.dispatch('contact/initCampuses')
			store.dispatch('notifications/initContactSubjects')
			store.dispatch('gcu/initLegalText')
			store.dispatch('contact/initVerbatims')
			next()
		},
		meta: {
			requiresAuth: true,
			displayLoader: false,
		},
	},
	{
		path: '/contact',
		name: 'contact',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "contact" */ '../views/ContactView.vue'),
		beforeEnter: (to, from, next) => {
			store.dispatch('contact/initCampuses')
			store.dispatch('gcu/initLegalText')
			next()
		},
		meta: {
			displayLoader: false,
		},
	},
	{
		path: '/dashboard',
		name: 'dashboard',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/DashboardView.vue'),
		beforeEnter: async (to, from, next) => {
			await store.dispatch('payment/initPaymentInProgress')
			await store.dispatch('payment/initWaitingPayment')
			store.dispatch('oralTests/initOralTests')

			const meSimplifiedStatus = store.state.profile.me.student.simplifiedStatus
			const meStatus = store.state.profile.me.student.state
			const admissibleState = meStatus === STUDENT_STATE.admissible || meStatus === STUDENT_STATE.unranked
			const rejectedAdmissibleState = meStatus === STUDENT_STATE.rejected_admissible || meStatus === STUDENT_STATE.unranked

			const BLOC_TYPE = {
				initialized: 'DASHBOARD_INITIALIZED_CANDIDACY',
				in_progress: 'DASHBOARD_PROCESSING_CANDIDACY',
				complete: 'DASHBOARD_COMPLETED_CANDIDACY',
				validated: 'DASHBOARD_VALIDATED_CANDIDACY',
			}
			let blocType = BLOC_TYPE[meSimplifiedStatus]

			if (!blocType && (admissibleState || rejectedAdmissibleState) && (!store.state.results.dateResultatsAdmissibilite || store.state.results.dateResultatsAdmissibilite > new Date())) {
				blocType = BLOC_TYPE.validated
			}

			await Promise.all([
				store.dispatch('contact/initCampuses'),
				store.dispatch('dashboard/blocValidationPopin'),
				blocType ? store.dispatch('dashboard/blocCandidateMessage', blocType) : undefined,
				store.dispatch('dashboard/initDateResultsAdmission'),
				store.dispatch('dashboard/initDocuments'),
				store.dispatch('dashboard/initCalendar'),
				admissibleState || rejectedAdmissibleState ? store.dispatch('dashboard/blocCampusInfos') : undefined,
				admissibleState || rejectedAdmissibleState ? store.dispatch('dashboard/admissibilityPublication') : undefined,
				!store.getters['profile/isForbiddenPTV'] && store.state.profile.me.student.activeStudentPTV ? store.dispatch('programmeTaVenue/getStudentPTV') : undefined,
			])

			next()
		},
		meta: {
			requiresAuth: true,
			forceGetMe: true,
			displayLoader: true,
		},
	},
	{
		path: '/profile',
		name: 'profile',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "profile" */ '../views/ProfileView.vue'),
		beforeEnter: async (to, from, next) => {
			await store.dispatch('global/initCountries')
			Promise.all([
				store.dispatch('profile/initProfileBlocs'),
			])
			next()
		},
		meta: {
			requiresAuth: true,
			forceGetMe: true,
			displayLoader: true,
		},
	},
	{
		path: '/cv',
		name: 'cv',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "cv" */ '../views/CvView.vue'),
		beforeEnter: async (to, from, next) => {
			await store.dispatch('dashboard/candidacySteps').then(
				() => {
					if (store.state.dashboard.candidacySteps.cv === 'forbidden') {
						next(from.path)
					}
				},
			)
			const createOrInitCv = !store.state.profile.me.student.cv ? 'cv/createCv' : 'cv/initStudentCv'
			await Promise.all([
				store.dispatch(createOrInitCv),
				store.dispatch('cv/initDateFinCV'),
			]).catch((err) => {
				if (err.response.status === 403) {
					next()
				}
			})

			if (store.state.cv.endDate > new Date()) {
				await store.dispatch('cv/initCvLanguages')
				await Promise.all([
					store.dispatch('signup/initSignup'),
					store.dispatch('global/initDiplomas'),
					store.dispatch('global/initEstablishments'),
					store.dispatch('cv/initCv'),
					store.dispatch('cv/initDateCV'),
					store.dispatch('cv/initBacData'),
					store.dispatch('global/initCountries'),
					store.dispatch('tooltip/initTooltipExp'),
					store.dispatch('tooltip/initTooltipBacSup'),
				])
			} else {
				await Promise.all([
					store.dispatch('cv/initRegistrationClosed'),
				])
			}

			next()
		},
		meta: {
			requiresAuth: true,
			roles: ['ast1', 'ast2'],
			displayLoader: true,
		},
	},
	{
		path: '/folder',
		name: 'folder',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "folder" */ '../views/FolderView.vue'),
		beforeEnter: async (to, from, next) => {
			await Promise.all([
				store.dispatch('tooltip/initTooltipFolder'),
				store.dispatch('global/initProgramChannels'),
				store.dispatch('global/initCountries'),
				store.dispatch('global/initDiplomas'),
				store.dispatch('global/initEstablishments'),
				store.dispatch('folder/initFolder'),
				store.dispatch('folder/initAdminRecord'),
				store.dispatch('folder/initDateFolder'),
			]).then(() => next())
		},
		meta: {
			requiresAuth: true,
			roles: ['ast1', 'ast2'],
			forceGetMe: true,
			displayLoader: true,
		},
	},
	{
		path: '/epreuves-ecrites',
		name: 'WrittenTests',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "WrittenTests" */ '../views/WrittenTestsView.vue'),
		beforeEnter: async (to, from, next) => {
			await store.dispatch('dashboard/candidacySteps').then(() => {
				if (store.state.dashboard.candidacySteps.writtenExamination === 'forbidden') {
					next(from.path)
				}
			})
			await Promise.all([
				store.dispatch('writtenTests/initExamSessionType'),
				store.dispatch('writtenTests/initExamStudents'),
			])
			Promise.all([
				store.dispatch('payment/getExamOrders'),
				store.dispatch('writtenTests/initDateFin'),
				store.dispatch('writtenTests/initVerbatims'),
				store.dispatch('writtenTests/initReminder'),
				store.dispatch('payment/initPaymentInProgress'),
				store.dispatch('writtenTests/languageTestInfos'),
				store.dispatch('writtenTests/initManagement'),
				store.dispatch('writtenTests/initEnglish'),
				store.dispatch('writtenTests/initExamClassification'),
			]).then(async () => {
				await store.dispatch('payment/initWaitingPayment')
				next()
			})
		},
		meta: {
			requiresAuth: true,
			roles: ['ast1', 'ast2'],
			displayLoader: true,
		},
	},
	{
		path: '/epreuves-orales',
		name: 'OralTests',
		component: () => import(/* webpackChunkName: "OralTests" */ '../views/OralTestsView.vue'),
		beforeEnter: async (to, from, next) => {
			await store.dispatch('oralTests/initOralTests')
			if (store.getters['oralTests/isForbidden']) {
				next('/')
			}
			store.dispatch('contact/initCampuses')
			store.dispatch('folder/initAdminRecord')
			store.dispatch('oralTests/initVerbatims')
			next()
		},
		meta: {
			requiresAuth: true,
			displayLoader: true,
		},
	},
	{
		path: '/venue',
		name: 'MaVenue',
		component: () => import(/* webpackChunkName: "OralTests" */ '../views/MaVenueView.vue'),
		beforeEnter: async (to, from, next) => {
			await store.dispatch('oralTests/initOralTests')
			if (store.getters['profile/isForbiddenPTV']) {
				next('/')
			}
			await store.dispatch('contact/initCampuses')
			await store.dispatch('folder/initAdminRecord')
			await store.dispatch('oralTests/initVerbatims')
			await store.dispatch('programmeTaVenue/initPTV')
			await Promise.all([
				!store.getters['profile/isForbiddenPTV'] && store.state.profile.me.student.activeStudentPTV ? store.dispatch('programmeTaVenue/getStudentPTV') : undefined,
			])
			next()
		},
		meta: {
			requiresAuth: true,
			displayLoader: true,
		},
	},
	{

		path: '/notifications',
		name: 'notifications',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "notifications" */ '../views/NotificationsView.vue'),
		beforeEnter: (to, from, next) => {
			store.dispatch('notifications/initVerbatimNotification')
			next()
		},
		meta: {
			requiresAuth: true,
			displayLoader: true,
		},
	},
	{

		path: '/resultats-admissibilite',
		name: 'resultats-admissibilite',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "resultats-admissibilite" */ '../views/ResultatsAdmissibiliteView.vue'),
		beforeEnter: (to, from, next) => {
			next()
		},
		meta: {
			requiresAuth: false,
			displayLoader: false,
			isLanding: true,
		},
	},
	{
		path: '/resultats-admission',
		name: 'resultats-admission',
		// route level code-splitting
		// this generates a separate chunk (resultats-admission.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "resultats-admission" */ '../views/ResultatsAdmissionView.vue'),
		beforeEnter: (to, from, next) => {
			next()
		},
		meta: {
			requiresAuth: false,
			displayLoader: false,
			isLanding: true,
		},
	},
	{

		path: '/detail-notification',
		name: 'detail-notification',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "detail-notification" */ '../views/NotificationDetailView.vue'),
		meta: {
			requiresAuth: true,
		},
	},
	{

		path: '/account-activation',
		name: 'account-activation',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "account-activation" */ '../views/AccountActivationView.vue'),
		beforeEnter: (to, from, next) => {
			store.dispatch('gcu/initLegalText')
			next()
		},
		meta: {
			requiresAuth: false,
			displayLoader: false,
		},
	},
	{
		path: '/resultats',
		name: 'resultats',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "results" */ '../views/ResultatsView.vue'),
		beforeEnter: async (to, from, next) => {
			await store.dispatch('results/initDateResultatsAdmissibilite')
			if (!store.state.results.dateResultatsAdmissibilite || store.state.results.dateResultatsAdmissibilite > new Date()) {
				next('/dashboard')
			}
			store.dispatch('results/initVerbatims')
			store.dispatch('results/initResultsNoteBene')
			next()
		},
		meta: {
			requiresAuth: true,
			forceGetMe: true,
			displayLoader: true,
		},
	},
	{
		path: '/historique-paiements',
		name: 'payments-history',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "payments-history" */ '../views/PaymentsHistory.vue'),
		beforeEnter: (to, from, next) => {
			store.dispatch('paymentsHistory/initVerbatims')
			store.dispatch('paymentsHistory/getOrders')
			next()
		},
		meta: {
			requiresAuth: true,
			forceGetMe: false,
			displayLoader: true,
		},
	},
	{
		path: '/campus',
		name: 'campus',
		component: () => import('../views/CampusView.vue'),
		beforeEnter: async (to, from, next) => {
			store.dispatch('contact/initCampuses')
			store.dispatch('campus/getCampusDatas')
			next()
		},
		meta: {
			requiresAuth: true,
			forceGetMe: true,
			displayLoader: true,
		},
	},
	{
		path: '*',
		component: () => import(/* webpackChunkName: "NotFoundView" */ '../views/NotFoundView.vue'),
		beforeEnter: (to, from, next) => {
			store.commit('global/SET_PROGRAM_CHANNELS_LIST', [])
			next()
		},
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})
router.beforeEach(async (to, from, next) => {
	store.commit('global/SET_LOADER', to.meta.displayLoader)
	store.commit('global/SET_ROUTE', to)

	if (to.name === 'resultats-admissibilite') {
		store.dispatch('footer/initConnectedFooter')
		store.dispatch('navBar/initDisconnectedNavBar')
		next()
		return
	}

	await store.dispatch('global/initCountries')
	store.dispatch('global/initFlexGapSupported')

	if (to.meta.requiresAuth) {
		if (to.meta.roles) {
			await store.dispatch('global/initProgramChannels')
			await store.dispatch('profile/initMe', { forceGet: false })
			if (!to.meta.roles.includes(store.getters['profile/role'])) {
				next('/')
				return
			}
		}

		store.dispatch('auth/refresh')
			.then(async () => {
				if (!store.getters['auth/isAuthenticated']) {
					next('/')
				} else {
					await store.dispatch('profile/initMe', { forceGet: !!to.meta.forceGetMe })
					await store.dispatch('global/initProgramChannels')
					store.dispatch('paymentsHistory/getOrders')
					store.dispatch('notifications/initNotification')
					store.dispatch('footer/initConnectedFooter')
					store.dispatch('dashboard/candidacySteps')
					store.dispatch('results/initDateResultatsAdmissibilite')
					store.dispatch('oralTests/initDateResultats')
					next()
				}
			}).catch(() => {
				next('/')
			})
	} else {
		store.dispatch('navBar/initDisconnectedNavBar')
		store.dispatch('footer/initDisconnectedFooter')
		next()
	}
})
router.afterEach(() => {
	window.scrollTo(0, 0)
	store.commit('global/SET_LOADER', false)
})

export default router

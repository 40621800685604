import deleteAPI from '@/utils/deleteData.js'
import postAPI from '@/utils/postData.js'
import axios from './axios'
import store from '../store'

export const MEDIA_CODES = {
	certificat_eligibilite: 'certificat_eligibilite',
	certificat_double_parcours: 'certificat_double_parcours',
	shn: 'shn',
	tt: 'tt',
	crous: 'crous',
	id_card: 'id_card',
	bac: 'bac',
	jdc: 'jdc',
	L1_S1: 'L1_S1',
	L1_S2: 'L1_S2',
	L2_S3: 'L2_S3',
	L2_S4: 'L2_S4',
	L3_S5: 'L3_S5',
	L3_S6: 'L3_S6',
	M1_S1: 'M1_S1',
	M1_S2: 'M1_S2',
	M2_S3: 'M2_S3',
	M2_S4: 'M2_S4',
	L1: 'L1',
	L2: 'L2',
	L3: 'L3',
	M1: 'M1',
	M2: 'M2',
	attestation_anglais: 'attestation_anglais',
	attestation_management: 'attestation_management',
	autre: 'autre',
}

export default {
	postMedia (file, code) {
		// eslint-disable-next-line no-new
		return new Promise((resolve, reject) => {
			const formData = new FormData()
			formData.append('formFile', file, file.name)
			formData.append('originalName', file.name)
			formData.append('code', code)
			postAPI.postWithToken('api/media', formData).then((res) => resolve(res.data))
				.catch((err) => reject(err))
		})
	},
	deleteMedia (lastMediaId) {
		// eslint-disable-next-line no-new
		return new Promise((resolve, reject) => {
			deleteAPI.deleteWithToken(lastMediaId).then((res) => resolve(res.data))
				.catch((err) => reject(err))
		})
	},
	getMedia (url, name) {
		axios.get(url, {
			responseType: 'blob',
			headers: {
				Authorization: `Bearer ${store.state.auth.token}`,
			},
		}).then((response) => {
			const link = document.createElement('a')
			link.href = window.URL.createObjectURL(new Blob([response.data]))
			link.setAttribute('download', name)
			document.body.appendChild(link)
			link.click()
		})
	},
}

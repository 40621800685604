<template>
	<div
		class="footer-container"
		:class="flexGapSupported ? '' : 'footer-container-rg'">
		<div
			class="links-container"
			:class="flexGapSupported ? '' : 'links-container-rg'">

      <span
        v-for="(element, index) in filteredLabels"
        :key="`labels-${index}`"
        @click="sendActivityLogs(element)"
      >
        <a
          :href="element.link"
          target="_blank"
          rel="noopener noreferrer"
          :data-beaver-id="`linkConnectedFooter-${index}`"
        >
          <p>{{ element.label }}</p>
        </a>
      </span>

		</div>
		<div
			class="networks-container"
			:class="flexGapSupported ? '' : 'networks-container-rg'">
			<a
				v-for="(img, index) in filteredNetworks"
				:key="index"
				:href="img.link"
				target="_blank"
				rel="noopener noreferrer"
				class="external-links"
				:data-beaver-id="`networkConnectedFooter-${index}`"
			>
				<img v-if="img.media" :src="img.media.file" :alt="img.label" />
			</a>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import store from '@/store'
import getAPI from '@/utils/getData'
import utilsDate from '@/utils/dates'
import utilsActivityLogs from '@/utils/activityLogs'

export default {
	name: 'ConnectedFooter',
	data () {
		return {}
	},
	computed: {
		...mapState('global', ['flexGapSupported']),
		...mapState('activityLogs', ['activityLogs']),
		...mapGetters('footer', ['filteredLabels']),
		...mapGetters('navBar', ['filteredNetworks']),
		...mapGetters('auth', ['isAuthenticated']),

	},
	methods: {
		isDocumentRef (link) {
			return link.includes('document_reference')
		},
		getDocumentRefName (link) {
			return link.substring(link.lastIndexOf('/') + 1)
		},
		async sendActivityLogs (element) {
			if (this.isDocumentRef(element.link)) {
				const activity = await getAPI.getWithToken('api/users/activity_log')

				const docName = this.getDocumentRefName(element.link)

				const activityOutput = {
					...utilsActivityLogs.formatActivityOuput(activity.data.activityOutput),
					eventType: 'Document de référence',
					date: utilsDate.toDateString(),
					document: docName,
				}
				await store.dispatch('activityLogs/postActivityLogs', activityOutput)
			}
		},
	},
}
</script>

<style scoped>
.footer-container {
	background: black;
	color: white;
	padding: 20px;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	gap: 28px;
	flex-wrap: wrap;
	width: 100%;
}
.footer-container-rg > *:not(:last-child)  {
	margin-right: 28px;
}
.links-container {
	display: flex;
	align-items: center;
	gap: 10px;
	flex-wrap: wrap;
}
.links-container-rg > *:not(:last-child)  {
	margin-right: 10px;
}
.links-container a p {
	color: white;
}
.links-container :not(:last-of-type) {
	border-right: 1px solid white;
	padding-right: 10px;
}
.networks-container {
	display: flex;
	gap: 5px;
	align-items: center;
}
.networks-container-rg > *:not(:last-child)  {
	margin-right: 5px;
}
.external-links {
	width: 24px;
	height: 24px;
}
.external-links img{
	width: 24px;
}
@media screen and (min-width: 820px) {

	.footer-container {
		flex-wrap: nowrap;

	}
}
</style>

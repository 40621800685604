import { HYDRA_MEMBER } from '@/utils/api'
import blocService from '@/services/bloc.service'

export const TagLabelValue = {
	PTV_CAMPUS_TITRE: 'PTV_CAMPUS_TITRE',
	PTV_CAMPUS_SANS_IMAGE: 'PTV_CAMPUS_SANS_IMAGE',
	PTV_CAMPUS_IMAGE_DROITE: 'PTV_CAMPUS_IMAGE_DROITE',
	PTV_CAMPUS_IMAGE_GAUCHE: 'PTV_CAMPUS_IMAGE_GAUCHE',
	PTV_CAMPUS_VIGNETTE: 'PTV_CAMPUS_VIGNETTE',
}

const mapPtvCampusTagToCampusBlock = (id, ptvCampusTags) => {
	if (!ptvCampusTags || ptvCampusTags.length === 0) {
		return undefined
	}

	let title = ''
	const blocks = []
	let lastBlockIsThumbnail = false

	ptvCampusTags.forEach((ptvCampusTag) => {
		switch (ptvCampusTag.tag.label) {
		case TagLabelValue.PTV_CAMPUS_TITRE:
			title = ptvCampusTag.label
			lastBlockIsThumbnail = false
			break
		case TagLabelValue.PTV_CAMPUS_SANS_IMAGE:
			blocks.push({
				type: ptvCampusTag.tag.label,
				title: ptvCampusTag.label,
				content: ptvCampusTag.content,
			})
			lastBlockIsThumbnail = false
			break
		case TagLabelValue.PTV_CAMPUS_IMAGE_DROITE:
		case TagLabelValue.PTV_CAMPUS_IMAGE_GAUCHE:
			blocks.push({
				type: ptvCampusTag.tag.label,
				title: ptvCampusTag.label,
				content: ptvCampusTag.content,
				image: ptvCampusTag.media.file,
			})
			lastBlockIsThumbnail = false
			break
		case TagLabelValue.PTV_CAMPUS_VIGNETTE:
			if (!lastBlockIsThumbnail) {
				blocks.push({
					type: ptvCampusTag.tag.label,
					thumbnail: [
						{
							title: ptvCampusTag.label,
							content: ptvCampusTag.content,
							image: ptvCampusTag.media?.file ?? undefined,
						},
					],
				})
				lastBlockIsThumbnail = true
			} else {
				blocks[blocks.length - 1].thumbnail.push({
					title: ptvCampusTag.label,
					content: ptvCampusTag.content,
					image: ptvCampusTag.media.file,
				})
			}
			break
		default:
			break
		}
	})

	return {
		id,
		title,
		blocks,
	}
}

const state = {
	ptvCampusTagImgGlobal: undefined,
	campusBlock1: undefined,
	campusBlock2: undefined,
	campusBlock3: undefined,
	campusBlock4: undefined,
	selectedCampusId: localStorage.getItem('SELECTED_CAMPUS_ID'),
}

const SET_PTV_CAMPUS_IMG_GLOBAL = 'SET_PTV_CAMPUS_IMG_GLOBAL'
const SET_PTV_CAMPUS_TAG_1 = 'SET_PTV_CAMPUS_TAG_1'
const SET_PTV_CAMPUS_TAG_2 = 'SET_PTV_CAMPUS_TAG_2'
const SET_PTV_CAMPUS_TAG_3 = 'SET_PTV_CAMPUS_TAG_3'
const SET_PTV_CAMPUS_TAG_4 = 'SET_PTV_CAMPUS_TAG_4'
const SET_SELECTED_CAMPUS_ID = 'SET_SELECTED_CAMPUS_ID'
const RAZ_DATA_CAMPUS = 'RAZ_DATA_CAMPUS'

const mutations = {
	[SET_PTV_CAMPUS_IMG_GLOBAL] (state, payload) {
		state.ptvCampusTagImgGlobal = payload
	},
	[SET_PTV_CAMPUS_TAG_1] (state, payload) {
		state.campusBlock1 = mapPtvCampusTagToCampusBlock('campusBlock1', payload)
	},
	[SET_PTV_CAMPUS_TAG_2] (state, payload) {
		state.campusBlock2 = mapPtvCampusTagToCampusBlock('campusBlock2', payload)
	},
	[SET_PTV_CAMPUS_TAG_3] (state, payload) {
		state.campusBlock3 = mapPtvCampusTagToCampusBlock('campusBlock3', payload)
	},
	[SET_PTV_CAMPUS_TAG_4] (state, payload) {
		state.campusBlock4 = mapPtvCampusTagToCampusBlock('campusBlock4', payload)
	},
	[SET_SELECTED_CAMPUS_ID] (state, payload) {
		state.selectedCampusId = payload
		localStorage.setItem('SELECTED_CAMPUS_ID', payload)
	},
	[RAZ_DATA_CAMPUS] (state) {
		state.ptvCampusTagImgGlobal = undefined
		state.campusBlock1 = undefined
		state.campusBlock2 = undefined
		state.campusBlock3 = undefined
		state.campusBlock4 = undefined
		state.selectedCampusId = undefined
		localStorage.setItem('SELECTED_CAMPUS_ID', undefined)
	},
}

const actions = {
	getCampusDatas ({ commit }) {
		return new Promise((resolve, reject) => {
			blocService.getBloc({ label: 'PTV_CAMPUS_IMG_GLOBAL', campus: state.selectedCampusId })
				.then((res) => commit(SET_PTV_CAMPUS_IMG_GLOBAL, res.data[HYDRA_MEMBER]))
			blocService.getBlocByKeyAndCampus({ key: 'PTV_CAMPUS_TAG_1', campus: state.selectedCampusId })
				.then((res) => commit(SET_PTV_CAMPUS_TAG_1, res.data[HYDRA_MEMBER]))
			blocService.getBlocByKeyAndCampus({ key: 'PTV_CAMPUS_TAG_2', campus: state.selectedCampusId })
				.then((res) => commit(SET_PTV_CAMPUS_TAG_2, res.data[HYDRA_MEMBER]))
			blocService.getBlocByKeyAndCampus({ key: 'PTV_CAMPUS_TAG_3', campus: state.selectedCampusId })
				.then((res) => commit(SET_PTV_CAMPUS_TAG_3, res.data[HYDRA_MEMBER]))
			blocService.getBlocByKeyAndCampus({ key: 'PTV_CAMPUS_TAG_4', campus: state.selectedCampusId })
				.then((res) => commit(SET_PTV_CAMPUS_TAG_4, res.data[HYDRA_MEMBER]))

			resolve()
		})
	},
}

const getters = {
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}

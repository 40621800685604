import filtersBloc from '@/utils/filtersBloc'
import blocService from '@/services/bloc.service'

const state = {
	connected: {
		labels: [],
	},
	disconnected: {
		map: [],
		text: [],
		logo: [],
	},
}

const mutations = {
	SET_MAP (state, payload) {
		state.disconnected.map = payload
	},
	SET_TEXT (state, payload) {
		state.disconnected.text = payload
	},
	SET_LOGO (state, payload) {
		state.disconnected.logo = payload
	},
	SET_CONNECTED_LABELS (state, payload) {
		state.connected.labels = payload
	},
}

const actions = {
	initDisconnectedFooter ({ commit }) {
		if (state.disconnected.map.length > 0) {
			return Promise.resolve(state.disconnected.map)
		}
		return Promise.all([
			blocService.getBloc({ label: 'HP_FOOTER_MAP' })
				.then((res) => {
					commit('SET_MAP', res.data['hydra:member'])
				}),
			blocService.getBloc({ label: 'HP_FOOTER_MIDDLE' })
				.then((res) => {
					commit('SET_TEXT', res.data['hydra:member'])
				}),
			blocService.getBloc({ label: 'HP_FOOTER_LOGO' })
				.then((res) => {
					commit('SET_LOGO', res.data['hydra:member'])
				}),
		])
	},
	initConnectedFooter ({ commit }) {
		if (state.connected.labels.length > 0) {
			return Promise.resolve(state.connected.labels)
		}
		return blocService.getBloc({ label: 'PROFIL_FOOTER_LIGHT' })
			.then((res) => {
				commit('SET_CONNECTED_LABELS', res.data['hydra:member'])
			})
	},
}

const getters = {
	filteredMap () {
		return filtersBloc.filterActive(state.disconnected.map)
	},
	filteredText () {
		return filtersBloc.filterActive(state.disconnected.text)
	},
	filteredLogo () {
		return filtersBloc.filterActive(state.disconnected.logo)
	},
	filteredLabels () {
		return filtersBloc.filterActive(state.connected.labels)
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}

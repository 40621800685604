import moment from 'moment'

const TIME_ZONE = 'Europe/Paris'
export default {
	toLocaleDateString (value) {
		const dateString = new Date(value).toLocaleDateString('fr-FR', {
			timeZone: TIME_ZONE,
			day: 'numeric',
			month: 'long',
			year: 'numeric',
		})

		const time = new Date(value).toLocaleTimeString('fr-FR', {
			timeZone: TIME_ZONE,
			hour: '2-digit',
			minute: '2-digit',
		}).replace(':', 'h')

		const timeString = time !== '00h00' ? ` (${time})` : ''

		return `${dateString}${timeString}`
	},
	toLocaleDateString2 (value) {
		const dateString = new Date(value).toLocaleDateString('fr-FR', {
			timeZone: TIME_ZONE,
			day: 'numeric',
			month: 'long',
			year: 'numeric',
		})

		return `${dateString}`
	},
	toLocaleDateString3 (value) {
		const date = new Date(value)
		const dateString = date.toLocaleDateString('fr-FR', {
			timeZone: TIME_ZONE,
			weekday: 'long',
			day: 'numeric',
			month: 'long',
			year: 'numeric',
		})

		const time = date.toLocaleTimeString('fr-FR', {
			timeZone: TIME_ZONE,
			hour: '2-digit',
			minute: '2-digit',
		}).replace(':', 'h')

		const timeString = time !== '00h00' ? `${time}` : ''

		return `${this.capitalizeFirstLetter(dateString)} - ${timeString}`
	},
	capitalizeFirstLetter (string) {
		return `${string.charAt(0).toUpperCase()}${string.slice(1)}`
	},
	toLocaleDateString4 (value) {
		const dateString = new Date(value).toLocaleDateString('fr-FR', {
			timeZone: TIME_ZONE,
			weekday: 'long',
			day: 'numeric',
			month: 'long',
			year: 'numeric',
		})

		return `${this.capitalizeFirstLetter(dateString)}`
	},
	toLocaleDateString5 (value) {
		const dateString = new Date(value).toLocaleDateString('fr-FR', {
			timeZone: TIME_ZONE,
			day: 'numeric',
			month: 'long',
			year: 'numeric',
		})

		const time = new Date(value).toLocaleTimeString('fr-FR', {
			timeZone: TIME_ZONE,
			hour: '2-digit',
			minute: '2-digit',
		}).replace(':', 'h')

		const timeString = time !== '00h00' ? ` à ${time}` : ''

		return `${dateString}${timeString}`
	},
	toLocaleDateString6 (value) {
		const dateString = new Date(value).toLocaleDateString('fr-FR', {
			timeZone: TIME_ZONE,
			day: 'numeric',
			month: 'long',
			year: 'numeric',
		})

		const time = new Date(value).toLocaleTimeString('fr-FR', {
			timeZone: TIME_ZONE,
			hour: '2-digit',
			minute: '2-digit',
		}).replace(':', 'h')

		const timeString = time !== '00h00' ? ` ${time}` : ''

		return `${dateString}${timeString}`
	},
	toLocaleDateString7 (value) {
		const dateString = new Date(value).toLocaleDateString('fr-FR', {
			timeZone: TIME_ZONE,
			day: 'numeric',
			month: 'numeric',
			year: 'numeric',
		})

		return `${dateString}`
	},
	toDateString () {
		return moment().format('YYYY-MM-DD hh:mm:ss')
	},
	convertApiDateString (dateString) {
		return new Date(new Date(dateString.slice(0, 10)).setHours(0, 0, 0, 0, 0))
	},
}

<template>
  <div>
    <div
      class="networks"
      :class="flexGapSupported ? '' : 'networks-rg'">
      <a
        v-for="(img, index) in filteredNetworks"
        :key="index"
        :href="img.link"
        target="_blank"
        rel="noopener noreferrer"
        class="external-links"
      >
        <img v-if="img.media" :src="img.media.file" :alt="img.label"/>
      </a>
    </div>
    <div class="navigation">
      <img :src="skemaLogo" alt="'logo'" class="logo-skema"/>
      <div
        class="menu"
        :class="flexGapSupported ? '' : 'menu-rg'">
        <router-link
          v-for="(element, index) in filteredMenu"
          :key="index"
          :to="`${element.link}${element.text === 'FAQ' ? `?pcKey=${pcKey}` : ''}`"
          tag="a"
          class="menu-item"
          :class="flexGapSupported ? '' : 'menu-item-rg'"
        >
          <component :is="element.icon"/>
          <p class="hideMobile">{{ element.text }}</p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import AccueilIcon from '@/assets/icons/AccueilIcon.vue'
import FAQIcon from '@/assets/icons/FAQIcon.vue'
import ProfileIcon from '@/assets/icons/ProfileIcon.vue'
import MailIcon from '@/assets/icons/MailIcon.vue'

const AST1_PROGRAM_CHANNEL = '/api/program_channels/1'
const AST2_PROGRAM_CHANNEL = '/api/program_channels/2'
const BCE_ECO_PROGRAM_CHANNEL = '/api/program_channels/3'
const BCE_LIT_PROGRAM_CHANNEL = '/api/program_channels/4'
const ALL_PROGRAM_CHANNELS = [
	AST1_PROGRAM_CHANNEL,
	AST2_PROGRAM_CHANNEL,
	BCE_ECO_PROGRAM_CHANNEL,
	BCE_LIT_PROGRAM_CHANNEL,
]
const AST_PROGRAM_CHANNELS = [
	AST1_PROGRAM_CHANNEL,
	AST2_PROGRAM_CHANNEL,
]
export default {
	name: 'DisconnectedNavBar',
	components: {
		AccueilIcon,
		FAQIcon,
		ProfileIcon,
		MailIcon,
	},
	data () {
		return {
			skemaLogo: require('@/assets/img/skema_logo.png'),
			menu: [
				{
					icon: 'AccueilIcon',
					text: 'Accueil',
					link: '/',
					programChannel: ALL_PROGRAM_CHANNELS,
					appear: [
						'/',
					],
				},
				{
					icon: 'FAQIcon',
					text: 'FAQ',
					link: '/faq',
					programChannel: ALL_PROGRAM_CHANNELS,
					appear: [
						'/faq',
						'/faq?pcKey=ast',
						'/faq?pcKey=bce_lit',
						'/faq?pcKey=bce_eco',
						'/',
						'/contact',
					],
				},
				{
					icon: 'MailIcon',
					text: 'Contact',
					link: '/contact',
					programChannel: ALL_PROGRAM_CHANNELS,
					appear: [
						'/contact',
					],
				},
				{
					icon: 'ProfileIcon',
					text: 'AST',
					link: '/ast',
					programChannel: AST_PROGRAM_CHANNELS,
					appear: [
						'/',
						'/ast',
						'/bce_eco',
						'/bce_lit',
						'/contact',
					],
				},
				{
					icon: 'ProfileIcon',
					text: 'BCE Eco',
					link: '/bce_eco',
					programChannel: [
						BCE_ECO_PROGRAM_CHANNEL,
					],
					appear: [
						'/',
						'/ast',
						'/bce_eco',
						'/bce_lit',
						'/faq?pcKey=ast',
						'/signup',
						'/contact',
					],
				},
				{
					icon: 'ProfileIcon',
					text: 'BCE Lit',
					link: '/bce_lit',
					programChannel: [
						BCE_LIT_PROGRAM_CHANNEL,
					],
					appear: [
						'/',
						'/ast',
						'/bce_eco',
						'/bce_lit',
						'/faq?pcKey=ast',
						'/signup',
						'/contact',
					],
				},
			],
		}
	},
	computed: {
		...mapState('global', ['flexGapSupported']),
		...mapGetters('global', ['AST1', 'AST2', 'BCELIT', 'BCEECO']),
		...mapState('navBar', ['disconnected']),
		...mapState('global', ['programChannelsList', 'lastRoute']),

		...mapGetters('navBar', [
			'filteredNetworks',
		]),
		filteredMenu () {
			if (this.$route.fullPath === '/' || this.$route.fullPath === '/contact') {
				return this.menu.filter((element) => !element.appear.includes(this.$route.fullPath))
			}
			return this.menu.filter((element) => !element.appear.includes(this.$route.fullPath) && element.programChannel.some((v) => this.idProgramChannels.indexOf(v) !== -1))
		},
		idProgramChannels () {
			if (this.$route.fullPath === '/faq?pcKey=bce_lit') {
				return BCE_LIT_PROGRAM_CHANNEL
			}
			if (this.$route.fullPath === '/faq?pcKey=bce_eco') {
				return BCE_ECO_PROGRAM_CHANNEL
			}
			if (this.$route.fullPath === '/contact' && this.lastRoute === 'ast') {
				return AST1_PROGRAM_CHANNEL
			}
			if (this.$route.fullPath === '/contact' && this.lastRoute === 'bce_lit') {
				return BCE_LIT_PROGRAM_CHANNEL
			}
			if (this.$route.fullPath === '/contact' && this.lastRoute === 'bce_eco') {
				return BCE_ECO_PROGRAM_CHANNEL
			}
			return this.programChannelsList.map((pc) => pc['@id'])
		},
		pcKey () {
			if (['signup', 'contact'].includes(this.$route.name)) {
				return 'ast'
			}
			return this.$route.name
		},
	},
	watch: {},
	mounted () {
	},
}
</script>

<style scoped>
.networks, .navigation {
  padding: 0 20px;
  display: flex;
  align-items: center;
  width: 100%;
}

.networks {
  background: transparent linear-gradient(90deg, #e7433c, #d55145 26%, #c9443f 50%, #b93338 75%, #bf0030) 0 0 no-repeat padding-box;
  gap: 5px;
  height: var(--networks-height);
  justify-content: flex-end;
}

.networks-rg > *:not(:last-child) {
  margin-right: 5px;
}

.navigation {
  height: var(--navigation-height);
  justify-content: space-between;
  background: white;
}

.menu {
  display: flex;
  align-items: center;
  gap: 30px;
}

.menu-rg > *:not(:last-child) {
  margin-right: 30px;
}

.menu-item {
  display: flex;
  align-items: center;
  gap: 15px;
}

.menu-item-rg > *:not(:last-child) {
  margin-right: 15px;
}

.menu-item svg {
  width: var(--icons-width);
  height: var(--icons-height);
}

.external-links {
  display: flex;
  width: 24.7px;
  height: 24.7px;
}

.external-links >>> img {
  width: 24.7px;
  height: 24.7px;
}

.logo-skema {
  height: 45px;
}

@media screen and (min-width: 1200px) {
  .logo-skema {
    margin-left: 60px;
  }

  .navigation, .networks {
    padding: 0 var(--padding-r-l-1);
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navbar-container",class:_vm.flexGapSupported ? '' : 'navbar-container-rg'},[_c('img',{staticClass:"skema-logo hideDesktop",attrs:{"src":"https://www.skema-bs.fr/_layouts/15/skema.internet.2016/site/branding/img/menu-logo.png?v=2.0 ","alt":"skema business school"}}),_c('router-link',{class:_vm.flexGapSupported ? '' : 'a-rg',attrs:{"to":`/connected-faq${_vm.pcKey}`,"tag":"a","data-beaver-id":"linkConnectedFaq"}},[_c('FAQ-icon'),_c('p',{staticClass:"hideMobile"},[_vm._v("FAQ")])],1),_c('router-link',{class:_vm.flexGapSupported ? '' : 'a-rg',attrs:{"to":"/connected-contact","tag":"a","data-beaver-id":"linkConnectedContacts"}},[_c('MailIcon'),_c('p',{staticClass:"hideMobile"},[_vm._v("Contacts")])],1),_c('button',{class:[
				'nav-button', _vm.isOnNotifPage ? 'active' : '',
				_vm.flexGapSupported ? '' : 'nav-button-rg'
			],attrs:{"data-beaver-id":"buttonAlerts"},on:{"click":function($event){$event.stopPropagation();return _vm.alertsVisibility.apply(null, arguments)}}},[_c('notificationIcon'),(_vm.filteredNotifsNotSentByCandidat.length > 0)?_c('span',{staticClass:"notification-counter"},[_vm._v(_vm._s(_vm.filteredNotifsNotSentByCandidat.length))]):_vm._e(),_c('p',{staticClass:"hideMobile"},[_vm._v("Notifications")]),(_vm.showAlerts)?_c('nav-bar-notifs',{staticClass:"navbar-notifs"}):_vm._e()],1),_c('a',{staticClass:"hideMobile",class:_vm.flexGapSupported ? '' : 'a-rg',attrs:{"data-beaver-id":"linkLogOut"},on:{"click":function($event){$event.preventDefault();return _vm.disconnect()}}},[_c('DeconnexionIcon'),_c('p',[_vm._v("Déconnexion")])],1),_c('nav-menu',{class:[
			'nav-menu mobile-menu',
			_vm.flexGapSupported ? '' : 'desktop-menu-rg',
			_vm.displayMenu ? 'menu--display': ''
			]}),_c('div',{staticClass:"nav-button hideDesktop",attrs:{"data-beaver-id":"burger"},on:{"click":_vm.setMenuState}},[(!this.displayMenu)?_c('burger-icon'):_vm._e(),(this.displayMenu)?_c('close-icon'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import store from '@/store'

export const STUDENT_STATE = {
	CREATED: 'created',
	CREATED_TO_PAY: 'created_to_pay',
	CREATED_BOURSIER_KO: 'created_boursier_ko',
	BOURSIER_KO: 'boursier_ko',
	CHECK_BOURSIER: 'check_boursier',
	RECHECK_BOURSIER: 'recheck_boursier',
	CHECK_DIPLOMA: 'check_diploma',
	ELIGIBLE: 'eligible',
	VALID: 'valid',
	admissible: 'admissible',
	rejected_admissible: 'rejected_admissible',
	COMPLETE: 'complete',
	REGISTERED_EO: 'registered_eo',
	COMPLETE_EO: 'complete_eo',
	FINALIZED: 'finalized',
	admis_affected: 'admis_affected',
	admis_not_affected: 'admis_not_affected',
	admis: 'admis',
	not_admis: 'non_admis',
	registered_sk: 'registered_sk',
	approved: 'approved',
	unranked: 'unranked',
}

export default {
	isPayable ({ state }) {
		const order = store.state.payment.schoolOrder
		return order
			? order.state === 'created'
			: state === STUDENT_STATE.CREATED_TO_PAY
					|| state === STUDENT_STATE.CREATED_BOURSIER_KO
	},
	isAllowCvAndTests (student) {
		if (student === undefined) {
			return false
		}
		const { state } = student
		const validState = [
			STUDENT_STATE.CHECK_BOURSIER,
			STUDENT_STATE.BOURSIER_KO,
			STUDENT_STATE.VALID,
		]
		return validState.includes(state)
	},
}

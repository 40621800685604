import getAPI from '@/utils/getData'
import { HYDRA_MEMBER } from '@/utils/api'

const state = {
	topics: [],
}

const SET_TOPICS = 'SET_TOPICS'

const mutations = {
	[SET_TOPICS] (state, payload) {
		state.topics = payload
	},
}

const actions = {
	initTopics ({ commit }) {
		return getAPI.get('api/faq_topics')
			.then((res) => commit(SET_TOPICS, res.data[HYDRA_MEMBER]))
	},
}
const getters = {}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}

import getAPI from '@/utils/getData'

const BASE_URL = 'api/students'
export default {
	getAffectationResults (studentId) {
		const url = `${BASE_URL}/${studentId}/affectation_result`
		return getAPI.getWithToken(url)
	},
	getRegisteredSk (studentId) {
		const url = `${BASE_URL}/${studentId}/affectation_result/registered_sk`
		return getAPI.getWithToken(url)
	},
}

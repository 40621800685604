<template>
	<div class="page">
		<heading />
		<reasons />
	</div>
</template>

<script>
// @ is an alias to /src
import Heading from '@/components/HomeView/Heading.vue'
import Reasons from '@/components/HomeView/Reasons.vue'

export default {
	name: 'HomeView',
	components: {
		Reasons,
		Heading,
	},
	data () {
		return {
		}
	},
	computed: {
	},
	methods: {
	},
	mounted () {
	},
}
</script>

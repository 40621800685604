/* eslint-disable no-restricted-syntax */
import blocService from '@/services/bloc.service'
import store from '@/store'
import deleteAPI from '@/utils/deleteData.js'
import filtersBloc from '@/utils/filtersBloc'
import getAPI from '@/utils/getData.js'
import mediaAPI, { MEDIA_CODES } from '@/utils/medias.js'
import { MEDIA_STATUS } from '@/utils/medias.types.js'
import utilsParameters from '@/utils/parameters.js'
import postAPI from '@/utils/postData.js'
import putAPI from '@/utils/putData.js'
import { HYDRA_MEMBER } from '@/utils/api'

const state = {
	verbatims: [],
	bloc_lv2: [],
	closeDate: undefined,
	adminRecord: null,
	exam_languages: [],
	sport_levels: [],
	scholarShipLevels: [],
}

const SET_VERBATIMS = 'SET_VERBATIMS'
const SET_ADMINRECORD = 'SET_ADMINRECORD'
const SET_DIPLOMA_MEDIA = 'SET_DIPLOMA_MEDIA'
const SET_DUALPATH_DIPLOMA_MEDIA = 'SET_DUALPATH_DIPLOMA_MEDIA'
const SET_BLOC_LV2 = 'SET_BLOC_LV2'
const SET_EXAM_LANGUAGES = 'SET_EXAM_LANGUAGES'
const SET_SPORT_LEVELS = 'SET_SPORT_LEVELS'
const SET_SCHOLAR_SHIP_LEVELS = 'SET_SCHOLAR_SHIP_LEVELS'
const SET_CLOSE_DATE = 'SET_CLOSE_DATE'

const mutations = {
	[SET_VERBATIMS] (state, payload) {
		state.verbatims = payload
	},
	[SET_ADMINRECORD] (state, payload) {
		state.adminRecord = payload
	},
	[SET_DIPLOMA_MEDIA] (state, payload) {
		state.adminRecord.studentLastDiploma.diplomaMedias = [payload]
	},
	[SET_DUALPATH_DIPLOMA_MEDIA] (state, payload) {
		if (state.adminRecord.studentLastDiploma.dualPathStudentDiploma === null) {
			state.adminRecord.studentLastDiploma.dualPathStudentDiploma = {}
			state.adminRecord.studentLastDiploma.dualPathStudentDiploma.diplomaMedias = [payload]
		} else {
			state.adminRecord.studentLastDiploma.dualPathStudentDiploma.diplomaMedias = [payload]
		}
	},
	[SET_BLOC_LV2] (state, payload) {
		state.bloc_lv2 = payload
	},
	[SET_EXAM_LANGUAGES] (state, payload) {
		state.exam_languages = payload
	},
	[SET_SPORT_LEVELS] (state, payload) {
		state.sport_levels = payload
	},
	[SET_SCHOLAR_SHIP_LEVELS] (state, payload) {
		state.scholarShipLevels = payload
	},
	[SET_CLOSE_DATE] (state, payload) {
		state.closeDate = new Date(payload)
	},
}

const actions = {
	initFolder ({ commit }) {
		return new Promise((resolve, reject) => {
			const programChannelId = store.state.profile.me.student ? store.getters['profile/programChannel'] : '1'
			Promise.all([
				blocService.getBloc({ label: 'DOSSIER_ADMINISTRATIF_VERBATIM', programChannels: [{ id: programChannelId }] })
					.then((res) => commit(SET_VERBATIMS, res.data[HYDRA_MEMBER])),

				blocService.getBloc({ label: 'ADMINISTRATIVE_RECORD_LV2_PART' })
					.then((res) => commit(SET_BLOC_LV2, res.data[HYDRA_MEMBER])),

				getAPI.getWithToken('api/exam_languages')
					.then((res) => commit(SET_EXAM_LANGUAGES, res.data[HYDRA_MEMBER])),

				getAPI.get('api/sport_levels')
					.then((res) => commit(SET_SPORT_LEVELS, res.data[HYDRA_MEMBER])),

				getAPI.get('api/scholar_ship_levels')
					.then((res) => commit(SET_SCHOLAR_SHIP_LEVELS, res.data[HYDRA_MEMBER])),
			]).then(() => resolve('ok'))
		})
	},
	initDateFolder ({ commit }) {
		return getAPI.get('api/parameters?key.name=dateClotureInscriptions')
			.then((res) => {
				const date = utilsParameters.filterProgramChannel(res.data[HYDRA_MEMBER])
				commit(SET_CLOSE_DATE, date.value)
			})
	},
	initAdminRecord ({ commit }) {
		return getAPI.getWithToken(`${store.state.profile.me.student.administrativeRecord}`)
			.then((res) => commit(SET_ADMINRECORD, res.data))
	},
	async putAdminRecord ({
		commit, state, dispatch, getters,
	}, payload) {
		store.commit('global/SET_LOADER', true)
		const { programChannel } = store.state.profile.me.student
		const diplomaList = store.getters['global/filteredDiplomas'](programChannel, true)

		const dualPathPost = {
			year: parseInt(payload.lastDiploma.year, 10),
			diploma: payload.dualPath.diploma !== '' ? diplomaList[payload.dualPath.diploma]['@id'] : null,
			diplomaChannel: payload.dualPath.diploma !== '' && payload.dualPath.diplomaChannel !== '' ? diplomaList[payload.dualPath.diploma].diplomaChannels[payload.dualPath.diplomaChannel]['@id'] : null,
			detail: payload.dualPath.detail !== '' ? payload.dualPath.detail : '',
			establishment: payload.dualPath.school !== '' ? payload.dualPath.school : '',
			administrativeRecord: getters.filteredAdminRecord['@id'],
			postalCode: payload.dualPath.postalCode !== '' ? payload.dualPath.postalCode : '',
			city: payload.dualPath.city !== '' ? payload.dualPath.city : '',
		}
		const adminRecordPost = {
			optionalExamLanguage: payload.optionalExamLanguage,
			examLanguage: payload.examLanguage !== '' ? state.exam_languages[payload.examLanguage]['@id'] : null,
			highLevelSportsman: payload.highLevelSportsman,
			highLevelSportsmanMedias: getters.filteredAdminRecord.highLevelSportsmanMedias.length > 0 ? [getters.filteredAdminRecord.highLevelSportsmanMedias[0]['@id']] : [],
			sportLevel: payload.sportLevel !== '' ? state.sport_levels[payload.sportLevel]['@id'] : null,
			thirdTime: payload.thirdTime,
			thirdTimeMedias: getters.filteredAdminRecord.thirdTimeMedias.length > 0 ? [getters.filteredAdminRecord.thirdTimeMedias[0]['@id']] : [],
			scholarShip: payload.scholarShip,
			scholarShipMedias: [],
			scholarShipLevel: payload.scholarShipLevel !== '' ? state.scholarShipLevels[payload.scholarShipLevel]['@id'] : null,
			idCards: getters.filteredAdminRecord.idCards.length > 0 ? [getters.filteredAdminRecord.idCards[0]['@id']] : [],
			jdc: getters.filteredAdminRecord.jdc ? getters.filteredAdminRecord.jdc['@id'] : null,
		}

		// delete dualPath ?
		if (!payload.doubleBackground && state.adminRecord.studentLastDiploma.dualPathStudentDiploma) {
			const dualPathId = state.adminRecord.studentLastDiploma.dualPathStudentDiploma['@id']
			await deleteAPI.deleteWithToken(dualPathId)
		}

		if (!adminRecordPost.optionalExamLanguage) {
			delete adminRecordPost.examLanguage
		}
		if (getters.filteredAdminRecord.scholarShipMedias[0]) {
			adminRecordPost.scholarShipMedias.push(getters.filteredAdminRecord.scholarShipMedias[0]['@id'])
		}
		if (getters.filteredAdminRecord.scholarShipMedias[1]) {
			adminRecordPost.scholarShipMedias.push(getters.filteredAdminRecord.scholarShipMedias[1]['@id'])
		}
		if (payload.selectedMedia && (!payload.media || (typeof payload.media !== 'string' && !payload.media['@id']))) {
			dispatch('deleteMedia', payload.selectedMedia)
		}
		if (payload.media && payload.media.size) {
			await dispatch('postMedia', {
				media: payload.media,
				mediaCode: MEDIA_CODES.certificat_eligibilite,
				commitName: SET_DIPLOMA_MEDIA,
			})
			await putAPI.putWithToken(getters.filteredAdminRecord.studentLastDiploma['@id'], { diplomaMedias: [getters.filteredAdminRecord.studentLastDiploma.diplomaMedias[0]['@id']] })
		}
		if (payload.selectedDualPathMedia && (!payload.dualPathMedia || (typeof payload.dualPathMedia !== 'string' && !payload.dualPathMedia['@id']))) {
			dispatch('deleteMedia', payload.selectedDualPathMedia)
		}
		if (payload.dualPathMedia && payload.dualPathMedia.size) {
			await dispatch('postMedia', {
				media: payload.dualPathMedia,
				mediaCode: MEDIA_CODES.certificat_double_parcours,
				commitName: SET_DUALPATH_DIPLOMA_MEDIA,
			})
			dualPathPost.diplomaMedias = [getters.filteredAdminRecord.studentLastDiploma.dualPathStudentDiploma.diplomaMedias[0]['@id']]
		}

		if (payload.doubleBackground) {
			if (getters.filteredAdminRecord.studentLastDiploma.dualPathStudentDiploma && getters.filteredAdminRecord.studentLastDiploma.dualPathStudentDiploma['@id']) {
				await putAPI.putWithToken(getters.filteredAdminRecord.studentLastDiploma.dualPathStudentDiploma['@id'], dualPathPost)
			} else {
				const res = await postAPI.postWithToken('api/student_diplomas', dualPathPost)
				await putAPI.putWithToken(getters.filteredAdminRecord.studentLastDiploma['@id'], { dualPathStudentDiploma: res.data['@id'] })
			}
		} else {
			await putAPI.putWithToken(getters.filteredAdminRecord.studentLastDiploma['@id'], { dualPathStudentDiploma: null })
		}
		// PUT ADMIN_RECORD
		const myArray = [
			{
				file: payload.jdc,
				code: 'jdc',
				name: 'jdc',
				lastMedia: state.adminRecord.jdc,
			},
			{
				file: payload.idCards,
				code: 'id_card',
				name: 'idCards',
				isArray: true,
				position: 0,
				lastMedia: payload.selectedIdCard,
			},
			{
				file: payload.highLevelSportsmanMedias,
				code: 'shn',
				name: 'highLevelSportsmanMedias',
				isArray: true,
				position: 0,
				lastMedia: payload.selectedHighLevelSportsmanMedias,
			},
			{
				file: payload.thirdTimeMedias,
				code: 'tt',
				name: 'thirdTimeMedias',
				isArray: true,
				position: 0,
				lastMedia: payload.selectedThirdTimeMedias,
			},
			{
				file: payload.scholarShipMedias,
				code: 'crous',
				name: 'scholarShipMedias',
				isArray: true,
				position: 0,
				lastMedia: payload.selectedScholarShipMedias,
			},
			{
				file: payload.scholarShipMedias2,
				code: 'crous',
				name: 'scholarShipMedias',
				isArray: true,
				position: 1,
				lastMedia: payload.selectedScholarShipMedias2,
			},
		]
		for (const obj of myArray) {
			if (obj.lastMedia && (!obj.file || (typeof obj.file !== 'string' && !obj.file['@id']))) {
				dispatch('deleteMedia', obj.lastMedia)
			}
			if (obj.file && obj.file.size) {
				// eslint-disable-next-line no-await-in-loop
				await dispatch('postMedia', {
					media: obj.file,
					mediaCode: MEDIA_CODES[obj.code],
				})
					.then((res) => {
						if (obj.isArray) {
							adminRecordPost[obj.name][obj.position] = res['@id']
						} else {
							adminRecordPost[obj.name] = res['@id']
						}
					})
			}
		}
		if (!adminRecordPost.scholarShipMedias[0] && adminRecordPost.scholarShipMedias[1]) {
			adminRecordPost.scholarShipMedias.shift()
		}
		if (!payload.scholarShip) {
			adminRecordPost.scholarShipMedias = []
			adminRecordPost.scholarShipLevel = null
		}
		await putAPI.putWithToken(payload.path, { ...adminRecordPost })
		store.commit('global/SET_LOADER', false)
	},
	postMedia ({ commit }, payload) {
		return new Promise((resolve, reject) => {
			if (payload.media && payload.media['@id']) {
				resolve(payload.media['@id'])
			}
			mediaAPI.postMedia(
				payload.media,
				payload.mediaCode,
			).then((res) => {
				if (payload.commitName) {
					commit(payload.commitName, res)
				}
				resolve(res)
			}).catch((err) => reject(err))
		})
	},
	deleteMedia ({ commit }, payload) {
		if (!payload || payload.state === MEDIA_STATUS.STATE_CANCELLED) {
			return Promise.resolve()
		}
		return new Promise((resolve, reject) => {
			mediaAPI.deleteMedia(
				payload['@id'],
			).then((res) => {
				resolve(res)
			}).catch((err) => reject(err))
		})
	},
	validateAdminRecords ({ commit }, payload) {
		return new Promise((resolve, reject) => {
			if (!payload) {
				reject('no admin record')
			}
			postAPI.postWithToken(`${payload}/completed`, {})
				.then((res) => {
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
}
const getters = {
	filteredAdminRecord () {
		const adminRecord = JSON.parse(JSON.stringify(state.adminRecord))
		if (!adminRecord) {
			return null
		}
		return adminRecord
	},
	filteredBlocLv2 () {
		return filtersBloc.filterActive(state.bloc_lv2)
	},
	isClassicInputDisabled () {
		if (store.state.profile.me.student && (store.state.profile.me.student.state === 'created' || store.state.profile.me.student.state === 'check_diploma')) {
			return false
		}
		return true
	},
	isClotureInscriptions () {
		return new Date() > state.closeDate
	},
	isAcceptedShnMedia () {
		return state.adminRecord?.highLevelSportsmanMedias[0]?.state === MEDIA_STATUS.STATE_ACCEPTED
	},
	isAcceptedTTMedia () {
		return state.adminRecord?.thirdTimeMedias[0]?.state === MEDIA_STATUS.STATE_ACCEPTED
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}

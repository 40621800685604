<template>
	<div
		:class="[
			'heading',
			connectedPage ? 'ast': '',
			flexGapSupported ? '' : 'heading-rg'
		]">
		<div
			class="container"
			:class="flexGapSupported ? '' : 'container-rg'"
			v-if="!connectedPage">
			<div
				class="body"
				:class="flexGapSupported ? '' : 'body-rg'">
				<div class="title">
					<h1><strong>GRANDE ECOLE</strong></h1>
					<h2>ThinkForward*</h2>
				</div>
				<div
					class="cards"
					:class="flexGapSupported ? '' : 'cards-rg'">
					<div
						v-for="(card, index) in filteredCards"
						:key="index"
						class="contest-card card"
						:class="flexGapSupported ? '' : 'contest-card-rg'"
					>
						<h1>
							<span class="text-red">
								<strong>{{ card.label }}</strong>
							</span>
						</h1>
						<div
							class="details"
							v-html="card.content"
						/>
						<router-link
							:to="isExternalUrl(card.link) ? '': card.link"
							:href="isExternalUrl(card.link) ? card.link : ''"
							:target="isExternalUrl(card.link) ? '_blank' : ''"
							:rel="isExternalUrl(card.link) ? 'noopener noreferrer' : ''"
							:data-beaver-id="`btnAccess-${index}`"
							tag="a"
						>
							<p>{{ card.labelLink}}</p>
						</router-link>
					</div>
				</div>
			</div>
		</div>
		<div
			v-else
			class="container ast"
			:class="flexGapSupported ? '' : 'container-rg'">
			<div class="title-container">
				<div class="title-ast">
					<h1><strong>GRANDE ECOLE</strong></h1>
					<h2>ThinkForward*</h2>
				</div>
				<div
					class="ast-details"
					:class="flexGapSupported ? '' : 'ast-details-rg'"
					v-for="(detail, index) in filteredDetail"
					:key="`detail-${index}`"
				>
					<h1>
						<span class="text-red">
							{{ detail.label }}
						</span>
					</h1>
					<div v-html="detail.content" />
					<router-link
						tag="a"
						class="signup"
						:key="`btnAccess-${accesBtnUpdated}`"
						:to="isExternalUrl(detail.link) ? '' : detail.link"
						:href="isExternalUrl(detail.link) ? detail.link : ''"
						:target="isExternalUrl(detail.link) ? '_blank' : ''"
						:rel="isExternalUrl(detail.link) ? 'noopener noreferrer' : ''"
						:data-beaver-id="`btnAccess-${index}`"
					>
						<p>{{detail.labelLink}}</p>
					</router-link>
				</div>
			</div>
			<form
				class="connexion card"
				:class="flexGapSupported ? '' : 'connexion-rg'"
				v-if="contestYear !== undefined"
			>
				<div class="text">
					<h1 v-if="isAST">DÉJÀ INSCRIT</h1>
					<h2 v-if="isAST">AU CONCOURS {{ getProgChanNameParam }} {{ contestYear }} ?</h2>
					<h2 v-else>{{filteredCandidateConnexion[0]?.label}}</h2>
				</div>
				<hr>
				<p
					v-for="(bloc, index) in filteredCandidateConnexion"
					:key="index"
					v-html="bloc.content"
				>
				</p>
				<input-text
					class="inputs"
					:placeholder="'E-mail'"
					v-model="email"
					:regexValidation="emailRegex"
					data-beaver-id="inputConnectionEmail"
					@input="email = email.toLowerCase()"
				/>
				<input-text
					class="inputs"
					:placeholder="'Mot de passe'"
					:isPassword="true"
					:regexValidation="passwordRegex"
					v-model="password"
					data-beaver-id="inputConnectionMdp"
				/>
				<p
					class="forget-password no-selection"
					@click="showResetPassword"
					data-beaver-id="linkForgottenPassword"
				>
					Mot de passe oublié ?
				</p>
				<button
					class="red-button no-selection"
					@click.prevent="connection({email, password})"
					data-beaver-id="btnLogin"
				>
					Se connecter
				</button>
			</form>
		</div>
	</div>
</template>

<script>
import {
	mapState, mapGetters, mapMutations, mapActions,
} from 'vuex'
import InputText from '@/components/Forms/InputText.vue'
import { REGEX_EMAIL, REGEX_PASSWORD } from '@/utils/regexDictionnary.js'

export default {
	name: 'Heading',
	components: {
		InputText,
	},
	data () {
		return {
			email: '',
			password: '',
			emailRegex: REGEX_EMAIL,
			passwordRegex: REGEX_PASSWORD,
			accesBtnUpdated: 0,
		}
	},
	computed: {
		...mapState('global', ['flexGapSupported']),
		...mapState('programChannels', [
			'headingDetails',
		]),
		...mapGetters('home', [
			'filteredCards',
		]),
		...mapGetters('programChannels', [
			'filteredHeadingDetailContent', 'filteredCandidateConnexion',
		]),
		...mapGetters('global', ['getProgChanNameParam']),
		contestYear () {
			return this.headingDetails.contestYear
		},
		filteredDetail () {
			return this.filteredHeadingDetailContent
		},
		connectedPage () {
			return this.$route.fullPath === '/ast' || this.$route.fullPath === '/bce_eco' || this.$route.fullPath === '/bce_lit'
		},
		isAST () {
			return this.$route.fullPath === '/ast'
		},
	},
	watch: {
		filteredDetail () {
			this.accesBtnUpdated = 1
		},
	},
	methods: {
		...mapMutations('modal', {
			showModal: 'SHOW_MODAL',
			hideModal: 'HIDE_MODAL',
		}),
		...mapActions('auth', ['connection']),
		showResetPassword () {
			this.showModal({ name: 'ModalPassword' })
		},
		isExternalUrl (link) {
			return link ? link.includes('http') : true
		},
	},
}
</script>

<style scoped>
.body {
	display: flex;
	flex-direction: column;
	row-gap: 4px;
}
.body-rg > *:not(:last-child)  {
	margin-bottom: 4px;
}
.heading {
	padding: 17px 2px 50px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    background-size: contain;
    width: 100%;
    background-color: var(--beige-1);
    height: 100%;
    background-repeat: no-repeat;
}
.container {
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
}
.container-rg > *:not(:last-child)  {
	margin-bottom: 16px;
}
.container.ast {
	align-items: center;
	justify-content: center;
	max-height: 100%;
	max-width: 1200px;
	padding: 20px;
}
.cards{
	display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
}
.cards-rg > *:not(:last-child)  {
	margin-bottom: 20px;
}
.contest-card {
	flex: 1;
	padding: 30px 34px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
	max-width: 655px;
}
.contest-card-rg > *:not(:last-child)  {
	margin-bottom: 20px;
}
.details /deep/ p {
	line-height: 22px;
	font-size: var(--text-size-2);
}
.ast-details /deep/ {
	display: flex;
	flex-direction: column;
	text-align: center;
	gap: 35px;
}
.ast-details-rg > *:not(:last-child)  {
	margin-bottom: 35px;
}
.connexion {
	flex-basis: 100%;
	padding: 20px 20px;
	max-width: 500px;
	display: flex;
	flex-direction: column;
	align-items: center;
	line-height: 22px;
	gap: 20px;
}
.connexion-rg > *:not(:last-child)  {
	margin-bottom: 20px;
}
.connexion .text {
	text-align: center;
}
.connexion h1 {
	font-size: var(--text-size-3);
}
.connexion h2 {
	font-size: var(--text-size-1);
}
.connexion hr {
	width: 50%;
	filter: var(--from-dark-to-red-1);
	opacity: 0.5;
}
.forget-password {
	width: 100%;
	color: var(--grey-2);
	cursor: pointer;
}

.inputs {
	width: 100%;
}
.signup {
	background: var(--red-1);
	margin: auto;
	width: 65%;
}
.title-container {
	max-width: 465px;
}
.title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
	padding: 20px;
}
.title h1, .title-ast h1 {
	font-family: "Nexa", sans-serif;
	font-size: 52px;
}
.title h2, .title-ast h2 {
	font-family: "Nexa", sans-serif;
	font-size: 42px;
	font-weight: 500;
}
.title-ast {
	padding: 0;
}

a {
	padding: 13px 42px;
	background: var(--red-1);
}
a p {
	color: white;
}
@media screen and (min-width: 1750px) {
.heading {
  background-image: url("@/assets/img/heading_bck.png");
}
}
@media screen and (min-width: 820px) {
.body {
	row-gap: 38px;
}
.body-rg > *:not(:last-child)  {
	margin-bottom: 38px;
}
.heading {
  display: flex;
  justify-content: flex-end;
  padding: 61px 50px 50px 0;
}
.container {
	flex-wrap: nowrap;
}
.container-rg > *:not(:last-child)  {
	margin-right: 16px;
}
.cards{
	flex-direction: row;
}
.cards-rg > *:not(:last-child)  {
	margin-right: 20px;
	margin-bottom: 0;
}
.contest-card{
	max-width: 389px;
}
.connexion{
max-width: 320px;
}
	.heading.ast {
		justify-content: center;
		width: 100%;
		padding: 60px var(--padding-r-l-1);
	}
	.container.ast {
		justify-content: right;
		width: 100%;
		padding: 20px 0;
	}
}

@media only screen and (min-width: 1500px) {
	.heading.ast {
		background-image: url("@/assets/img/heading_bck.png");
	}
}

@media screen and (min-width: 820px) and (max-width: 1749px) {
.title {
    align-items: flex-end;
}
}
/* media query */
</style>

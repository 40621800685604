<template>
	<div class="infos card">
		<div class="info-content">
			<valide-icon
				color="#e7433c"
				width="77"
				class="valide-icon"
			/>
			<div class="">
				<div class="content-text">Paiement en cours</div>
				<div v-html="props.message"></div>
			</div>
		</div>
	</div>
</template>

<script>
import ValideIcon from '@/assets/icons/ValideIcon.vue'

export default {
	name: 'ModalPayment',
	props: ['props'],
	components: {
		ValideIcon,
	},
}
</script>

<style scoped>
.infos {
	position: relative;
    min-width: 590px;
    max-width: 590px;
    padding: 40px;
}
.info-close {
	cursor: pointer;
    position: absolute;
    right: 35px;
    top: 15px;
}
.info-close >>> svg {

	width: 16px;
}
.info-content {
	display: flex;
    justify-content: center;
	font-size: 16px;
	font-weight: normal;
	line-height: 30px;
}
.info-content >>> svg {
	width:77px;
	fill:var(--red-1);
}

.content-text {
	color: var(--red-1);
        font-size: 26px;
}
.info-return {
	display: block;
	color: #fff;
    background-color: #e7433c;
    padding: 11px 20px;
	width: 250px;
	margin: 0 auto;
}

.info-return span {
	align-items: center;
    color: inherit;
    display: flex;
    flex: 1 0 auto;
    justify-content: inherit;
    line-height: normal;
    position: relative;
    transition: inherit;
    transition-property: opacity;
		font-size: 14px;

}
</style>

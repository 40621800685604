import getAPI from '@/utils/getData'
import postAPI from '@/utils/postData'

const BASE_URL = 'api/oral_test_students'
export default {
	getSlotByStudentId (studentId) {
		return getAPI.getWithToken(`${BASE_URL}?student=${studentId}&state[]=validated&state[]=waiting_for_treatment`)
	},
	getSlotById (id) {
		return getAPI.getWithToken(`${id}`)
	},
	checkSlotStatus (id) {
		return getAPI.get(`${id}/check`)
	},
	valid (campusOralDay) {
		return postAPI.postWithToken(`${BASE_URL}`, { campusOralDay })
	},
	cancel (id, oralTestStudent) {
		return postAPI.postWithToken(`${BASE_URL}/${id}/cancel`, { oralTestStudent })
	},
}

import filtersBloc from '@/utils/filtersBloc'
import blocService from '@/services/bloc.service'
import { HYDRA_MEMBER } from '@/utils/api'

const state = {
	isTooltipVisible: false,
	tooltipName: '',
	props: null,
	tooltipDualCourse: [],
	tooltipLv2: [],
	tooltipScholarship: [],
	tooltipSportman: [],
	tooltipArrangement: [],
	tooltipsExp: [],
	tooltipInfoMoyenne: [],
	tooltipInfoMoyenneNonStandard: [],
}

const mutations = {
	SET_DUAL_COURSE_TOOLTIP (state, payload) {
		state.tooltipDualCourse = payload
	},
	SET_LV2_TOOLTIP (state, payload) {
		state.tooltipLv2 = payload
	},
	SET_SCHOLARSHIP_TOOLTIP (state, payload) {
		state.tooltipScholarship = payload
	},
	SET_SPORTMAN_TOOLTIP (state, payload) {
		state.tooltipSportman = payload
	},
	SET_ARRANGEMENT_TOOLTIP (state, payload) {
		state.tooltipArrangement = payload
	},
	SHOW_TOOLTIP (state, payload) {
		state.isTooltipVisible = true
		state.tooltipName = payload.name
		state.props = payload.props
	},
	HIDE_TOOLTIP (state) {
		state.tooltip.isTooltipVisible = false
		state.tooltip.tooltipName = ''
		state.props = null
	},

	SET_EXP_VERBATIMS (state, payload) {
		state.tooltipsExp.push(payload)
	},

	SET_CV_INFO_MOYENNE (state, payload) {
		state.tooltipInfoMoyenne = payload
	},

	SET_CV_INFO_MOYENNE_NON_STANDARD (state, payload) {
		state.tooltipInfoMoyenneNonStandard = payload
	},
}

const actions = {
	initTooltipFolder ({ commit }) {
		return new Promise((resolve, reject) => {
			Promise.all([

				blocService.getBloc({ label: 'ADMINISTRATIVE_RECORD_DIPLOMA_DOUBLE_PARCOURS' })
					.then((res) => commit('SET_DUAL_COURSE_TOOLTIP', res.data[HYDRA_MEMBER])),

				blocService.getBloc({ label: 'ADMINISTRATIVE_RECORD_LV2_INFOBULLE' })
					.then((res) => commit('SET_LV2_TOOLTIP', res.data[HYDRA_MEMBER])),
				blocService.getBloc({ label: 'ADMINISTRATIVE_RECORD_INFO_SCHOLARSHIP' })
					.then((res) => commit('SET_SCHOLARSHIP_TOOLTIP', res.data[HYDRA_MEMBER])),

				blocService.getBloc({ label: 'ADMINISTRATIVE_RECORD_INFO_HIGH_LEVEL_SPORTSMAN' })
					.then((res) => commit('SET_SPORTMAN_TOOLTIP', res.data[HYDRA_MEMBER])),

				blocService.getBloc({ label: 'ADMINISTRATIVE_RECORD_SPORT_TEST_ARRANGEMENT' })
					.then((res) => commit('SET_ARRANGEMENT_TOOLTIP', res.data[HYDRA_MEMBER])),
			]).then(() => resolve('ok'))
		})
	},

	initTooltipExp ({ commit }) {
		blocService.getBloc({ label: 'CV_PROFESSIONAL_EXPERIENCE' })
			.then((res) => commit('SET_EXP_VERBATIMS', res.data[HYDRA_MEMBER]))
		blocService.getBloc({ label: 'CV_INTERNATIONAL_EXPERIENCE' })
			.then((res) => commit('SET_EXP_VERBATIMS', res.data[HYDRA_MEMBER]))
		blocService.getBloc({ label: 'CV_ASSOCIATIVE_EXPERIENCE' })
			.then((res) => commit('SET_EXP_VERBATIMS', res.data[HYDRA_MEMBER]))
	},

	initTooltipBacSup ({ commit }) {
		blocService.getBloc({ label: 'CV_INFO_MOYENNE' })
			.then((res) => commit('SET_CV_INFO_MOYENNE', res.data[HYDRA_MEMBER]))

		blocService.getBloc({ label: 'CV_INFO_MOYENNE_NON_STANDARD' })
			.then((res) => commit('SET_CV_INFO_MOYENNE_NON_STANDARD', res.data[HYDRA_MEMBER]))
	},
}

const getters = {
	filteredTooltipDualCourse () {
		return filtersBloc.filterActive(state.tooltipDualCourse)
	},
	filteredTooltipLv2 () {
		return filtersBloc.filterActive(state.tooltipLv2)
	},
	filteredTooltipScholarship () {
		return filtersBloc.filterActive(state.tooltipScholarship)
	},
	filteredTooltipSportman () {
		return filtersBloc.filterActive(state.tooltipSportman)
	},
	filteredTooltipArrangement () {
		return filtersBloc.filterActive(state.tooltipArrangement)
	},
	filteredTooltipMoyenne () {
		return filtersBloc.filterActive(state.tooltipInfoMoyenne)
	},
	filteredTooltipMoyenneNonStandard () {
		return filtersBloc.filterActive(state.tooltipInfoMoyenneNonStandard)
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}

import getAPI from '@/utils/getData'
import postAPI from '@/utils/postData'

const SET_ACTIVITY_LOGS = 'SET_ACTIVITY_LOGS'

const state = {
	activityLogs: {},
}

const mutations = {
	[SET_ACTIVITY_LOGS] (state, payload) {
		state.activityLogs = payload
	},
}

const actions = {
	fetchActivityLogs ({ commit }) {
		getAPI.getWithToken('api/users/activity_log')
			.then((res) => {
				commit(SET_ACTIVITY_LOGS, res.data)
			})
			.catch((err) => Promise.reject(err))
	},

	postActivityLogs ({
		commit, state, dispatch, getters,
	}, payload) {
		postAPI.post('api/users/activity_log', payload).catch((err) => Promise.reject(err))
	},
}

const getters = {}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}

import filtersBloc from '@/utils/filtersBloc'
import blocService from '@/services/bloc.service'
import { HYDRA_MEMBER } from '@/utils/api'

const state = {
	legaltext: [],
}

const SET_LEGAL_TEXT = 'SET_LEGAL_TEXT'

const mutations = {
	[SET_LEGAL_TEXT] (state, payload) {
		state.legaltext = payload
	},
}

const actions = {
	initLegalText ({ commit }) {
		return blocService.getBloc({ label: 'TEXT_LEGAL' })
			.then((res) => {
				commit(SET_LEGAL_TEXT, res.data[HYDRA_MEMBER])
			})
	},
}

const getters = {
	filteredLegalText () {
		return filtersBloc.filterActive(state.legaltext)
	},

}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}

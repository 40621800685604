<template>
	<div :class="['modal-container', isModalVisible ? 'active' : '']">
		<transition name="pop">
			<div
				class="modal-parent"
				v-if="isModalVisible"
			>
				<component
					:is='modalName'
					:props='props'
				/>
			</div>
		</transition>
	</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import ModalPassword from '@/components/Modals/ModalPassword.vue'
import ModalError from '@/components/Modals/ModalError.vue'
import ModalResignation from '@/components/Modals/ModalResignation.vue'
import ModalInfos from '@/components/Modals/ModalInfos.vue'
import ModalValidCv from '@/components/Modals/ModalValidCv.vue'
import ModalDeleteExperience from '@/components/Modals/ModalDeleteExperience.vue'
import ModalPayment from '@/components/Modals/ModalPayment.vue'
import ModalValidCandidacy from '@/components/Modals/ModalValidCandidacy.vue'
import ModalAccountPayment from '@/components/Modals/ModalAccountPayment.vue'
import ModalCancelOralTest from '@/components/Modals/ModalCancelOralTest.vue';

export default {
	name: 'ModalContainer',
	components: {
		ModalPassword,
		ModalError,
		ModalResignation,
		ModalInfos,
		ModalValidCv,
		ModalDeleteExperience,
		ModalPayment,
		ModalValidCandidacy,
		ModalAccountPayment,
		ModalCancelOralTest,
	},
	data () {
		return {}
	},
	computed: {
		...mapState('modal', ['isModalVisible', 'modalName', 'props']),
	},
	methods: {
		...mapMutations('modal', {
			hideModal: 'HIDE_MODAL',
		}),
	},
}
</script>

<style scoped>
.modal-parent {
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}
.modal-container.active {
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.3);
}
.modal-container {
	transition: all 0.3s ease;
	background-color: transparent;
	position: absolute;
}
.pop-enter {
	opacity: 0;
	transform: scale(0.7);
}
.pop-enter-to {
	opacity: 1;
	transform: scale(1);
}
.pop-leave {
	opacity: 1;
	transform: scale(1);
}
.pop-leave-to {
	opacity: 0;
	transform: scale(0.7);
}
.pop-enter-active,
.pop-leave-active {
  transition: .3s cubic-bezier(.25,.8,.25,1);
}
</style>

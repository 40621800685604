import { HYDRA_DESCRIPTION } from '@/utils/api'

export default {
	getErrorMessage (err) {
		if (err.response.data?.message) {
			return err.response.data.message
		}

		if (err.response.data?.violations) {
			return err.response.data?.violations[0].message
		}

		if (err.response?.data[HYDRA_DESCRIPTION]) {
			return err.response.data[HYDRA_DESCRIPTION]
		}

		if (err.response?.data) {
			let errorsString = ''
			Object.values(err.response.data).forEach((error) => {
				errorsString += error.reduce((acc, current) => {
					return `${acc}<li>${current}</li>`
				}, '')
			})
			return errorsString
		}

		return 'ERREUR'
	},
}

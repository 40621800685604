const state = {
	activateRequired: true,
}

const mutations = {
	ACTIVATE_REQUIRED (state) {
		state.activateRequired = true
	},
	DESACTIVATE_REQUIRED (state) {
		state.activateRequired = false
	},
}

const actions = {
}

const getters = {

}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}

<template>
	<div
		class="navbar-container"
		:class="flexGapSupported ? '' : 'navbar-container-rg'">
			<img
				class="skema-logo hideDesktop"
				src="https://www.skema-bs.fr/_layouts/15/skema.internet.2016/site/branding/img/menu-logo.png?v=2.0 "
				alt="skema business school"
			/>
		<router-link
			:to="`/connected-faq${pcKey}`"
			tag="a"
			data-beaver-id="linkConnectedFaq"
			:class="flexGapSupported ? '' : 'a-rg'"
		>
			<FAQ-icon />
			<p class="hideMobile">FAQ</p>
		</router-link>
		<router-link
			to="/connected-contact"
			tag="a"
			data-beaver-id="linkConnectedContacts"
			:class="flexGapSupported ? '' : 'a-rg'"
		>
			<MailIcon  />
			<p class="hideMobile">Contacts</p>
		</router-link>
		<button
			@click.stop="alertsVisibility"
			:class="[
				'nav-button', isOnNotifPage ? 'active' : '',
				flexGapSupported ? '' : 'nav-button-rg'
			]"
			data-beaver-id="buttonAlerts"
		>
			<notificationIcon />
			<span v-if="filteredNotifsNotSentByCandidat.length > 0" class="notification-counter">{{filteredNotifsNotSentByCandidat.length}}</span>
			<p class="hideMobile">Notifications</p>
			<nav-bar-notifs
				class="navbar-notifs"
				v-if="showAlerts"
			/>
		</button>
		<a
			@click.prevent="disconnect()"
			data-beaver-id="linkLogOut"
			class="hideMobile"
			:class="flexGapSupported ? '' : 'a-rg'"
		>
			<DeconnexionIcon />
			<p>Déconnexion</p>
		</a>
		<nav-menu :class="[
			'nav-menu mobile-menu',
			flexGapSupported ? '' : 'desktop-menu-rg',
			displayMenu ? 'menu--display': ''
			]"
		/>
		<div class="nav-button hideDesktop" @click="setMenuState" data-beaver-id="burger">
            <burger-icon v-if="!this.displayMenu"/>
            <close-icon v-if="this.displayMenu"/>

          </div>
	</div>
</template>

<script>

import { mapGetters, mapActions, mapState } from 'vuex'
import DeconnexionIcon from '@/assets/icons/DeconnexionIcon.vue'
import NotificationIcon from '@/assets/icons/NotificationIcon.vue'
import MailIcon from '@/assets/icons/MailIcon.vue'
import FAQIcon from '@/assets/icons/FAQIcon.vue'
import BurgerIcon from '@/assets/icons/BurgerIcon.vue'
import CloseIcon from '@/assets/icons/CloseIcon.vue'

import NavBarNotifs from '@/components/Notifications/NavBarNotifs.vue'
import NavMenu from '@/components/DashboardView/NavMenu.vue'

export default {
	name: 'ConnectedNavBar',
	components: {
		FAQIcon,
		MailIcon,
		DeconnexionIcon,
		NotificationIcon,
		BurgerIcon,
		CloseIcon,
		NavBarNotifs,
		NavMenu,
	},
	data () {
		return {
			displayMenu: false,
			showAlerts: false,
		}
	},
	computed: {
		...mapState('global', ['programChannels', 'flexGapSupported']),
		...mapState('profile', ['me']),
		...mapGetters('notifications', ['filteredNotifsNotSentByCandidat']),
		isOnNotifPage () {
			return this.$route.fullPath === '/notifications'
		},
		studentProgram () {
			return this.programChannels ? this.programChannels.filter((e) => e['@id'] === this.me.student.programChannel) : undefined
		},
		pcKey () {
			if (this.studentProgram && this.studentProgram.length > 0) {
				return `?pcKey=${this.studentProgram[0].key.indexOf('ast') > -1 ? 'ast' : this.studentProgram[0].key}`
			}
			return ''
		},
	},
	methods: {
		...mapActions('auth', ['disconnect']),
		alertsVisibility () {
			this.showAlerts = !this.showAlerts
		},
		onClickOutside () {
			this.showAlerts = false
		},
		setMenuState () {
			this.displayMenu = !this.displayMenu
		},
	},
	mounted () {
		document.addEventListener('click', this.onClickOutside)
	},
	beforeDestroy () {
		document.removeEventListener('click', this.onClickOutside)
	},
	watch: {
		$route () {
			this.displayMenu = false
		},
	},
}
</script>

<style scoped>
.mobile-menu{
	display: block;
}
.menu--display{
	left:0;
}
.navbar-container {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    border-bottom: 1px solid var(--black-1);
	position: sticky;
    top: 0;
    z-index: 999;
	background: white;
    padding: 20px;
	align-items: baseline;
}
.navbar-container-rg > *:not(:last-child)  {
	margin-right: 15px;
}
.skema-logo{
	width: 107px;
	position: absolute;
	left: 20px;
}
.skema-logo img{
	width: 107px;
}
a,
.nav-button {
	display: flex;
	align-items: center;
	gap: 10px;
	background: transparent;
	cursor: pointer;
	position: relative;
}
.nav-button-rg > *:not(:last-child), .a-rg > *:not(:last-child)  {
	margin-right: 10px;
}
svg {
	width: 25px;
}
.router-link-exact-active,
button.active {
	border-bottom: 5px solid var(--red-1);
	padding-bottom: 10px;
}
.router-link-exact-active p,
button.active p {
	font-weight: bold;
}
.navbar-notifs {
	position: absolute;
	z-index: 10;
	top: calc(100% + 6px);
	left: 50%;
	transform: translateX(-50%);
}
.notification-counter {
	position: absolute;
	top: 5px;
	left: 20px;
	background-color: var(--red-1);
	color: #fff;
	border-radius: 3px;
	padding: 0px 2px;
	font-weight: bold;
}

@media screen and (min-width: 820px) {
	.mobile-menu{
		display: none;
	}
	.navbar-container {
		position: relative;
		gap: 38px;
		height: 100px;
		background: none;
		left: inherit;
		top: inherit;
	}
	.navbar-container-rg > *:not(:last-child)  {
		margin-right: 38px;
	}

	.router-link-exact-active,
	button.active {
		padding-bottom: 30px;
	}
}
</style>

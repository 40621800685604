import getAPI from '@/utils/getData'
import putAPI from '@/utils/putData'
import postAPI from '@/utils/postData'

const BASE_URL = 'api/notifications'
export default {
	getNotification () {
		return getAPI.getWithToken(BASE_URL)
	},
	putNotification (id, payload) {
		return putAPI.putWithToken(`${BASE_URL}/${id}`, payload)
	},
	postNotification (payload) {
		return postAPI.postWithToken(`${BASE_URL}`, payload)
	},
}

<template>
	<div
		class="error-modal card"
		:class="flexGapSupported ? '' : 'error-modal-rg'">
		<h2 class="error-title">{{ props.title || 'Informations manquantes ou erronnées'}}</h2>
		<div
			class="error"
			v-html="props.message"
		></div>
		<button
			class="white-button error-btn"
			@click="$parent.hideModal()"
		>OK</button>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'ModalError',
	props: ['props'],
	computed: {
		...mapState('global', ['flexGapSupported']),
	},
}
</script>

<style scoped>
.error-modal {
    width: 590px;
    min-height: 200px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:16px
}
.error-modal-rg > *:not(:last-child)  {
	margin-bottom: 16px;
}
.error-title {
	font-family: "Nexa", sans-serif;
    color: var(--red-1);
}
.error-btn {
    min-width: 150px;
}
.error {
	padding: 16px
}
</style>

import Vue from 'vue'
import getAPI from '@/utils/getData'
import postAPI from '@/utils/postData'
import putAPI from '@/utils/putData'
import store from '@/store'
import filtersBloc from '@/utils/filtersBloc'
import blocService from '@/services/bloc.service'
import utilsParameters from '@/utils/parameters.js'
import { HYDRA_MEMBER } from '@/utils/api'

const isGMAT = (examKey) => {
	return examKey === 'management_exclusion'
}

const state = {
	verbatims: [],
	reminder: [],
	languageTestInfos: [],
	managementTestInfos: [],
	managementNotaFnege: [],
	managementScoreSubtitle: [],
	managementScoreNota: [],
	managementInscriptionSubtitle: [],
	managementInscriptionNota: [],
	englishScoreSubtitle: [],
	englishScoreNota: [],
	englishInscriptionSubtitle: [],
	englishInscriptionNota: [],
	examSessionType: [],
	englishExtExamClassifications: [],
	managmentExtExamClassifications: [],
	englishSkemaExamClassifications: [],
	managmentSkemaExamClassifications: [],
	englishExamSessions: [],
	managmentExamSessions: [],
	examStudents: [],
	dateClotureInscriptions: [],
	dateFinUploadEpreuveAnglais: [],
	dateFinUploadEpreuveGmat: [],
	dateFinEpreuveManagement: [],
}

const mutations = {
	SET_WRITTEN_TEST_VERBATIM (state, payload) {
		state.verbatims = payload
	},
	SET_WRITTEN_TEST_REMINDER (state, payload) {
		state.reminder = payload
	},
	SET_LANGUAGE_TEST_INFO (state, payload) {
		state.languageTestInfos = payload
	},
	SET_MANAGEMENT_TEST_INFO (state, payload) {
		state.managementTestInfos = payload
	},
	SET_MANAGEMENT_NOTA_BENE_FNEGE (state, payload) {
		state.managementNotaFnege = payload
	},
	SET_MANAGEMENT_SCORE_SUBTITLE (state, payload) {
		state.managementScoreSubtitle = payload
	},
	SET_MANAGEMENT_SCORE_NOTA_BENE (state, payload) {
		state.managementScoreNota = payload
	},
	SET_MANAGEMENT_INSCRIPTION_SUBTITLE (state, payload) {
		state.managementInscriptionSubtitle = payload
	},
	SET_MANAGEMENT_INSCRIPTION_NOTA_BENE (state, payload) {
		state.managementInscriptionNota = payload
	},
	SET_ENGLISH_SCORE_SUBTITLE (state, payload) {
		state.englishScoreSubtitle = payload
	},
	SET_ENGLISH_SCORE_NOTA_BENE (state, payload) {
		state.englishScoreNota = payload
	},
	SET_ENGLISH_INSCRIPTION_SUBTITLE (state, payload) {
		state.englishInscriptionSubtitle = payload
	},
	SET_ENGLISH_INSCRIPTION_NOTA_BENE (state, payload) {
		state.englishInscriptionNota = payload
	},
	SET_EXAM_SESSION_TYPE (state, payload) {
		state.examSessionType = payload
	},
	SET_ENGLISH_EXT_EXAM_CLASSIFICATIONS (state, payload) {
		state.englishExtExamClassifications = payload
	},
	SET_ENGLISH_SKEMA_EXAM_CLASSIFICATIONS (state, payload) {
		state.englishSkemaExamClassifications = payload
	},
	SET_MANAGMENT_EXT_EXAM_CLASSIFICATIONS (state, payload) {
		state.managmentExtExamClassifications = payload
	},
	SET_MANAGMENT_SKEMA_EXAM_CLASSIFICATIONS (state, payload) {
		state.managmentSkemaExamClassifications = payload
	},
	SET_ENGLISH_EXAM_SESSIONS (state, payload) {
		state.englishExamSessions = payload
	},
	SET_MANAGMENT_EXAM_SESSIONS (state, payload) {
		state.managmentExamSessions = payload
	},
	SET_EXAM_STUDENTS (state, payload) {
		state.examStudents = payload
	},
	ADD_EXAM_STUDENT (state, payload) {
		Vue.set(state, 'examStudents', [...state.examStudents, payload])
	},
	PUT_EXAM_STUDENT (state, payload) {
		const index = state.examStudents.findIndex((es) => es['@id'] === payload['@id'])
		state.examStudents[index] = payload
		state.examStudents = [...state.examStudents]
	},
	SET_DATE_CLOTURE_INSCRIPTIONS (state, payload) {
		state.dateClotureInscriptions = new Date(payload)
	},
	SET_DATE_FIN_UPLOAD_EPREUVE_ANGLAIS (state, payload) {
		state.dateFinUploadEpreuveAnglais = new Date(payload)
	},
	SET_DATE_FIN_UPLOAD_EPREUVE_GMAT (state, payload) {
		state.dateFinUploadEpreuveGmat = new Date(payload)
	},
	SET_DATE_FIN_EPREUVE_MANAGEMENT (state, payload) {
		state.dateFinEpreuveManagement = new Date(payload)
	},
}

const actions = {
	initVerbatims ({ commit }) {
		blocService.getBloc({ label: 'WRITTEN_TEST_VERBATIM' })
			.then((res) => commit('SET_WRITTEN_TEST_VERBATIM', res.data[HYDRA_MEMBER]))
	},
	initDateFin ({ commit }) {
		getAPI.get('api/parameters?key.name=dateClotureInscriptions')
			.then((res) => {
				const date = utilsParameters.filterProgramChannel(res.data[HYDRA_MEMBER])
				commit('SET_DATE_CLOTURE_INSCRIPTIONS', date.value)
			})
		getAPI.get('api/parameters?key.name=dateFinUploadEpreuveAnglais')
			.then((res) => {
				const date = utilsParameters.filterProgramChannel(res.data[HYDRA_MEMBER])
				commit('SET_DATE_FIN_UPLOAD_EPREUVE_ANGLAIS', date.value)
			})
		getAPI.get('api/parameters?key.name=dateFinUploadEpreuveGmat')
			.then((res) => {
				const date = utilsParameters.filterProgramChannel(res.data[HYDRA_MEMBER])
				commit('SET_DATE_FIN_UPLOAD_EPREUVE_GMAT', date.value)
			})
		getAPI.get('api/parameters?key.name=dateFinEpreuveManagement')
			.then((res) => {
				const date = utilsParameters.filterProgramChannel(res.data[HYDRA_MEMBER])
				commit('SET_DATE_FIN_EPREUVE_MANAGEMENT', date.value)
			})
	},
	initManagement  ({ commit }) {
		const programChannelId = store.state.profile.me.student ? store.getters['profile/programChannel'] : '1'
		blocService.getBloc({ label: 'WRITTEN_TEST_MANAGEMENT_INFO', programChannels: [{ id: programChannelId }] })
			.then((res) => commit('SET_MANAGEMENT_TEST_INFO', res.data[HYDRA_MEMBER]))
		blocService.getBloc({ label: 'WRITTEN_TEST_MANAGEMENT_NOTA_BENE_FNEGE' })
			.then((res) => commit('SET_MANAGEMENT_NOTA_BENE_FNEGE', res.data[HYDRA_MEMBER]))
		blocService.getBloc({ label: 'WRITTEN_TEST_MANAGEMENT_SCORE_SUBTITLE' })
			.then((res) => commit('SET_MANAGEMENT_SCORE_SUBTITLE', res.data[HYDRA_MEMBER]))
		blocService.getBloc({ label: 'WRITTEN_TEST_MANAGEMENT_SCORE_NOTA_BENE', programChannels: [{ id: programChannelId }] })
			.then((res) => commit('SET_MANAGEMENT_SCORE_NOTA_BENE', res.data[HYDRA_MEMBER]))
		blocService.getBloc({ label: 'WRITTEN_TEST_MANAGEMENT_INSCRIPTION_SUBTITLE', programChannels: [{ id: programChannelId }] })
			.then((res) => commit('SET_MANAGEMENT_INSCRIPTION_SUBTITLE', res.data[HYDRA_MEMBER]))
		blocService.getBloc({ label: 'WRITTEN_TEST_MANAGEMENT_INSCRIPTION_NOTA_BENE' })
			.then((res) => commit('SET_MANAGEMENT_INSCRIPTION_NOTA_BENE', res.data[HYDRA_MEMBER]))
	},
	initEnglish ({ commit }) {
		blocService.getBloc({ label: 'WRITTEN_TEST_ENGLISH_SCORE_SUBTITLE' })
			.then((res) => commit('SET_ENGLISH_SCORE_SUBTITLE', res.data[HYDRA_MEMBER]))
		blocService.getBloc({ label: 'WRITTEN_TEST_ENGLISH_SCORE_NOTA_BENE' })
			.then((res) => commit('SET_ENGLISH_SCORE_NOTA_BENE', res.data[HYDRA_MEMBER]))
		blocService.getBloc({ label: 'WRITTEN_TEST_ENGLISH_INSCRIPTION_SUBTITLE' })
			.then((res) => commit('SET_ENGLISH_INSCRIPTION_SUBTITLE', res.data[HYDRA_MEMBER]))
		blocService.getBloc({ label: 'WRITTEN_TEST_ENGLISH_INSCRIPTION_NOTA_BENE' })
			.then((res) => commit('SET_ENGLISH_INSCRIPTION_NOTA_BENE', res.data[HYDRA_MEMBER]))
	},
	initReminder ({ commit }) {
		const programChannelId = store.state.profile.me.student ? store.getters['profile/programChannel'] : '1'
		blocService.getBloc({ label: 'WRITTEN_TEST_REMINDER', programChannels: [{ id: programChannelId }] })
			.then((res) => commit('SET_WRITTEN_TEST_REMINDER', res.data[HYDRA_MEMBER]))
	},
	languageTestInfos ({ commit }) {
		const programChannelId = store.state.profile.me.student ? store.getters['profile/programChannel'] : '1'
		blocService.getBloc({ label: 'WRITTEN_TEST_ENGLISH_INFO', programChannels: [{ id: programChannelId }] })
			.then((res) => commit('SET_LANGUAGE_TEST_INFO', res.data[HYDRA_MEMBER]))
	},
	async initExamSessionType ({ commit }) {
		return getAPI.getWithToken('api/exam_session_types')
			.then((res) => commit('SET_EXAM_SESSION_TYPE', res.data[HYDRA_MEMBER]))
	},
	initExamClassification ({ commit }) {
		const programChannelId = store.state.profile.me.student ? store.getters['profile/programChannel'] : '1'
		const angId = store.state.writtenTests.examSessionType ? store.state.writtenTests.examSessionType.filter((type) => type.code === 'ANG')[0]['@id'].substr(-1) : ''
		const mgtId = store.state.writtenTests.examSessionType ? store.state.writtenTests.examSessionType.filter((type) => type.code === 'MANAGEMENT')[0]['@id'].substr(-1) : ''

		getAPI.getWithToken(`api/exam_classifications?programChannels.id=${programChannelId}&examSessionType.id=${angId}`)
			.then((res) => commit('SET_ENGLISH_EXT_EXAM_CLASSIFICATIONS', res.data[HYDRA_MEMBER]))

		getAPI.getWithToken(`api/exam_classifications?active&examSession.type=Skema&examSessionType.id=${angId}`)
			.then((res) => commit('SET_ENGLISH_SKEMA_EXAM_CLASSIFICATIONS', res.data['hydra:member']))

		getAPI.getWithToken(`api/exam_classifications?programChannels.id=${programChannelId}&examSessionType.id=${mgtId}`)
			.then((res) => commit('SET_MANAGMENT_EXT_EXAM_CLASSIFICATIONS', res.data[HYDRA_MEMBER]))

		getAPI.getWithToken(`api/exam_classifications?active&examSession.type=Skema&examSessionType.id=${mgtId}`)
			.then((res) => commit('SET_MANAGMENT_SKEMA_EXAM_CLASSIFICATIONS', res.data['hydra:member']))
	},
	loadExamSessions ({ commit }, { examSessionId, inEnglish }) {
		getAPI.getWithToken(`api/exam_sessions?examClassification.id=${examSessionId}&active`)
			.then((res) => commit(inEnglish ? 'SET_ENGLISH_EXAM_SESSIONS' : 'SET_MANAGMENT_EXAM_SESSIONS', res.data[HYDRA_MEMBER]))
	},
	saveRegistration ({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const examSession = JSON.parse(JSON.stringify(payload.examSession))
			const postData = {
				examSession: payload.examSession['@id'],
				media: payload.media ? payload.media['@id'] : null,
				score: payload.score,
				student: payload.student,
			}
			postAPI.postWithToken('api/exam_students', postData)
				.then((res) => {
					const examSessionTypeId = examSession.examClassification.examSessionType
					examSession.examClassification.examSessionType = state.examSessionType.find((est) => est['@id'] === examSessionTypeId)
					const examStudent = { ...res.data, examSession, media: payload.media }
					commit('ADD_EXAM_STUDENT', examStudent)
					resolve(res)
				})
				.catch((err) => reject(err))
		})
	},
	updateExam ({ commit }, { id, exam }) {
		const examSession = JSON.parse(JSON.stringify(exam.examSession))
		const putData = {
			...exam, examSession: exam.examSession['@id'],
		}
		return putAPI.putWithToken(`${id}`, putData)
			.then((res) => {
				const examSessionTypeId = examSession.examClassification.examSessionType['@id']
				examSession.examClassification.examSessionType = state.examSessionType.find((est) => est['@id'] === examSessionTypeId)
				const examStudent = { ...res.data, examSession }
				commit('PUT_EXAM_STUDENT', examStudent)
			})
	},
	createSession ({ commit }, payload) {
		return postAPI.postWithToken('api/exam_sessions', payload)
	},
	async updateSession ({ commit }, { id, session }) {
		return putAPI.putWithToken(`${id}`, session)
	},
	initExamStudents ({ commit }) {
		return getAPI.getWithToken(`${store.state.profile.me.student['@id']}/exam_students`)
			.then((res) => commit('SET_EXAM_STUDENTS', res.data[HYDRA_MEMBER]))
	},
}

const getters = {
	extEnglishExamStudents () {
		return state.examStudents
			.filter((exam) => exam.examSession.examClassification.examSessionType.code === 'ANG'
				&& exam.examSession.type === 'Extérieur')
	},
	skemaEnglishExamStudents () {
		return state.examStudents
			.filter((exam) => exam.examSession.examClassification.examSessionType.code === 'ANG'
				&& exam.examSession.type === 'Skema')
	},
	extManagmentExamStudents () {
		return state.examStudents
			.filter((exam) => exam.examSession.examClassification.examSessionType.code === 'MANAGEMENT'
				&& exam.examSession.type === 'Extérieur')
	},
	filteredExtManagementGMAT () {
		return state.managmentExtExamClassifications
			.filter((exam) => isGMAT(exam.key))
	},
	filteredExtManagement () {
		return state.managmentExtExamClassifications
			.filter((exam) => !isGMAT(exam.key))
	},
	skemaManagmentExamStudents () {
		return state.examStudents
			.filter((exam) => exam.examSession.examClassification.examSessionType.code === 'MANAGEMENT'
				&& exam.examSession.type === 'Skema')
	},
	filteredManagementNotaFnege () {
		return filtersBloc.filterActive(state.managementNotaFnege)
	},
	filteredManagementScoreSubtitle () {
		return filtersBloc.filterActive(state.managementScoreSubtitle)
	},
	filteredManagementScoreNota () {
		return filtersBloc.filterActive(state.managementScoreNota)
	},
	filteredManagementInscriptionSubtitle () {
		return filtersBloc.filterActive(state.managementInscriptionSubtitle)
	},
	filteredManagementInscriptionNota () {
		return filtersBloc.filterActive(state.managementInscriptionNota)
	},
	filteredEnglishScoreSubtitle () {
		return filtersBloc.filterActive(state.englishScoreSubtitle)
	},
	filteredEnglishScoreNota () {
		return filtersBloc.filterActive(state.englishScoreNota)
	},
	filteredEnglishInscriptionSubtitle () {
		return filtersBloc.filterActive(state.englishInscriptionSubtitle)
	},
	filteredEnglishInscriptionNota () {
		return filtersBloc.filterActive(state.englishInscriptionNota)
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
	isGMAT,
}

import axios from 'axios'
import router from '@/router'
import store from '@/store'

const baseDomain = process.env.VUE_APP_API_URL

const baseURL = `${baseDomain}`

const axiosInstance = axios.create({
	baseURL,
})

axiosInstance.interceptors.request.use((config) => {
	if (store && store.state.auth.isSwitchUser === true) {
		// eslint-disable-next-line no-param-reassign
		config.headers = {
			...config.headers,
			Authorization: `Bearer ${store.state.auth.token}`,
			'X-Switch-User': store.state.auth.candidateEmail,
		}
	}
	return config
}, (error) => {
	return Promise.reject(error)
})

axiosInstance.interceptors.response.use((response) => {
	// Any status code that lie within the range of 2xx cause this function to trigger
	// Do something with response data
	return response
}, (error) => {
	if (router.history.current.name === 'switchUser') {
		return Promise.reject(error)
	}
	// Any status codes that falls outside the range of 2xx cause this function to trigger
	// Do something with response error
	if (error.response.status === 401) {
		localStorage.clear()
		if (router.history.current.name !== 'ast' && router.history.current.name !== 'bce_eco' && router.history.current.name !== 'bce_lit') {
			window.location = '/'
		}
	}
	return Promise.reject(error)
})

export default axiosInstance

import getAPI from '@/utils/getData'
import filtersBloc from '@/utils/filtersBloc'
import postApi from '@/utils/postData'
import store from '@/store'
import utilsParameters from '@/utils/parameters.js'
import blocService from '@/services/bloc.service'
import { HYDRA_MEMBER } from '@/utils/api'

const state = {
	startDate: undefined,
	closeDate: undefined,
	before: [],
	after: [],
	contestYear: undefined,
	rulesCheckboxMsg: [],
}

const mutations = {
	SET_START_DATE (state, payload) {
		state.startDate = new Date(payload)
	},
	SET_CLOSE_DATE (state, payload) {
		state.closeDate = new Date(payload)
	},
	SET_BEFORE (state, payload) {
		state.before = payload
	},
	SET_AFTER (state, payload) {
		state.after = payload
	},
	SET_CONTEST_YEAR (state, payload) {
		state.contestYear = payload
	},
	SET_RULES_CHECKBOX_MSG (state, payload) {
		state.rulesCheckboxMsg = payload
	},
}

const actions = {
	initSignup ({ commit }) {
		getAPI.get('api/parameters?key.name=dateDebutInscriptions')
			.then((res) => {
				const date = utilsParameters.filterProgramChannel(res.data['hydra:member'])
				commit('SET_START_DATE', date.value)
			})
		getAPI.get('api/parameters?key.name=dateClotureInscriptions')
			.then((res) => {
				const date = utilsParameters.filterProgramChannel(res.data['hydra:member'])
				commit('SET_CLOSE_DATE', date.value)
			})
		getAPI.get('api/parameters?key.name=anneeConcours')
			.then((res) => {
				const year = utilsParameters.filterProgramChannel(res.data['hydra:member'])
				commit('SET_CONTEST_YEAR', year.value)
			})
		blocService.getBloc({ label: 'CREA_CPT_OPTIN' })
			.then((res) => {
				commit('SET_RULES_CHECKBOX_MSG', res.data[HYDRA_MEMBER])
			})
	},
	getBeforeSignup ({ commit }) {
		return blocService.getBloc({ label: 'ERROR_CANDIDATE_INSCRIPTION_NOT_OPEN' })
			.then((res) => {
				commit('SET_BEFORE', res.data['hydra:member'])
			})
	},
	getAfterSignup ({ commit }) {
		return blocService.getBloc({ label: 'ERROR_CANDIDATE_INSCRIPTION_TOO_LATE' })
			.then((res) => {
				commit('SET_AFTER', res.data['hydra:member'])
			})
	},
	postSignup ({ commit }, payload) {
		const diplomaList = store.getters['global/filteredDiplomas'](payload.diplomaInfos.programChannel, false)
		const postData = {
			email: payload.connexionInfos.emailInput,
			plainPassword: payload.connexionInfos.passwordInput,
			firstName: payload.personalInfos.firstName,
			lastName: payload.personalInfos.lastName,
			student: {
				termAccepted: payload.termAccepted,
				dateOfBirth: payload.personalInfos.birthDate,
				programChannel: payload.diplomaInfos.programChannel,
				firstNameSecondary: payload.personalInfos.otherName,
				gender: payload.personalInfos.gender,
				phone: payload.personalInfos.phoneNumber,
				address: payload.personalInfos.address,
				postalCode: payload.personalInfos.postalCode,
				city: payload.personalInfos.city,
				country: store.state.global.countries[payload.personalInfos.country]['@id'],
				countryBirth: store.state.global.countries[payload.personalInfos.birthCountry]['@id'],
				nationality: store.state.global.countries[payload.personalInfos.nationality]['@id'],
				nationalitySecondary: payload.personalInfos.nationality2 && payload.personalInfos.nationality2 > -1 && store.state.global.countries[payload.personalInfos.nationality2] ? store.state.global.countries[payload.personalInfos.nationality2]['@id'] : null,
				administrativeRecord: {
					studentDiplomas: [
						{
							year: parseInt(payload.diplomaInfos.year, 10),
							diploma: diplomaList[payload.diplomaInfos.diploma]['@id'],
							diplomaChannel: diplomaList[payload.diplomaInfos.diploma].needDetail ? null : diplomaList[payload.diplomaInfos.diploma].diplomaChannels[payload.diplomaInfos.diplomaChannel]['@id'],
							detail: payload.diplomaInfos.detail === '' ? null : payload.diplomaInfos.detail,
							establishment: payload.diplomaInfos.school,
							postalCode: payload.diplomaInfos.postalCode,
							city: payload.diplomaInfos.city,
							lastDiploma: true,
						},
					],
				},
			},

		}

		return postApi.post('api/users', postData)
	},
}

const getters = {
	filteredBefore () {
		return filtersBloc.filterActive(state.before)
	},
	filteredAfter () {
		return filtersBloc.filterActive(state.after)
	},
	filteredRulesCheckboxMsg () {
		return filtersBloc.filterActive(state.rulesCheckboxMsg)[0] || ''
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}

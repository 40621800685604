import getAPI from '@/utils/getData'
import filtersBloc from '@/utils/filtersBloc'
import utilsParameters from '@/utils/parameters.js'
import router from '@/router'
import blocService from '@/services/bloc.service'
import { HYDRA_MEMBER } from '@/utils/api'

const state = {
	infos: {
		useful: [],
		links: [],
		contest: [],
	},
	headingDetails: {
		content: [],
		contestYear: '',
	},
	infosContest: [],
	documents: [],
	calendar: [],
	candidateConnexion: [],
}

const SET_USEFUL = 'SET_USEFUL'
const SET_LINKS = 'SET_LINKS'
const SET_CONTEST = 'SET_CONTEST'
const SET_HEADING_DETAIL_CONTENT = 'SET_HEADING_DETAIL_CONTENT'
const SET_HEADING_DETAIL_CONTEST_YEAR = 'SET_HEADING_DETAIL_CONTEST_YEAR'
const SET_INFOS_CONTEST = 'SET_INFOS_CONTEST'
const SET_DOCUMENTS = 'SET_DOCUMENTS'
const SET_CALENDAR = 'SET_CALENDAR'
const SET_CANDIDATE_CONNEXION = 'SET_CANDIDATE_CONNEXION'

const mutations = {
	[SET_USEFUL] (state, payload) {
		state.infos.useful = payload
	},
	[SET_LINKS] (state, payload) {
		state.infos.links = payload
	},
	[SET_CONTEST] (state, payload) {
		state.infos.contest = payload
	},
	[SET_HEADING_DETAIL_CONTENT] (state, payload) {
		state.headingDetails.content = payload
	},
	[SET_HEADING_DETAIL_CONTEST_YEAR] (state, payload) {
		state.headingDetails.contestYear = payload
	},
	[SET_INFOS_CONTEST] (state, payload) {
		state.infosContest = payload
	},
	[SET_DOCUMENTS] (state, payload) {
		state.documents = payload
	},
	[SET_CALENDAR] (state, payload) {
		state.calendar = payload
	},
	[SET_CANDIDATE_CONNEXION] (state, payload) {
		state.candidateConnexion = payload
	},
}

const actions = {
	initInfos ({ commit, getters }) {
		const programChannelsParams = getters.getMultiProgChan
		blocService.getBloc({ label: 'HOME_PGE_INFOS_PRATIQUES', programChannels: programChannelsParams })
			.then((res) => commit(SET_USEFUL, res.data[HYDRA_MEMBER]))

		blocService.getBloc({ label: 'HOME_PGE_LIENS_UTILES', programChannels: programChannelsParams })
			.then((res) => commit(SET_LINKS, res.data[HYDRA_MEMBER]))

		blocService.getBloc({ label: 'HOME_PGE_CONCOURS_INTERNATIONAL', programChannels: programChannelsParams })
			.then((res) => commit(SET_CONTEST, res.data[HYDRA_MEMBER]))
	},
	initHeadingAst ({ commit, getters, state }) {
		blocService.getBloc({ label: 'AST_HP_TITLE', programChannels: getters.getMultiProgChan })
			.then((res) => commit(SET_HEADING_DETAIL_CONTENT, res.data[HYDRA_MEMBER]))

		getAPI.get('api/parameters?key.name=anneeConcours&order[position]=asc')
			.then((res) => {
				const year = utilsParameters.filterProgramChannel(res.data[HYDRA_MEMBER])
				commit(SET_HEADING_DETAIL_CONTEST_YEAR, year.value)
			})

		blocService.getBloc({ label: 'CANDIDATE_CONNEXION', programChannels: getters.getMultiProgChan })
			.then((res) => commit(SET_CANDIDATE_CONNEXION, res.data[HYDRA_MEMBER]))
	},
	initInfosContest ({ commit, getters }) {
		blocService.getBloc({ label: 'HOME_PGE_INFORMATIONS_CONCOURS', programChannels: getters.getMultiProgChan })
			.then((res) => commit(SET_INFOS_CONTEST, res.data[HYDRA_MEMBER]))
	},
	initDocuments ({ commit, getters }) {
		blocService.getBloc({ label: 'HOME_DROITE_LIGNE_2', programChannels: getters.getMultiProgChan })
			.then((res) => commit(SET_DOCUMENTS, res.data[HYDRA_MEMBER]))
	},
	initCalendar ({ commit, getters }) {
		getAPI.get(`api/events?order[paramStart.valueDateTime]=asc&order[paramEnd.valueDateTime]=asc${getters.getProgChanQueryParam}`)
			.then((res) => commit(SET_CALENDAR, res.data[HYDRA_MEMBER]))
	},
}

const getters = {
	filteredHeadingDetailContent () {
		return filtersBloc.filterActive(state.headingDetails.content)
	},
	filteredInfosUseful () {
		return filtersBloc.filterActive(state.infos.useful)
	},
	filteredInfosLinks () {
		return filtersBloc.filterActive(state.infos.links)
	},
	filteredInfosContest () {
		return filtersBloc.filterActive(state.infos.contest)
	},
	filteredContestInfos () {
		return filtersBloc.filterActive(state.infosContest)
	},
	filteredDocuments () {
		return filtersBloc.filterActive(state.documents)
	},
	getMultiProgChan (state, getters, rootState) {
		if (rootState.global.route.name === 'ast') {
			return rootState.global.programChannelsList.filter((el) => el.key.slice(0, -1) === 'ast')
		}

		return rootState.global.programChannelsList.filter((el) => el.key === rootState.global.route.name)
	},
	getProgChanQueryParam (state, getters, rootState, rootGetters) {
		if (rootState.global.route.name === 'ast') {
			return `&programChannels.id=${rootState.global.programChannelsList.filter((el) => el.key === 'ast1')[0].id}`
		}
		const getCurrentId = rootState.global.programChannelsList.filter((el) => el.key === rootState.global.route.name)[0].id
		if (getCurrentId !== undefined) {
			return `&programChannels.id=${getCurrentId}`
		}
		return ''
	},
	filteredCandidateConnexion () {
		const channel = router.history.current.fullPath.substr(1)
		return filtersBloc.filterActive(state.candidateConnexion)
			.filter((b) => b.programChannels.some((pc) => pc.key.includes(channel)))
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}

/* eslint-disable */

export const REGEX_EMAIL = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
export const REGEX_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/
export const REGEX_YEAR = /\d{4}/
export const REGEX_8CHARS = /(?=.{8,})/g
export const REGEX_MAX_50CHARS = /^.{1,50}$/g
export const REGEX_ONE_DIGIT = /(?=.*[0-9])/g
export const REGEX_ONE_UPPER = /(?=.*[A-Z])/g
export const REGEX_ONE_LOWER = /(?=.*[a-z])/g
export const REGEX_ONE_SPE = /(?=.*[!@#$%^&*])/g
export const REGEX_DATE = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/
export const REGEX_PHONE = /^\+?[0-9]{5,20}$/
export const REGEX_POSTAL  = /^[0-9A-Z\s-]{2,20}$/
export const REGEX_SCORE = /^(?:1?\d(?:[\,\.]\d*)?|20(?:\.0?0?)?)$/
export const REGEX_SCORE_2 = /^[+-]?([0-9]*[.|,])?[0-9]+$/
export const REGEX_PARTIALS_HOURS = /^([1-9]|[1-2][0-9]|[3][0-5])$/
export const REGEX_ALPHANUMERIC = /^[a-zA-Z0-9]*$/
export const REGEX_NPINTEGERS =  /^-?\d+$/

<template>
	<div class="infos card">
		<div @click="$parent.hideModal()" class="info-close">
			<close-icon
				class="close-icon"
				data-beaver-id="iconCloseThanks"
				v-if="props.closeIcon !== false"
			/>
		</div>
		<div class="info-content">
			<valide-icon color="#e7433c" width="77" class="valide-icon" v-if="props.displayIcon !== false"/>
			<div class="content">
				<div class="content-text" v-html="props.title">Merci !</div>
				<div v-html="props.message"></div>
				<div class="loader" v-if="props.loader === true">
					<div class="spinner"></div>
					<div class="spinner"></div>
					<div class="spinner"></div>
				</div>
			</div>
		</div>
		<button
			class="info-return"
			type="primary"
			width="250"
			data-beaver-id="btnReturnToDashboard"
			v-if="props.showDashboardButton !== false"
			@click="$router.push('/dashboard'), $parent.hideModal()">
				<span>Retour sur votre tableau de bord</span>
		</button>
	</div>
</template>

<script>
import CloseIcon from '@/assets/icons/CloseIcon.vue'
import ValideIcon from '@/assets/icons/ValideIcon.vue'

export default {
	name: 'ModalInfos',
	props: ['props'],
	components: {
		CloseIcon,
		ValideIcon,
	},
}
</script>

<style scoped>
.infos {
    max-width: 400px;
    position: relative;
    margin: 10% auto;
    padding: 16px 16px ;
}
.info-close {
	cursor: pointer;
    position: absolute;
    right: 35px;
    top: 15px;
}
.info-close >>> svg {

	width: 16px;
}
.info-content {
	display: flex;
    justify-content: center;
	font-size: 16px;
	font-weight: normal;
	line-height: 30px;
	margin-bottom: 30px;
}
.info-content >>> svg {
	width:77px;
	fill:var(--red-1);
	margin-right: 20px;
}

.content-text {
	color: var(--red-1);
        font-size: 26px;
}
.info-return {
	display: block;
	color: #fff;
    background-color: #e7433c;
    padding: 11px 20px;
	width: 250px;
	margin: 0 auto;
}

.info-return span {
	align-items: center;
    color: inherit;
    display: flex;
    flex: 1 0 auto;
    justify-content: inherit;
    line-height: normal;
    position: relative;
    transition: inherit;
    transition-property: opacity;
		font-size: 14px;

}
.card{
	border-radius: var(--radius-1);
    box-shadow: var(--shadow-card);
}

.info-content .loader, .info-content {
	margin-bottom: 0;
}

.loader {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px
}

.spinner {
    width: 30px;
    height: 30px;
    border: 6px solid var(--red-1);
    border-radius: 6px;
    animation: 1.5s linear infinite spinner;
    animation-play-state: inherit;
}

@keyframes spinner {
    0% {
        transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }
    100% {
        transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
}

@media only screen and (min-width: 750px) {

	.infos {
	position: relative;
    min-width: 590px;
    max-width: 590px;
    padding: 40px;
}
}
</style>

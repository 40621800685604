<template>
  <div class="nav-menu-container" v-if="isAuthenticated">
    <div class="nav-menu-top">
			<img
				class="skema-logo"
				src="https://www.skema-bs.fr/_layouts/15/skema.internet.2016/site/branding/img/menu-logo.png?v=2.0 "
				alt="skema business school"
			/>
			<div class="user-picture"><CandidatIcon /></div>
			<div class="user-identity">
				<p><span class="text-grey-2">Bonjour,</span></p>
				<p data-beaver-id="txtNames">{{ me.firstName }} {{ me.lastName }}</p>
				<p class="id">
					<strong>
						N° candidat
						<span class="text-red" data-beaver-id="txtCandidateNumber">
						{{ studentProgram.length === 0 ? '?' : studentProgram[0].name }}</span>
					</strong>
					: {{ me.student ? me.student.identifier : '?' }}
				</p>
			</div>
    </div>
    <nav v-if="me">
		<ul class="nav-menu-bottom">
			<li
				v-for="(element, index) in navigation"
				:key="`nav-element-${index}`"
				class="nav-menu-item"
				:class="[element.disable ? 'disable' : '']"
			>
				<router-link
					:to="element.link"
					tag="a"
					:data-beaver-id="`navMenuElement-${index}`"
				>
					<component :is="element.icon" />
					<p>{{ element.name }}</p>
				</router-link>
			</li>
		</ul>

		<button class="hideDesktop btn-logout" @click.prevent="disconnect()" data-beaver-id="buttonDeconnexion">
			<DeconnexionIcon />
			<p>Déconnexion</p>
		</button>
    </nav>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import CandidatIcon from '@/assets/icons/CandidatIcon.vue'
import AccueilIcon from '@/assets/icons/AccueilIcon.vue'
import ProfileIcon from '@/assets/icons/ProfileIcon.vue'
import DossierIcon from '@/assets/icons/DossierIcon.vue'
import CVIcon from '@/assets/icons/CVIcon.vue'
import EcritIcon from '@/assets/icons/EcritIcon.vue'
import OralIcon from '@/assets/icons/OralIcon.vue'
import ResultatIcon from '@/assets/icons/ResultatIcon.vue'
import PaymentCardIcon from '@/assets/icons/PaymentCardIcon.vue'
import DeconnexionIcon from '@/assets/icons/DeconnexionIcon.vue'
import MaVenueIcon from '@/assets/icons/MaVenueIcon.vue'
import { STUDENT_STATE } from '@/utils/student.js'

export default {
	name: 'NavMenu',
	components: {
		CandidatIcon,
		AccueilIcon,
		ProfileIcon,
		DossierIcon,
		CVIcon,
		EcritIcon,
		OralIcon,
		ResultatIcon,
		DeconnexionIcon,
		PaymentCardIcon,
		MaVenueIcon,
	},
	data () {
		return {
		}
	},
	computed: {
		...mapState('profile', ['me']),
		...mapGetters('profile', ['isBCE']),
		...mapState('global', ['programChannelsList', 'flexGapSupported']),
		...mapActions('auth', ['disconnect']),
		...mapState('dashboard', ['candidacySteps']),
		...mapState('results', ['dateResultatsAdmissibilite']),
		...mapState('oralTests', ['dateOuvertureRDV', 'dateFermetureRDV']),
		...mapGetters('oralTests', { isForbiddenOralTests: 'isForbidden' }),
		...mapGetters('auth', ['isAuthenticated']),
		...mapState('paymentsHistory', ['orders']),
		...mapState('programmeTaVenue', ['ptv']),

		studentProgram () {
			try {
				return this.programChannelsList.filter((e) => e['@id'] === this.me.student.programChannel)
			} catch {
				return []
			}
		},

		navigation () {
			return [
				{
					name: 'Tableau de bord',
					link: '/dashboard',
					icon: 'AccueilIcon',
					disable: false,
				},
				{
					name: 'Mon profil',
					link: '/profile',
					icon: 'ProfileIcon',
					disable: false,
				},
				{
					name: 'Mon dossier administratif',
					link: '/folder',
					icon: 'DossierIcon',
					disable: this.isBCE,
				},
				{
					name: 'Mon CV',
					link: '/cv',
					icon: 'CVIcon',
					disable: this.cvForbidden,
				},
				{
					name: 'Mes épreuves écrites',
					link: '/epreuves-ecrites',
					icon: 'EcritIcon',
					disable: this.writtenExaminationForbidden,
				},
				{
					name: 'Mes épreuves orales',
					link: '/epreuves-orales',
					icon: 'OralIcon',
					disable: this.isForbiddenOralTests,
				},
				{
					name: 'Ma venue',
					link: '/venue',
					icon: 'MaVenueIcon',
					disable: this.forbiddenPTV,
				},
				{
					name: 'Mes résultats',
					link: '/resultats',
					icon: 'ResultatIcon',
					disable: this.resultForbidden,
				},
				{
					name: 'Mes paiements',
					link: '/historique-paiements',
					icon: 'PaymentCardIcon',
					disable: !this.orders || this.orders.length === 0,
				},
			]
		},
		cvForbidden () {
			return this.isBCE || (this.candidacySteps && this.candidacySteps.cv === 'forbidden')
		},
		writtenExaminationForbidden () {
			return this.isBCE || (this.candidacySteps && this.candidacySteps.writtenExamination === 'forbidden')
		},
		admissibleStudent () {
			return this.me.student.state === STUDENT_STATE.admissible
		},
		resultForbidden () {
			return !this.dateResultatsAdmissibilite || this.dateResultatsAdmissibilite > new Date()
		},
		forbiddenPTV () {
			return this.me.student.state !== STUDENT_STATE.REGISTERED_EO && this.ptv.state !== 'terminated'
		},
	},
	methods: {
	},
	mounted () {
	},
}
</script>

<style scoped>
.mobile-menu {
  z-index: 50;
  position: fixed;
  width: 100%;
  top:66px;
  height: calc(100vh - 66px);
  background-color: var(--light-grey-1);
  left: -200vw;
  border-left: 20px solid rgba(12, 12, 12, 0.4);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  display: flex;
  flex-direction: column;
  transition: all 0.5s ease-in-out;
  overflow: scroll;
}

.skema-logo {
	display: none;
}
.mobile-menu .nav-menu-top {
	padding: 30px 20px;
	display: flex;
	gap: 30px;
	align-items: center;
}
.mobile-menu-rg .nav-menu-top > *:not(:last-child)  {
	margin-right: 30px;
}
.mobile-menu .nav-menu-top >>> svg {
  width: 100px;
  height: 100px;
}
.mobile-menu .user-picture >>> svg{
	display: inline-block;
    width: 40px;
    height: 40px;
}
.mobile-menu .user-identity {
	text-align: left;
}
.mobile-menu .nav-menu-bottom {
	display: flex;
	flex-direction: column;
}
.mobile-menu .nav-menu-bottom .disable {
	display: none;
}
.mobile-menu .nav-menu-bottom svg {
	width: 25px;
	fill: var(--grey-2);
	margin-right: 15px;
    align-self: center;
    opacity: 0.6;
}
.mobile-menu .nav-menu-item {
	height: 60px;
    display: flex;
    border: 1px solid #cecece;
	border-bottom: 0;
}
.mobile-menu .nav-menu-item:last-child {
    border: 1px solid #cecece;
}
.disable {
    color: black;
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
}
.mobile-menu .nav-menu-bottom a {
	text-decoration: none;
    color: black;
    padding-left: 20px;
    align-items: center;
    height: 100%;
    letter-spacing: 0px;
    width: 100%;
    display: flex;
    line-height: 60px;
}
.btn-logout {
	width: 100%;
	display: flex;
	height: 95px;
	align-items: center;
	padding-left: 20px;
}
.btn-logout >>> svg{

	width: 25px;
	margin-right: 15px;
	align-self: center;
}
.router-link-exact-active {
  font-weight: bold;
  border-right: 4px solid var(--red-1);
}
.router-link-exact-active p{
	font-weight: bold;
}
.router-link-exact-active svg {
  fill: var(--black-0);
}
::-webkit-scrollbar {
  width: 3px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--light-grey-1);
  border-radius: 3px;
}
::-webkit-scrollbar-thumb {
  background: var(--light-grey-2);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--grey-2);
}
@media screen and (min-width: 820px) {
	.desktop-menu {
		height: 100%;
		gap: 28px;
		flex-direction: column;
		padding: 35px 0;
		box-sizing: border-box;
		display:flex;
		width: 100%;
	}
	.desktop-menu-rg > *:not(:last-child)  {
		margin-bottom: 28px;
	}
	.desktop-menu .skema-logo {
		width: 100px;
		display: block;
	}

	.desktop-menu .user-identity .id {
		margin-top: 10px;
	}
	.desktop-menu .nav-menu-top {
		padding: 0 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px;
		padding-bottom: 30px;
		border-bottom: 1px solid var(--light-grey-2);
	}
	.desktop-menu-rg .nav-menu-top > *:not(:last-child)  {
		margin-bottom: 10px;
	}
	.nav-menu-top >>> svg {
		width: 100px;
		height: 100px;
	}
	.desktop-menu .nav-menu-bottom {
		display: flex;
		flex-direction: column;
		gap: 30px;
	}
	.desktop-menu .nav-menu-bottom .disable {
		display: none;
	}
	.desktop-menu-rg .nav-menu-bottom > *:not(:last-child)  {
		margin-bottom: 30px;
	}
	.desktop-menu .nav-menu-bottom a {
		padding: 0 20px;
		display: flex;
		gap: 15px;
		width: 100%;
		height: 100%;
		align-items: center;
	}
	.desktop-menu-rg .nav-menu-bottom a > *:not(:last-child)  {
		margin-right: 15px;
	}
	.desktop-menu .nav-menu-bottom >>> svg {
		width: 21px;
		height: 20px;
		fill: var(--grey-2);
		vertical-align: text-bottom;
	}
	.desktop-menu .user-identity {
		text-align: center;
	}
	.desktop-menu .nav-menu-item {
		display: flex;
		height: 30px;
		align-items: center;
	}
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'

Vue.use(Vuex)

export default new Vuex.Store({
	modules,
	plugins: [], // pas de persistedState le pckg est plus maintenu à jour, on le fera nous même
	strict: process.env.NODE_ENV !== 'production',
})

import axios from './axios'
import store from '../store'

export default {
	deleteWithToken (query) {
		const headerConfig = {
			'Content-type': 'application/json;charset=UTF-8',
			Authorization: `Bearer ${store.state.auth.token}`,
		}
		return axios.delete(`${query}`, { headers: headerConfig })
	},
}

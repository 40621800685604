/* eslint-disable max-len */
import filtersBloc from '@/utils/filtersBloc'
import store from '@/store'
import blocService from '@/services/bloc.service'
import notificationsService from '@/services/notifications.service'
import { HYDRA_MEMBER } from '@/utils/api'
import getAPI from '@/utils/getData'

const state = {
	verbatims: [],
	notifications: [],
	readNotifications: [],
	contactNotification: {},
	selectedNotification: localStorage.getItem('SELECTED_NOTIFICATION'),
	contactSubjects: [],
}

const SET_VERBATIMS = 'SET_VERBATIMS'
const SET_NOTIFICATION = 'SET_NOTIFICATION'
const SET_READ_NOTIFICATION = 'SET_READ_NOTIFICATION'
const SET_CONTACT_NOTIFICATION = 'SET_CONTACT_NOTIFICATION'
const SET_SELECTED_NOTIFICATION = 'SET_SELECTED_NOTIFICATION'
const ADD_NOTIFICATION = 'ADD_NOTIFICATION'
const SET_CONTACT_SUBJECTS = 'SET_CONTACT_SUBJECTS'

const mutations = {
	[SET_VERBATIMS] (state, payload) {
		state.verbatims = payload
	},
	[SET_NOTIFICATION] (state, payload) {
		state.notifications = payload
	},
	[SET_READ_NOTIFICATION] (state, payload) {
		state.readNotifications = payload
	},
	[SET_CONTACT_NOTIFICATION] (state, payload) {
		state.contactNotification = payload
	},
	[SET_SELECTED_NOTIFICATION] (state, payload) {
		state.selectedNotification = payload
		localStorage.setItem('SELECTED_NOTIFICATION', payload)
	},
	[ADD_NOTIFICATION] (state, payload) {
		state.notifications = [...state.notifications, payload]
	},
	[SET_CONTACT_SUBJECTS] (state, payload) {
		state.contactSubjects = payload
	},
}

const actions = {
	initNotification ({ commit }) {
		notificationsService.getNotification()
			.then((res) => commit(SET_NOTIFICATION, res.data[HYDRA_MEMBER]))
	},
	initVerbatimNotification ({ commit }) {
		blocService.getBloc({ label: 'NOTIFICATION_VERBATIM' })
			.then((res) => commit(SET_VERBATIMS, res.data[HYDRA_MEMBER]))
	},
	initContactSubjects ({ commit }) {
		return getAPI.getWithToken('api/notification_subjects')
			.then((res) => {
				commit(SET_CONTACT_SUBJECTS, res.data[HYDRA_MEMBER])
			})
	},
	readNotification ({ commit }, notifId) {
		notificationsService.putNotification(notifId, { read: true })
			.then((res) => commit(SET_READ_NOTIFICATION, res.data[HYDRA_MEMBER]))
	},
	postContact ({ commit }, payload) {
		const postData = {
			notificationSubject: state.contactSubjects[payload.notificationSubject]['@id'],
			subject: payload.subject,
			content: payload.content,
		}
		notificationsService.postNotification(postData)
			.then((res) => commit(SET_CONTACT_NOTIFICATION, res.data[HYDRA_MEMBER]))
	},
	postResponse ({ commit }, payload) {
		const postData = {
			parent: payload.parent,
			subject: payload.subject,
			content: payload.content,
		}
		return notificationsService.postNotification(postData)
			.then((res) => commit(ADD_NOTIFICATION, res.data))
	},
}

const getters = {
	sortedNotifications () {
		return state.notifications.slice().sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).filter((n) => !n.parent)
	},
	parentOnlyWithNotReadChildren (state, getters, rootState) {
		return new Set(state.notifications
			.slice()
			.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
			.filter((n) => n.parent && n.read === false && n.sender.id !== rootState.profile.me.id)
			.map((n) => n.parent['@id']))
	},
	filteredNotifications () {
		return filtersBloc.filterActive(state.verbatims)
	},
	filteredNotifsNotSentByCandidat () {
		return state.notifications.slice().sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).filter((n) => n.read === false && (!n.sender || n.sender.id !== store.state.profile.me.id))
	},
	selectedNotificationWithChildren () {
		return state.notifications
			.filter((n) => n['@id'] === state.selectedNotification || (n.parent && n.parent['@id'] === state.selectedNotification))
			.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}

import moment from 'moment'
import getAPI from '@/utils/getData'
import putAPI from '@/utils/putData'
import postAPI from '@/utils/postData'
import store from '@/store'
import blocService from '@/services/bloc.service'
import { HYDRA_MEMBER } from '@/utils/api'
import { STUDENT_STATE } from '@/utils/student'

const state = {
	verbatims: [],
	resignationVerbatims: [],
	me: {},

}

const SET_VERBATIMS = 'SET_VERBATIMS'
const SET_RESIGNATION_VERBATIMS = 'SET_RESIGNATION_VERBATIMS'
const SET_ME = 'SET_ME'

const mutations = {
	[SET_VERBATIMS] (state, payload) {
		state.verbatims = payload
	},
	[SET_RESIGNATION_VERBATIMS] (state, payload) {
		state.resignationVerbatims = payload
	},
	[SET_ME] (state, payload) {
		state.me = payload
	},
}

function formatMeData (res) {
	const { data } = res
	data.student.dateOfBirth = res.data.student.dateOfBirth.substring(0, 10).split('-').reverse().join('/')
	data.student.countryBirth = store.getters['global/countryIndexById'](res.data.student.countryBirth)
	data.student.nationality = store.getters['global/countryIndexById'](res.data.student.nationality)
	data.student.nationalitySecondary = res.data.student.nationalitySecondary ? store.getters['global/countryIndexById'](res.data.student.nationalitySecondary) : null
	data.student.country = store.getters['global/countryIndexById'](res.data.student.country)
	return { data }
}

const actions = {
	initProfileBlocs ({ commit, dispatch }) {
		blocService.getBloc({ label: 'PROFIL_VERBATIM' })
			.then((res) => commit(SET_VERBATIMS, res.data[HYDRA_MEMBER]))

		dispatch('initResignationVerbatim')
	},

	initResignationVerbatim ({ commit }) {
		return blocService.getBloc({ label: 'RESIGNATION_VERBATIM' })
			.then((res) => {
				commit(SET_RESIGNATION_VERBATIMS, res.data[HYDRA_MEMBER])
			})
	},

	async initMe ({ commit }, { forceGet = false }) {
		if (state.me.student && !forceGet) {
			return Promise.resolve(state.me)
		}

		return new Promise((resolve) => {
			getAPI.getWithToken('api/users/me')
				.then((res) => {
					const { data } = formatMeData(res)
					commit(SET_ME, data)
					resolve(data)
				})
		})
	},
	putProfile ({ commit }, payload) {
		const putData = {
			firstName: payload.firstName,
			lastName: payload.lastName,
			student: {
				id: state.me.student.id,
				dateOfBirth: moment(payload.birthDate, 'YYYY-MM-DD', true).isValid() ? payload.birthDate : payload.birthDate.split('/').reverse().join('-'),
				programChannel: state.me.student.programChannel,
				firstNameSecondary: payload.otherName,
				gender: payload.gender,
				phone: payload.phoneNumber,
				address: payload.address,
				postalCode: payload.postalCode,
				city: payload.city,
				country: store.state.global.countries[payload.country]['@id'],
				countryBirth: store.state.global.countries[payload.birthCountry]['@id'],
				nationality: store.state.global.countries[payload.nationality]['@id'],
				nationalitySecondary: payload.nationality2 && store.state.global.countries[payload.nationality2] ? store.state.global.countries[payload.nationality2]['@id'] : null,
			},
		}

		return putAPI.putWithToken(`api/users/${state.me.id}`, putData)
			.then((res) => {
				const { data } = formatMeData(res)
				data.student.programChannel = res.data.student.programChannel['@id']
				commit(SET_ME, data)
			})
	},
	postConnexionInfos ({ commit }, payload) {
		const postData = {
			email: state.me.email,
			old_password: payload.oldPassword,
			new_password: payload.password,
			confirmation_password: payload.passwordConfirm,
		}
		return postAPI.postWithToken('api/reset-password/modify', postData)
	},
	resignation () {
		return putAPI.putWithToken(`api/students/${state.me.student.id}/resignation`, {})
	},
	cancelRegistration ({ commit }, payload) {
		// TODO: cancel_registration
		return postAPI.postWithToken(`api/students/${state.me.student.id}/cancel_registration`, {
			reason: payload.reason,
		})
	},
	activation ({ commit }, { token }) {
		return postAPI.post('api/students/activation', { token })
	},

}

const getters = {
	isRejectedAdmissible () {
		const { state: studentState } = state.me.student
		return [STUDENT_STATE.rejected_admissible].includes(studentState)
	},
	role (state, getters, rootState) {
		if (!state.me.student) {
			return ''
		}
		const { programChannel } = state.me.student
		return rootState.global.programChannelsList
			.find((pc) => pc['@id'] === programChannel)?.key
	},
	isBCE (state, getters, rootState) {
		const { programChannel } = state.me.student
		return rootState.global.programChannelsList
			.find((pc) => pc['@id'] === programChannel)?.key?.includes('bce') || false
	},
	isAST (statet, getters) {
		return !getters.isBCE
	},
	programChannel (state) {
		return state.me.student.programChannel.split('/').at(-1)
	},
	isForbiddenPTV () {
		const { state: studentState } = state.me.student
		return ![STUDENT_STATE.REGISTERED_EO].includes(studentState)
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}

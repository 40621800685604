<template>
	<div class="template">
		<div :class="[
			isConnectedRoute ?  'connected-app':'disconnected-app',
			!isConnectedRoute || flexGapSupported ? '' : 'connected-app-rg'
		]"
		v-if="!isLanding">
			<div class="nav-menu-bloc" v-if="isConnectedRoute">
				<nav-menu
					class="nav-menu desktop-menu"
					:class="flexGapSupported ? '' : 'desktop-menu-rg'"
				/>
			</div>
			<div
				class="content-body"
				:class="flexGapSupported ? '' : 'content-body-rg'">
					<component
						:is="!isConnectedRoute || !isAuthenticated ? 'DisconnectedNavBar' : 'ConnectedNavBar'"
						:class="!isConnectedRoute || !isAuthenticated ? 'disconnected-navbar' : 'connected-navbar'"
					/>
				<router-view class="router-view"/>
			</div>
			<modal-container />
		</div>
		<div v-else >
			<router-view class="router-view"/>
		</div>
		<component
			:is="isConnectedRoute || isLanding ?  'ConnectedFooter':'DisconnectedFooter'"
			:class="[isConnectedRoute ? 'connected-footer': '', { 'footer-admissible': isLanding} ]"
		/>
		<loader/>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import DisconnectedNavBar from '@/components/NavBar/DisconnectedNavBar.vue'
import ConnectedNavBar from '@/components/NavBar/ConnectedNavBar.vue'
import DisconnectedFooter from '@/components/Footer/DisconnectedFooter.vue'
import ConnectedFooter from '@/components/Footer/ConnectedFooter.vue'
import ModalContainer from '@/components/Modals/ModalContainer.vue'
import NavMenu from '@/components/DashboardView/NavMenu.vue'
import Loader from '@/components/Loader/Loader.vue'

export default {
	name: 'App',
	components: {
		DisconnectedNavBar,
		ConnectedNavBar,
		DisconnectedFooter,
		ConnectedFooter,
		ModalContainer,
		NavMenu,
		Loader,
	},
	data () {
		return {}
	},
	computed: {
		...mapState('global', ['flexGapSupported']),
		...mapGetters('auth', ['isAuthenticated']),
		isConnectedRoute () {
			return this.$route.meta.requiresAuth
		},
		isLanding () {
			return this.$route.meta.isLanding
		},
	},
}
</script>

<style>
.template {
	display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
}
.content-body{
	width:100%;
	min-height: 100vh;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 25px;
	padding-bottom: 20px;
}
.content-body-rg > *:not(:last-child)  {
	margin-bottom: 25px;
}
.desktop-menu{
	display: none;
}
.disconnected-app {
	display: flex;
	flex-direction: column;
	margin: 0 auto;
    height: 100%;
	width: 100%;
}
.connected-app {
	background: var(--beige-1);
	max-width: 1200px;
	margin: 0 auto;
	height: 100%;
	display: flex;
	width: 100%;
	align-items: stretch;
}
.disconnected-navbar {
	position: fixed;
	z-index: 1000;
	width: 100%;
	top: 0;
	left: 0;
}
.footer-admissible {
	position: fixed;
	bottom: 0;
}
@media screen and (min-width: 820px) {

	.nav-menu-bloc {
		background: white;
		width: 100%;
		max-width: 280px;
		min-width: 220px;
	}
	.connected-app{
		gap: 25px;
	}
	.connected-app-rg > *:not(:last-child)  {
		margin-right: 25px;
	}
}
</style>

import blocService from '@/services/bloc.service'
import studentService from '@/services/student.service'
import store from '@/store'
import filtersBloc from '@/utils/filtersBloc'
import getAPI from '@/utils/getData'
import utilsParameters from '@/utils/parameters.js'
import postAPI from '@/utils/postData'
import errorMessageUtils from '@/utils/errorMessage.js'

const state = {
	messageWelcome: [],
	messageCandidate: [],
	documents: [],
	calendar: [],
	candidacySteps: undefined,
	popinValidations: [],
	dashboardCampusInformations: [],
	admissibilityPublication: undefined,
	approvedCandidacy: [],
	admittedCardHeader: [],
	admittedCardContent: [],
	cancelRegistration: [],
	affectedAccount1: [],
	affectedHeader1: [],
	affectedCampus1: [],
	payedHeader: [],
	payedChoice2: [],
	payedConfirm1: [],
	payedContent: [],
	payedResignation: [],
	schoolAssignments: [],
	dateCloturePrisePosition: null,
	dateAffectationDefinitive: null,
	dateLimiteRappel: null,
	affectationResults: null,
	affectationResultsError: '',
	registeredSk: null,
	admissionReassignment: null,
	dateResultatsAdmission: undefined,
}

const SET_AFFECTATION_RESULTS = 'SET_AFFECTATION_RESULTS'
const SET_REGISTERED_SK = 'SET_REGISTERED_SK'
const SET_ADMISSION_REASSIGNMENT = 'SET_ADMISSION_REASSIGNMENT'
const SET_AFFECTATION_RESULTS_ERROR = 'SET_AFFECTATION_RESULTS_ERROR'
const SET_DOCUMENTS = 'SET_DOCUMENTS'
const SET_CALENDAR = 'SET_CALENDAR'
const SET_MESSAGE_CANDIDATE = 'SET_MESSAGE_CANDIDATE'
const SET_MESSAGE_WELCOME = 'SET_MESSAGE_WELCOME'
const SET_CANDIDACY_STEPS = 'SET_CANDIDACY_STEPS'
const SET_CANDIDACY_POPIN_VALIDATION = 'SET_CANDIDACY_POPIN_VALIDATION'
const SET_DASHBOARD_CAMPUS_INFORMATIONS = 'SET_DASHBOARD_CAMPUS_INFORMATIONS'
const SET_ADMISSIBILITY_PUBLICATION = 'SET_ADMISSIBILITY_PUBLICATION'
const SET_APPROVED_CANDIDACY = 'SET_APPROVED_CANDIDACY'
const SET_DASHBOARD_ADMITTED_CARD_HEADER = 'SET_DASHBOARD_ADMITTED_CARD_HEADER'
const SET_DASHBOARD_ADMITTED_CARD_CONTENT = 'SET_DASHBOARD_ADMITTED_CARD_CONTENT'
const SET_DASHBOARD_CANCEL_REGISTRATION = 'SET_DASHBOARD_CANCEL_REGISTRATION'
const SET_DASHBOARD_AFFECTED_ACCOUNT1 = 'SET_DASHBOARD_AFFECTED_ACCOUNT1'
const SET_DASHBOARD_AFFECTED_HEADER1 = 'SET_DASHBOARD_AFFECTED_HEADER1'
const SET_DASHBOARD_AFFECTED_CAMPUS1 = 'SET_DASHBOARD_AFFECTED_CAMPUS1'
const SET_DASHBOARD_PAYED_HEADER = 'SET_DASHBOARD_PAYED_HEADER'
const SET_DASHBOARD_PAYED_CHOICE2 = 'SET_DASHBOARD_PAYED_CHOICE2'
const SET_DASHBOARD_PAYED_CONFIRM1 = 'SET_DASHBOARD_PAYED_CONFIRM1'
const SET_DASHBOARD_PAYED_CONTENT = 'SET_DASHBOARD_PAYED_CONTENT'
const SET_DASHBOARD_PAYED_RESIGNATION = 'SET_DASHBOARD_PAYED_RESIGNATION'
const SET_SCHOOL_ASSIGNMENTS = 'SET_SCHOOL_ASSIGNMENTS'
const SET_DATE_CLOTURE_PRISE_POSITION = 'SET_DATE_CLOTURE_PRISE_POSITION'
const SET_DATE_AFFECTATION_DEFINITIVE = 'SET_DATE_AFFECTATION_DEFINITIVE'
const SET_DATE_LIMIT_RAPPEL = 'SET_DATE_LIMIT_RAPPEL'
const SET_DATE_RESULTATS_ADMISSION = 'SET_DATE_RESULTATS_ADMISSION'

const mutations = {
	[SET_DOCUMENTS] (state, payload) {
		state.documents = payload
	},
	[SET_CALENDAR] (state, payload) {
		state.calendar = payload
	},
	[SET_MESSAGE_CANDIDATE] (state, payload) {
		state.messageCandidate = payload
	},
	[SET_MESSAGE_WELCOME] (state, payload) {
		state.messageWelcome = payload
	},
	[SET_CANDIDACY_STEPS] (state, payload) {
		state.candidacySteps = payload
	},
	[SET_CANDIDACY_POPIN_VALIDATION] (state, payload) {
		state.popinValidations = payload
	},
	[SET_DASHBOARD_CAMPUS_INFORMATIONS] (state, payload) {
		state.dashboardCampusInformations = payload
	},
	[SET_ADMISSIBILITY_PUBLICATION] (state, payload) {
		state.admissibilityPublication = payload
	},
	[SET_APPROVED_CANDIDACY] (state, payload) {
		state.approvedCandidacy = payload
	},
	[SET_DASHBOARD_ADMITTED_CARD_HEADER] (state, payload) {
		state.admittedCardHeader = payload
	},
	[SET_DASHBOARD_ADMITTED_CARD_CONTENT] (state, payload) {
		state.admittedCardContent = payload
	},
	[SET_DASHBOARD_CANCEL_REGISTRATION] (state, payload) {
		state.cancelRegistration = payload
	},
	[SET_DASHBOARD_AFFECTED_ACCOUNT1] (state, payload) {
		state.affectedAccount1 = payload
	},
	[SET_DASHBOARD_AFFECTED_HEADER1] (state, payload) {
		state.affectedHeader1 = payload
	},
	[SET_DASHBOARD_AFFECTED_CAMPUS1] (state, payload) {
		state.affectedCampus1 = payload
	},
	[SET_DASHBOARD_PAYED_HEADER] (state, payload) {
		state.payedHeader = payload
	},
	[SET_DASHBOARD_PAYED_CHOICE2] (state, payload) {
		state.payedChoice2 = payload
	},
	[SET_DASHBOARD_PAYED_CONFIRM1] (state, payload) {
		state.payedConfirm1 = payload
	},
	[SET_DASHBOARD_PAYED_CONTENT] (state, payload) {
		state.payedContent = payload
	},
	[SET_DASHBOARD_PAYED_RESIGNATION] (state, payload) {
		state.payedResignation = payload
	},
	[SET_SCHOOL_ASSIGNMENTS] (state, payload) {
		state.schoolAssignments = payload
	},
	[SET_DATE_CLOTURE_PRISE_POSITION] (state, payload) {
		state.dateCloturePrisePosition = payload
	},
	[SET_DATE_AFFECTATION_DEFINITIVE] (state, payload) {
		state.dateAffectationDefinitive = payload
	},
	[SET_DATE_LIMIT_RAPPEL] (state, payload) {
		state.dateLimiteRappel = payload
	},
	[SET_AFFECTATION_RESULTS] (state, payload) {
		state.affectationResults = payload
	},
	[SET_AFFECTATION_RESULTS_ERROR] (state, payload) {
		state.affectationResultsError = payload
	},
	[SET_REGISTERED_SK] (state, payload) {
		state.registeredSk = payload
	},
	[SET_ADMISSION_REASSIGNMENT] (state, payload) {
		state.admissionReassignment = payload
	},
	[SET_DATE_RESULTATS_ADMISSION] (state, payload) {
		state.dateResultatsAdmission = payload
	},
}

const HYDRA_MEMBER = 'hydra:member'

const actions = {
	initDocuments ({ commit, getters }) {
		blocService.getBloc({ label: 'HOME_PGE_JUSTIFICATIFS', programChannels: [{ id: getters.getProgChan }] })
			.then((res) => {
				commit(SET_DOCUMENTS, res.data[HYDRA_MEMBER])
			})
	},
	initCalendar ({ commit, getters }) {
		getAPI.get(`api/events?order[paramStart.valueDateTime]=asc&order[paramEnd.valueDateTime]=asc${getters.getProgChanQueryParam}`)
			.then((res) => commit(SET_CALENDAR, res.data[HYDRA_MEMBER]))
	},
	initParameter ({ commit, getters }) {
		getAPI.get('api/parameters?key.name=dateResultatsAdmissibilite')
			.then((res) => {
				const date = utilsParameters.filterProgramChannel(res.data[HYDRA_MEMBER])
				// TODO: remove this => move in results store ?
				commit('SET_DATE_RESULTATS_ADMISSIBILITE', date.value)
			})
	},
	initDateResultsAdmission ({ commit, getters }) {
		getAPI.get('api/parameters?key.name=dateResultatsAdmission')
			.then((res) => {
				const date = utilsParameters.filterProgramChannel(res.data[HYDRA_MEMBER])
				commit(SET_DATE_RESULTATS_ADMISSION, date.value)
			})
	},
	initAssignmentDates ({ commit, dispatch, getters }) {
		return Promise.all([
			getAPI.get('api/parameters?key.name=dateCloturePrisePosition')
				.then((res) => {
					const date = utilsParameters.filterProgramChannel(res.data[HYDRA_MEMBER])
					commit(SET_DATE_CLOTURE_PRISE_POSITION, new Date(date.value))
				}),
			dispatch('initDateAffectationDefinitive'),
		])
	},
	initDateAffectationDefinitive ({ commit }) {
		return getAPI.get('api/parameters?key.name=dateAffectationDefinitive')
			.then((res) => {
				const date = utilsParameters.filterProgramChannel(res.data[HYDRA_MEMBER])
				commit(SET_DATE_AFFECTATION_DEFINITIVE, new Date(date.value))
			})
	},
	initDateLimiteRappel ({ commit }) {
		return getAPI.get('api/parameters?key.name=dateLimiteRappel')
			.then((res) => {
				const date = utilsParameters.filterProgramChannel(res.data[HYDRA_MEMBER])
				commit(SET_DATE_LIMIT_RAPPEL, new Date(date.value))
			})
	},
	blocWelcomeMessage ({ commit }) {
		blocService.getBloc({ label: 'DASHBOARD_WELCOME_CANDIDACY' })
			.then((res) => {
				commit(SET_MESSAGE_WELCOME, res.data[HYDRA_MEMBER])
			})
	},
	blocCandidateMessage ({ commit }, blocState) {
		blocService.getBloc({ label: blocState, programChannels: [{ id: store.getters['profile/programChannel'] }] })
			.then((res) => {
				commit(SET_MESSAGE_CANDIDATE, res.data[HYDRA_MEMBER])
			})
	},
	blocCampusInfos ({ commit }) {
		blocService.getBloc({ label: 'DASHBOARD_CAMPUS_INFORMATIONS' })
			.then((res) => {
				commit(SET_DASHBOARD_CAMPUS_INFORMATIONS, res.data[HYDRA_MEMBER])
			})
	},
	blocApprovedCandidacy ({ commit }) {
		blocService.getBloc({ label: 'DASHBOARD_APPROVED_CANDIDACY', programChannels: [{ id: store.getters['profile/programChannel'] }] })
			.then((res) => {
				commit(SET_APPROVED_CANDIDACY, res.data[HYDRA_MEMBER])
			})
	},
	admissibilityPublication ({ commit }) {
		return getAPI.getWithToken(`api/students/${store.state.profile.me.student.id}/admissibility_publication`)
			.then((res) => {
				commit(SET_ADMISSIBILITY_PUBLICATION, res.data)
			})
			.catch((err) => {
				commit(SET_ADMISSIBILITY_PUBLICATION, null)
			})
	},
	async candidacySteps ({ commit }) {
		return getAPI.getWithToken(`api/students/${store.state.profile.me.student.id}/candidacy_steps`)
			.then((res) => {
				commit(SET_CANDIDACY_STEPS, res.data)
			})
	},
	blocValidationPopin ({ commit }) {
		blocService.getBloc({ label: 'DASHBOARD_COMPLETE_POPIN' })
			.then((res) => commit(SET_CANDIDACY_POPIN_VALIDATION, res.data[HYDRA_MEMBER]))
	},
	initAdmittedCard ({ commit }) {
		// TODO: Remove mock
		const mockHeader = [{
			label: 'Félicitations',
			content: 'Vous êtes admis à Skema Business School',
			active: true,
		}]
		blocService.getBloc({ label: 'DASHBOARD_ADMITTED_CARD_HEADER' })
		// .then((res) => commit('SET_DASHBOARD_ADMITTED_CARD_HEADER', res.data[HYDRA_MEMBER]))
			.then((res) => commit(SET_DASHBOARD_ADMITTED_CARD_HEADER, mockHeader))

		// TODO: Remove mock
		const mockContent = [{
			label: '',
			content: 'Vous êtes affecté à Skema Lille Confirmez votre affectation en payant un acompte.',
			active: true,
			media: {
				file: 'https://picsum.photos/425/290',
				originalName: 'img.png',
			},
		}]
		blocService.getBloc({ label: 'DASHBOARD_ADMITTED_CARD_CONTENT' })
			// .then((res) => commit('SET_DASHBOARD_ADMITTED_CARD_CONTENT', res.data[HYDRA_MEMBER]))
			.then((res) => commit(SET_DASHBOARD_ADMITTED_CARD_CONTENT, mockContent))
	},
	initCancelRegistration ({ commit }) {
		blocService.getBloc({ label: 'DASHBOARD_CANCEL_REGISTRATION' })
			.then((res) => commit(SET_DASHBOARD_CANCEL_REGISTRATION, res.data[HYDRA_MEMBER]))
	},
	initDashboardAffected ({ commit, dispatch }) {
		const programChannelId = store.state.profile.me.student ? store.getters['profile/programChannel'] : '1'

		blocService.getBloc({ label: 'DASHBOARD_AFFECTED_HEADER1', programChannels: [{ id: programChannelId }] })
			.then((res) => commit(SET_DASHBOARD_AFFECTED_HEADER1, res.data[HYDRA_MEMBER]))

		blocService.getBloc({ label: 'DASHBOARD_AFFECTED_ACCOUNT1', programChannels: [{ id: programChannelId }] })
			.then((res) => commit(SET_DASHBOARD_AFFECTED_ACCOUNT1, res.data[HYDRA_MEMBER]))

		const { id } = store.state.profile.me.student
		studentService.getAffectationResults(id)
			.then((res) => {
				commit(SET_AFFECTATION_RESULTS_ERROR, '')
				commit(SET_AFFECTATION_RESULTS, res.data)
			})
			.catch((err) => {
				const message = errorMessageUtils.getErrorMessage(err)
				commit(SET_AFFECTATION_RESULTS_ERROR, message)
			})

		return Promise.all([
			blocService.getBloc({ label: 'DASHBOARD_AFFECTED_CAMPUS1' })
				.then((res) => commit(SET_DASHBOARD_AFFECTED_CAMPUS1, res.data[HYDRA_MEMBER])),
			dispatch('initDateAffectationDefinitive'),
			dispatch('initDateLimiteRappel'),
		])
	},
	initPayedCard ({ commit }) {
		return new Promise((resolve, reject) => {
			blocService.getBloc({ label: 'DASHBOARD_PAYED_HEADER' })
				.then((res) => commit(SET_DASHBOARD_PAYED_HEADER, res.data[HYDRA_MEMBER]))

			blocService.getBloc({ label: 'DASHBOARD_PAYED_CONTENT' })
				.then((res) => commit(SET_DASHBOARD_PAYED_CONTENT, res.data[HYDRA_MEMBER]))

			blocService.getBloc({ label: 'DASHBOARD_PAYED_RESIGNATION' })
				.then((res) => commit(SET_DASHBOARD_PAYED_RESIGNATION, res.data[HYDRA_MEMBER]))

			resolve()
		})
	},
	initAffectedSecondChoice ({ commit }) {
		blocService.getBloc({ label: 'DASHBOARD_PAYED_CHOICE2' })
			.then((res) => commit(SET_DASHBOARD_PAYED_CHOICE2, res.data[HYDRA_MEMBER]))
		blocService.getBloc({ label: 'DASHBOARD_PAYED_CONFIRM1' })
			.then((res) => commit(SET_DASHBOARD_PAYED_CONFIRM1, res.data[HYDRA_MEMBER]))
	},
	validSchoolAssignments ({ commit }, payload) {
		const { id } = store.state.profile.me.student
		const studentSchoolAssignments = payload.map((item, index) => {
			return {
				campus: store.getters['contact/filteredCampuses'][item]['@id'],
				priority: index + 1,
			}
		})

		return postAPI.postWithToken(`api/students/${id}/student_school_assignments`, { studentSchoolAssignments })
			.then((res) => {
				const resSchoolAssignments = res.data.studentSchoolAssignments
				commit(SET_SCHOOL_ASSIGNMENTS, resSchoolAssignments)
			})
			.catch((err) => {
				const message = errorMessageUtils.getErrorMessage(err)
				store.commit('modal/SHOW_MODAL', {
					name: 'ModalError',
					props: {
						message,
					},
				})
			})
	},
	getSchoolAssignments ({ commit }) {
		const { id } = store.state.profile.me.student
		return getAPI.getWithToken(`/api/students/${id}/student_school_assignments`)
			.then((res) => commit(SET_SCHOOL_ASSIGNMENTS, res.data[HYDRA_MEMBER]))
	},
	initRegisteredSk ({ commit }) {
		const { id } = store.state.profile.me.student
		return studentService.getRegisteredSk(id)
			.then((res) => commit(SET_REGISTERED_SK, res.data))
	},
	initAdmissionReassignment ({ commit }) {
		blocService.getBloc({ label: 'ADMISSION_REASSIGNMENT' })
			.then((res) => commit(SET_ADMISSION_REASSIGNMENT, res.data[HYDRA_MEMBER]))
	},
	reassignment ({ commit }, payload)	{
		const { id } = store.state.profile.me.student
		return postAPI.postWithToken(`/api/students/${id}/reassignment`, { reassignment: payload })
	},
}

const getters = {
	getProgChan (state, getters, rootState) {
		return store.state.profile.me.student.programChannel.slice(-1)
	},
	getProgChanQueryParam () {
		const getCurrentId = store.state.profile.me.student.programChannel.slice(-1)
		if (getCurrentId !== undefined) {
			return `&programChannels.id=${getCurrentId}`
		}
		return ''
	},
	filteredDocuments () {
		return filtersBloc.filterActive(state.documents)
	},
	filteredMessageCandidate () {
		return filtersBloc.filterActive(state.messageCandidate)
	},
	filteredMessageWelcome () {
		return filtersBloc.filterActive(state.messageWelcome)
	},
	filteredPopinValidations () {
		return filtersBloc.filterActive(state.popinValidations)
	},
	filteredDashboardCampusInformations () {
		return filtersBloc.filterActive(state.dashboardCampusInformations)
	},
	filteredApprovedCandidacy () {
		return filtersBloc.filterActive(state.approvedCandidacy)
	},
	filteredAdmittedCardHeader () {
		return filtersBloc.filterActive(state.admittedCardHeader)
	},
	filteredAdmittedCardContent () {
		return filtersBloc.filterActive(state.admittedCardContent)
	},
	filteredCancelRegistration () {
		return filtersBloc.filterActive(state.cancelRegistration)
	},
	filteredAffectedAccount1 () {
		return filtersBloc.filterActive(state.affectedAccount1)
	},
	filteredAffectedHeader1 () {
		return filtersBloc.filterActive(state.affectedHeader1)
	},
	filteredAffectedCampus1 () {
		return filtersBloc.filterActive(state.affectedCampus1)
	},
	filteredPayedHeader () {
		return filtersBloc.filterActive(state.payedHeader)
	},
	filteredPayedChoice2 () {
		return filtersBloc.filterActive(state.payedChoice2)
	},
	filteredPayedConfirm1 () {
		return filtersBloc.filterActive(state.payedConfirm1)
	},
	filteredPayedContent () {
		return filtersBloc.filterActive(state.payedContent)
	},
	filteredPayedResignation () {
		return filtersBloc.filterActive(state.payedResignation)
	},
	filteredAdmissionReassignment () {
		return filtersBloc.filterActive(state.admissionReassignment)
	},
	schoolAssignmentsIds () {
		return state.schoolAssignments.map((sa) => sa.campus)
	},
	isSchoolAssignmentsEditModeEnabled () {
		const { dateCloturePrisePosition } = state
		const currentDate = new Date()
		return (currentDate <= dateCloturePrisePosition)
	},
	isBetweenAffectationDefinitiveAndLimitRappel () {
		const now = new Date()
		return now >= state.dateAffectationDefinitive && now <= state.dateLimiteRappel
	},
	registeredSkBlocPayedHeader () {
		const blocs = state.registeredSk?.blocs.filter((b) => b.tag === 'DASHBOARD_PAYED_HEADER') || null
		return blocs
	},
	registeredSkPayedContent () {
		const blocs = state.registeredSk?.blocs.filter((b) => b.tag === 'DASHBOARD_PAYED_CONTENT') || null
		return blocs
	},
	registeredSkPayedResignation () {
		const blocs = state.registeredSk?.blocs.filter((b) => b.tag === 'DASHBOARD_PAYED_RESIGNATION') || null
		return blocs
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}

<template>
	<div class="form">
		<div  class="title">
			<h2>Mot de passe oublié</h2>
		</div>
		<input-text
			class="inputs"
			:placeholder="'E-mail'"
			v-model="email"
			:regexValidation="emailRegex"
			data-beaver-id="inputEmailReset"
		/>
		<div :class="isError ? 'msg-error' : 'message'" > {{ message }}</div>
        <button class="white-button"
		@click="resetPassword()"
		data-beaver-id="btnResetPassword"
		>
			Réinitialiser
		</button>
		<div
			class="icon"
			@click="closeModal"
			data-beaver-id="iconCloseMdp"
		>
			<CloseIcon />
		</div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import CloseIcon from '@/assets/icons/CloseIcon.vue'
import InputText from '@/components/Forms/InputText.vue'
import { REGEX_EMAIL, REGEX_PASSWORD } from '@/utils/regexDictionnary.js'

export default {
	name: 'ModalPassword',
	data () {
		return {
			email: '',
			message: '',
			isError: false,
			emailRegex: REGEX_EMAIL,
			passwordRegex: REGEX_PASSWORD,
		}
	},
	components: {
		CloseIcon,
		InputText,
	},
	methods: {
		...mapActions('password', ['resetRequest']),
		closeModal () {
			this.$parent.hideModal()
		},

		resetPassword () {
			this.resetRequest(this.email)
				.then(() => {
					this.message = 'Si l’adresse e-mail renseignée est connue, un email de réinitialisation de mot de passe vous a été envoyé'
				})
				.catch(() => {
					this.isError = true
					this.message = 'La demande de réinitialisation a rencontré une erreur technique, merci de réessayer'
				})
		},
	},
}
</script>

<style scoped>
.form {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	height: 266px;
	background: white;
	padding: 33px;
    border-radius: var(--radius-1);
    box-shadow: var(--shadow-card);
	box-sizing: border-box;
	position: relative;
}
.title {
	width: 100%;
    display: flex;
    justify-content: space-between;
}
.icon {
	position: absolute;
	top: 33px;
	right: 33px;
	cursor: pointer;
}
.icon svg{
	height: 18px;
	width: 18px;
}
.inputs {
	width: 100%;
}
.message {
	font-size: 16px;
	font-family: "Raleway", sans-serif;
	color: rgba(0,0,0,.6);
	width:505px;
}
.msg-error{
	color : red;
	font-size: 16px;
	font-family: "Raleway", sans-serif;
}

@media screen and (min-width: 820px) {
	.form {
		width: 560px;
	}
}
</style>

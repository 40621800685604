import postAPI from '@/utils/postData'
import blocService from '@/services/bloc.service'
import { HYDRA_MEMBER } from '@/utils/api'

const state = {
	resetRequest: [],
	resetPassword: [],
	modifyPassword: [],
	errorReinitPassword: [],
}

const SET_ERROR_REINIT_PASSWORD = 'SET_ERROR_REINIT_PASSWORD'

const mutations = {
	[SET_ERROR_REINIT_PASSWORD] (state, payload) {
		state.errorReinitPassword = payload
	},
}

const actions = {
	resetRequest ({ commit }, email) {
		return postAPI.post('api/reset-password/request', { email })
	},
	resetPassword ({ commit }, payload) {
		if (payload.type) {
			return postAPI.post(`api/reset-password/reset/${payload.type}`, payload)
		}
		return postAPI.post('api/reset-password/reset', payload)
	},
	modifyPassword (PasswordConfirm) {
		postAPI.post('api/reset-password/modify', { PasswordConfirm })
	},
	initErrorReinitPassword ({ commit }) {
		blocService.getBloc({ label: 'ERROR_REINIT_PASSWORD' })
			.then((res) => {
				commit(SET_ERROR_REINIT_PASSWORD, res.data[HYDRA_MEMBER])
			})
	},
}

const getters = {
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}

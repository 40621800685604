<template>
	<div
		class="loader"
		v-if="isLoading"
	>
		<div class="spinner"></div>
		<div class="spinner"></div>
		<div class="spinner"></div>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'Loader',
	computed: {
		...mapState('global', ['isLoading']),
	},

}
</script>

<style scoped>
.loader {
	background-color: rgba(0, 0, 0, 0.3);
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.spinner {
    width: 30px;
    height: 30px;
    border: 6px solid var(--red-1);
    border-radius: 6px;
    animation: 1.5s linear infinite spinner;
    animation-play-state: inherit;

}

@keyframes spinner {
    0% {
        transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }
    100% {
        transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
}
</style>

<template>
	<div class="card">
		<div
			v-for="(bloc, index) in filteredPopinValidations"
			:key="index"
		>
			<h2>{{bloc.label}}</h2>
			<div
				class="content-cv-validation"
				v-html="bloc.content"
			>

			</div>
		</div>
		<div
			class="actions"
			:class="flexGapSupported ? '' : 'actions-rg'">
			<button
				class="red-button"
				@click="cancel"
				data-beaver-id="btnCancel"
			>
				Annuler
			</button>

			<button
				class="white-button"
				@click="valid"
				data-beaver-id="btnValid"
			>
				Valider
			</button>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
	name: 'ModalValidCv',
	props: ['props'],
	computed: {
		...mapState('global', ['flexGapSupported']),
		...mapGetters('cv', ['filteredPopinValidations']),
	},
	methods: {
		cancel () {
			this.$parent.hideModal()
			this.$emit('cancel')
		},
		valid () {
			this.$props.props.valid()
			this.$emit('valid')
		},
	},

}
</script>

<style scoped>
.card {
	position: relative;
    min-width: 590px;
    max-width: 590px;
    padding: 40px;
}

.actions {
    display: flex;
    justify-content: center;
    gap: 16px;
}
.actions-rg > *:not(:last-child)  {
	margin-right: 16px;
}

.content-cv-validation {
	padding: 16px;
	line-height: 1.5em;
}
</style>

<style>
.content-cv-validation > ul{
	padding-left: 30px;
}
</style>

export default {
	formatActivityOuput (activityOutput) {
		let activityLogsOutput = {}

		Object.keys(activityOutput).forEach((key) => {
			if (!key.startsWith('@')) {
				activityLogsOutput = {
					...activityLogsOutput,
					[key]: activityOutput[key],
				}
			}
		})

		return activityLogsOutput
	},
}

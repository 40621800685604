import axios from '../utils/axios.js'

const baseDomain = process.env.VUE_APP_API_URL
const BASE_URL = `${baseDomain}api/authentication_token`

export default {
	switchUserConnection ({ candidateEmail, email, password }) {
		const headerConfig = {
			'Content-type': 'application/json',
			'X-Switch-User': candidateEmail,
		}
		return axios.post(`${BASE_URL}`, { email, password }, { headers: headerConfig })
	},
}

import decode from 'jwt-decode'

export default {

	getTokenExpirationDate (encodedToken) {
		const token = decode(encodedToken)
		if (!token.exp) {
			return null
		}

		const date = new Date(0)
		date.setUTCSeconds(token.exp)

		return date
	},

	isTokenExpired (token) {
		const expirationDate = this.getTokenExpirationDate(token)
		return !!expirationDate && expirationDate < new Date()
	},
}

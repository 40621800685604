import router from '@/router'
import getAPI from '@/utils/getData'
import errorMessageUtils from '@/utils/errorMessage'

const SET_LANDING_ADMISSIBILITE = 'SET_LANDING_ADMISSIBILITE'
const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE'

const state = {
	landingAdmissibilite: null,
	errorMessage: '',

}

const mutations = {
	[SET_LANDING_ADMISSIBILITE] (state, payload) {
		state.landingAdmissibilite = payload
	},
	[SET_ERROR_MESSAGE] (state, payload) {
		state.errorMessage = payload
	},
}

const actions = {
	initLandingAdmissibilite ({ commit }) {
		const { token } = router.history.current.query
		if (!token) {
			commit(SET_ERROR_MESSAGE, 'aucun token')
			return
		}
		getAPI.get(`/api/students/landing_admissibility_publication?token=${token}`)
			.then((res) => {
				commit(SET_ERROR_MESSAGE, '')
				commit(SET_LANDING_ADMISSIBILITE, res.data)
			})
			.catch((err) => {
				if (err.response.status === 400) {
					commit(SET_ERROR_MESSAGE, errorMessageUtils.getErrorMessage(err))
				}
			})
	},
}

const getters = {

}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}

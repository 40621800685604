<template>
	<div
		class="container"
		:class="flexGapSupported ? '' : 'container-rg'">
		<div v-for="(text, index) in filteredReasonsTitle" :key="`${text.label}${index}`" class="title">
			<h2>{{ text.label }}</h2>
			<div v-html="text.content" />
		</div>
		<div
			class="cards-container"
			:class="flexGapSupported ? '' : 'cards-container-rg'">
			<div
				v-for="(card, index) in filteredReasonsCards"
				:key="`${card.label}${index}`"
				class="card"
				:class="flexGapSupported ? '' : 'card-rg'">
				<img v-if="card.media" :src="card.media.file" :alt="card.label" />
				<div v-html="card.content" />
			</div>
		</div>
		<a
			class="red-button"
			:href="detail.link"
			target="_blank"
			rel="noopener noreferrer"
			v-for="(detail,index) in filteredReasonsDetail"
			:key="`detail-${index}`"
			data-beaver-id="btnDiscoverReasons"
		>
			<p>{{detail.label}}</p>
		</a>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
	name: 'Reasons',
	data () {
		return {}
	},
	computed: {
		...mapState('global', ['flexGapSupported']),
		...mapGetters('home', [
			'filteredReasonsTitle',
			'filteredReasonsCards',
			'filteredReasonsDetail',
		]),
	},
}
</script>

<style scoped>
.container {
	padding: 60px 0px;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	gap: 35px;
	background: white;
}
.container-rg > *:not(:last-child)  {
	margin-bottom: 35px;
}
.title {
	line-height: 30px;
}
.cards-container {
	display: flex;
	flex-wrap: wrap;
	gap: 25px;
	justify-content: center;
}
.cards-container-rg > *:not(:last-child)  {
	margin-bottom: 25px;
	margin-right: 25px
}
.card {
	background: var(--beige-1);
	border-radius: var(--radius-1);
	box-shadow: var(--shadow-card);
	padding: 24px 42px;
	width: 380px;
	box-sizing: border-box;
	font-family: "Raleway", sans-serif;
	font-size: var(--text-size-1);
	font-weight: 500;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 25px;
}
.card-rg > *:not(:last-child)  {
	margin-bottom: 25px;
}
.card img {
	width: 60px;
}
a {
	padding: 13px 42px;
	border: 1px solid var(--red-1);
}
a p {
	color: var(--red-1);
}
@media screen and (min-width: 820px) {
	.container {
		padding: 60px var(--padding-r-l-1);
	}
}
</style>

import getAPI from '@/utils/getData'

const BASE_URL = 'api/blocs'
export default {
	getBloc ({
		label, programChannels, key, campus,
	}) {
		let url = `${BASE_URL}?tag.label=${label}&order[position]=asc`
		if (programChannels) {
			url += `&${programChannels.map((pc) => `programChannels[]=${pc.id}`).join('&')}`
		}
		if (campus) {
			url += `&campus=${campus}`
		}
		if (key) {
			url += `&key=${key}`
		}
		return getAPI.get(url)
	},

	getBlocByKeyAndCampus ({ key, campus }) {
		const url = `${BASE_URL}?key=${key}&campus=${campus}&order[position]=asc`
		return getAPI.get(url)
	},

	getBlocByKey ({ key }) {
		const url = `${BASE_URL}?key=${key}&order[position]=asc`
		return getAPI.get(url)
	},
}

import getAPI from '@/utils/getData'
import postAPI from '@/utils/postData'
import store from '@/store'
import filtersBloc from '@/utils/filtersBloc'
import blocService from '@/services/bloc.service'
import paymentService from '@/services/payment.services'
import { HYDRA_MEMBER } from '@/utils/api'

const state = {
	msgPaymentInProgress: [],
	schoolOrder: null,
	examOrders: [],
	depositRegistrationFeesOrders: [],
	paymentModalTimeout: 3,
	waitingMessage: '',
	paymentStatus: '',
}

const PAYMENT_TYPE = {
	registration_fee_for_exam_session: { name: 'registration_fee_for_exam_session', label: 'Epreuves écrites' },
	school_registration_fees: { name: 'school_registration_fees', label: 'Frais de concours' },
	deposit_registration_fees: { name: 'deposit_registration_fees', label: 'Acompte' },
}

const PAYMENT_STATE = {
	created: 'Régler',
	in_progress: 'En traitement',
	validated: 'Payé',
	rejected: 'Rejeté',
	canceled: 'Annulé',
}

const HISTO_PAYMENT_STATE = {
	created: 'A régler',
	in_progress: 'En traitement',
	validated: 'Payé',
	rejected: 'Rejeté',
	canceled: 'Annulé',
}

const PAYMENT_MODE = {
	online: 'CB',
	check: 'Chèque',
	cash: 'Espèce',
	bank_transfert: 'Virement',
	other: 'Autre',
}
const SET_MSG_PAYMENT_IN_PROGRESS = 'SET_MSG_PAYMENT_IN_PROGRESS'
const SET_PAYMENT_STATUS = 'SET_PAYMENT_STATUS'
const SET_SCHOOL_ORDER = 'SET_SCHOOL_ORDER'
const SET_EXAM_ORDERS = 'SET_EXAM_ORDERS'
const SET_DEPOSIT_REGISTRATION_FEES_ORDERS = 'SET_DEPOSIT_REGISTRATION_FEES'
const SET_MODAL_TIMEOUT = 'SET_MODAL_TIMEOUT'
const SET_WAITING_MESSAGE = 'SET_WAITING_MESSAGE'

const mutations = {
	[SET_MSG_PAYMENT_IN_PROGRESS] (state, payload) {
		state.msgPaymentInProgress = payload
	},
	[SET_PAYMENT_STATUS] (state, payload) {
		state.paymentStatus = payload
	},
	[SET_SCHOOL_ORDER] (state, payload) {
		state.schoolOrder = payload.at(0) || null
	},
	[SET_EXAM_ORDERS] (state, payload) {
		state.examOrders = payload
	},
	[SET_MODAL_TIMEOUT] (state, payload) {
		state.paymentModalTimeout = payload
	},
	[SET_WAITING_MESSAGE] (state, payload) {
		state.waitingMessage = payload
	},
	[SET_DEPOSIT_REGISTRATION_FEES_ORDERS] (state, payload) {
		state.depositRegistrationFeesOrders = payload
	},
}

const actions = {
	initPaymentInProgress ({ commit }) {
		return blocService.getBloc({ label: 'PAYMENT', key: 'MSG_PAYMENT_IN_PROGRESS' })
			.then((res) => commit(SET_MSG_PAYMENT_IN_PROGRESS, res.data[HYDRA_MEMBER]))
	},
	initWaitingPayment ({ commit }) {
		return new Promise((resolve, reject) => {
			Promise.all([
				blocService.getBloc({ label: 'PAYMENT', key: 'MSG_PAYMENT_WAITNG' })
					.then((res) => commit(SET_WAITING_MESSAGE, res.data[HYDRA_MEMBER])),
				getAPI.get('api/parameters?key.name=paymentModalTimeout')
					.then((res) => {
						const delay = res.data[HYDRA_MEMBER][0] ? res.data[HYDRA_MEMBER][0].value * 1000 : 3000
						commit(SET_MODAL_TIMEOUT, delay)
					}),
			])
				.then(() => resolve('ok'))
				.catch((err) => reject(err))
		})
	},
	getSchoolOrder ({ commit }) {
		const studentId = store.state.profile.me.student['@id']
		return getAPI.getWithToken(`api/orders?type=${PAYMENT_TYPE.school_registration_fees.name}`)
			.then((res) => commit(SET_SCHOOL_ORDER, res.data[HYDRA_MEMBER]))
	},
	getExamOrders ({ commit }) {
		const studentId = store.state.profile.me.student['@id']
		return getAPI.getWithToken(`api/orders?type=${PAYMENT_TYPE.registration_fee_for_exam_session.name}`)
			.then((res) => commit(SET_EXAM_ORDERS, res.data[HYDRA_MEMBER]))
	},
	getDepositRegistrationFees ({ commit }) {
		return getAPI.getWithToken(`api/orders?type=${PAYMENT_TYPE.deposit_registration_fees.name}`)
			.then((res) => commit(SET_DEPOSIT_REGISTRATION_FEES_ORDERS, res.data[HYDRA_MEMBER]))
	},
	paymentRequest ({ commit }, { examSession, paymentType, redirectUrl }) {
		let payload = {
			type: paymentType || PAYMENT_TYPE.registration_fee_for_exam_session.name,
			redirectUrl: redirectUrl || `${window.location}?paymentInProgress=true`,
		}
		if (paymentType === undefined) {
			payload = {
				type: paymentType || PAYMENT_TYPE.registration_fee_for_exam_session.name,
				redirectUrl: redirectUrl || `${window.location}?paymentInProgress=true`,
				examSession: examSession['@id'],
			}
		}
		return postAPI.postPayment('api/orders/payment/request', payload)
	},
	getPaymentStatus ({ commit }, { paymentId }) {
		return paymentService.getPaymentStatus(paymentId)
	},
}

const getters = {
	filteredMsgPaymentInProgress () {
		return filtersBloc.filterActive(state.msgPaymentInProgress)
	},
	filteredWaitingPayment () {
		return filtersBloc.filterActive(state.waitingMessage)
	},
	examOrder () {
		return (examSession) => state.examOrders.find((order) => order.examSession['@id'] === examSession && (order.state !== 'abandoned' && order.state !== 'to_be_refunded'))
	},
	paymentById () {
		return (paymentId) => state.examOrders
			.map((order) => order.payments.find((p) => p.externalPaymentID.includes(paymentId)))
			.filter((p) => !!p)[0]
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
	PAYMENT_TYPE,
	PAYMENT_STATE,
	HISTO_PAYMENT_STATE,
	PAYMENT_MODE,
}

import filtersBloc from '@/utils/filtersBloc'
import blocService from '@/services/bloc.service'
import { HYDRA_MEMBER } from '@/utils/api'

const state = {
	connected: {},
	disconnected: {
		networks: [],
	},
}

const SET_NETWORKS = 'SET_NETWORK'

const mutations = {
	[SET_NETWORKS] (state, payload) {
		state.disconnected.networks = payload
	},
}

const actions = {
	initDisconnectedNavBar ({ commit }) {
		if (state.disconnected.networks.length > 0) {
			return Promise.resolve(state.disconnected.networks)
		}

		return blocService.getBloc({ label: 'HP_NAV_SOCIAL' })
			.then((res) => {
				commit(SET_NETWORKS, res.data[HYDRA_MEMBER])
			})
	},
}

const getters = {
	filteredNetworks () {
		return filtersBloc.filterActive(state.disconnected.networks)
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}

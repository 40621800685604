<template>
	<div class="resignation__modal card">
		<div
			class="resignation__modal__content"
			v-html="props.verbatim.content"
		></div>
		<div v-if="props.reason" class="reason">
			<label>Veuillez saisir la raison de votre annulation : </label>
			<input-text
				ref="raisonInput"
				:placeholder="'Raison'"
				data-beaver-id="inputRaison"
				v-model="formData.reason"
				class="resignation__modal__input"
			/>
		</div>
		<hr class="separator" />
		<div
			class="actions"
			:class="flexGapSupported ? '' : 'actions-rg'">
			<button
				class="red-button"
				@click="$parent.hideModal()"
			>Non</button>
			<button
				class="white-button"
				@click="yesAction"
			>Oui</button>
		</div>
	</div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import store from '@/store'
import InputText from '../Forms/InputText.vue'

export default {
	components: { InputText },
	name: 'ModalResignation',
	props: ['props'],
	data () {
		return {
			formData: {
				reason: '',
			},
		}
	},
	methods: {
		...mapActions('auth', ['disconnect']),
		...mapMutations('modal', {
			showModal: 'SHOW_MODAL',
			hideModal: 'HIDE_MODAL',
		}),
		yesAction () {
			if (this.$props.props.reason) {
				if (this.formData.reason && this.formData.reason !== '') {
					this.$parent.hideModal()
					store.dispatch('profile/cancelRegistration', { reason: this.formData.reason })
						.then((res) => {
							this.resigned(res)
						})
				} else {
					this.$refs.raisonInput.externalError('Veuillez saisir la raison de votre démission')
				}
			} else {
				this.$parent.hideModal()
				store.dispatch('profile/resignation')
					.then((res) => {
						this.resigned(res)
					})
			}
		},
		resigned (res) {
			this.showModal({ name: 'ModalInfos', props: { message: res.data.message, showDashboardButton: false, loader: false } })
			setTimeout(() => {
				this.hideModal()
				this.disconnect()
			}, 5000)
		},
	},
	computed: {
		...mapState('global', ['flexGapSupported']),
	},
}
</script>

<style scoped>
.resignation__modal {
    width: 590px;
    padding: 32px;
}
.resignation__modal__input {
	margin: 10px 0 30px;
	width: 100%;
}
.actions {
    display: flex;
    justify-content: center;
    gap: 20px;
}
.actions-rg > *:not(:last-child)  {
	margin-right: 20px;
}
.reason {
	margin-top: 20px;
}
</style>

<template>
	<div
		v-if="filteredNotifsNotSentByCandidat.length !== 0 && filteredNotifsNotSentByCandidat[0].read === false"
		class="notif-message" @click="changeStatus(filteredNotifsNotSentByCandidat[0]['@id'])"
	>
	<div class="line1">
		<div class="date" >{{filteredNotifsNotSentByCandidat[0].createdAt | toLocaleDateString2}}</div>
		<button v-if="isNewNotif" class="circle"></button>
	</div>
	<div class="content" v-html="filteredNotifsNotSentByCandidat[0].content" />
  </div>
  <div v-else >Pas de nouvelles notifications</div>

</template>

<script>

import { mapState, mapGetters } from 'vuex'
import utilsDate from '@/utils/dates'
import store from '@/store'

export default {
	name: 'NotifMessage',
	data () {
		return {
		}
	},
	methods: {
		changeStatus (id) {
			const notifId = id.replace('/api/notifications/', '')
			store.dispatch('notifications/readNotification', notifId)
			clearTimeout(this.timeout)
			this.timeout = setTimeout(() => {
				store.dispatch('notifications/initNotification')
			}, 1000)
		},
	},
	filters: {
		toLocaleDateString2: (value) => utilsDate.toLocaleDateString2(value),
	},
	computed: {
		...mapState('profile', ['me']),
		...mapGetters('notifications', ['filteredNotifsNotSentByCandidat']),
		isNotRead () {
			return this.filteredNotifsNotSentByCandidat[0].read === false
		},
		isNotMe () {
			return this.filteredNotifsNotSentByCandidat[0].sender['@id'] !== this.me['@id']
		},
		isNewNotif () {
			return this.isNotRead && this.filteredNotifsNotSentByCandidat[0].sender && this.isNotMe
		},
	},
}
</script>

<style scoped>
.notif-message{
	display: flex;
	flex-direction: column;
	text-align: start;
	gap: 18px;
	cursor: pointer;

}
.line1{
	display: flex;
    gap: 54px;
}
.date {
	color: var(--grey-2)
}
.circle{
	width: 12px;
	height: 12px;
	background: var(--red-1);
	border-radius: 50%;
	cursor: pointer;

}
.notif-message  p {
	font-size: 13px;
	font-weight: 400;
}
.content{
  text-overflow: ellipsis;
	overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
</style>

const state = {
	isModalVisible: false,
	modalName: '',
	props: null,
}

const SHOW_MODAL = 'SHOW_MODAL'
const HIDE_MODAL = 'HIDE_MODAL'

const mutations = {
	[SHOW_MODAL] (state, payload) {
		state.isModalVisible = true
		state.modalName = payload.name
		state.props = payload.props
	},
	[HIDE_MODAL] (state) {
		state.isModalVisible = false
		state.modalName = ''
		state.props = null
	},
}

const actions = {
}

const getters = {

}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}

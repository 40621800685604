import getAPI from '@/utils/getData'
import filtersBloc from '@/utils/filtersBloc'
import store from '@/store'
import supportedStyle from '@/utils/supportedStyle.js'
import { HYDRA_MEMBER } from '@/utils/api'

const state = {
	route: null,
	programChannelsList: [],
	countries: [],
	diplomas: [],
	establishments: [],
	isLoading: false,
	flexGapSupported: undefined,
	lastRoute: localStorage.getItem('LAST_ROUTE'),
}

const SET_ROUTE = 'SET_ROUTE'
const SET_LAST_ROUTE = 'SET_LAST_ROUTE'
const SET_PROGRAM_CHANNELS_LIST = 'SET_PROGRAM_CHANNELS_LIST'
const SET_COUNTRIES = 'SET_COUNTRIES'
const SET_DIPLOMAS = 'SET_DIPLOMAS'
const SET_ESTABLISHMENTS = 'SET_ESTABLISHMENTS'
const SET_LOADER = 'SET_LOADER'
const SET_FLEX_GAP_SUPPORTED = 'SET_FLEX_GAP_SUPPORTED'

const mutations = {
	[SET_ROUTE] (state, payload) {
		state.route = payload
	},
	[SET_LAST_ROUTE] (state, payload) {
		state.lastRoute = payload
		localStorage.setItem('LAST_ROUTE', payload)
	},
	[SET_PROGRAM_CHANNELS_LIST] (state, payload) {
		state.programChannelsList = payload
	},
	[SET_COUNTRIES] (state, payload) {
		state.countries = payload
	},
	[SET_DIPLOMAS] (state, payload) {
		state.diplomas = payload
	},
	[SET_ESTABLISHMENTS] (state, payload) {
		state.establishments = payload
	},
	[SET_LOADER] (state, payload) {
		state.isLoading = payload
	},
	[SET_FLEX_GAP_SUPPORTED] (state, payload) {
		state.flexGapSupported = payload
	},
}

const actions = {
	initFlexGapSupported ({ state, commit }) {
		if (!state.flexGapSupported) {
			commit(SET_FLEX_GAP_SUPPORTED, supportedStyle.checkFlexGap())
		}
	},
	initProgramChannels ({ state, commit, getters }) {
		if (state.programChannelsList && state.programChannelsList.length > 0) {
			return Promise.resolve(state.programChannelsList)
		}

		return getAPI.get('api/program_channels')
			.then((res) => {
				commit(SET_PROGRAM_CHANNELS_LIST, res.data[HYDRA_MEMBER])
			})
	},
	initCountries ({ commit }) {
		if (state.countries.length > 0) {
			return Promise.resolve(state.countries)
		}
		return getAPI.get('/api/countries?active=true&order%5Bname%5D=asc')
			.then((res) => {
				commit(SET_COUNTRIES, res.data[HYDRA_MEMBER])
			})
	},
	initDiplomas ({ commit }) {
		return getAPI.get('api/diplomas')
			.then((res) => {
				commit(SET_DIPLOMAS, res.data[HYDRA_MEMBER])
			})
	},
	initEstablishments ({ commit }) {
		return getAPI.get('api/establishments')
			.then((res) => {
				commit(SET_ESTABLISHMENTS, res.data[HYDRA_MEMBER])
			})
	},
}

const getters = {
	filteredCountries () {
		return filtersBloc.filterActive(state.countries).map((a) => a.name)
	},
	filteredNationalities () {
		return filtersBloc.filterActive(state.countries).map((a) => a.nationality)
	},
	filteredProgramChannel () {
		return state.programChannelsList.map((pc) => ({ label: pc.name, value: pc['@id'] }))
	},
	filteredProgramChannelAST () {
		return state.programChannelsList
			.filter((pc) => pc.key.slice(0, 3) === 'ast')
			.map((pc) => ({ label: pc.name, value: pc['@id'] }))
	},
	filteredDiplomas () {
		return (programChannel, dualPath) => {
			let diplomas = state.diplomas.filter((d) => d.programChannels.includes(programChannel))
			diplomas = dualPath ? diplomas : diplomas.filter((d) => d.additional !== true)
			const el = 'Autre diplôme'
			const idx = diplomas.findIndex((d) => d.name.toUpperCase() === el.toUpperCase())
			if (idx > -1) {
				const autreDiplome = diplomas.splice(idx, 1)
				diplomas.push(autreDiplome[0])
			}
			return diplomas
		}
	},
	countryIndexById () {
		return (id) => filtersBloc.filterActive(state.countries).map((c) => c['@id']).indexOf(id)
	},
	diplomasData () {
		return state.diplomas.map((d) => ({ label: d.name, value: d['@id'], diplomaChannels: d.diplomaChannels }))
	},
	countryNameById () {
		return filtersBloc.filterActive(state.countries)
	},
	countryByIndex () {
		return (index) => filtersBloc.filterActive(state.countries)[index]
	},
	diplomaIndexById () {
		return (id, dualPath) => {
			const { programChannel } = store.state.profile.me.student
			const diplomas = store.getters['global/filteredDiplomas'](programChannel, dualPath)
			return diplomas.findIndex((d) => d['@id'] === id)
		}
	},
	diplomaChannelIndexById () {
		return (diplomaId, diplomaChannelId, dualPath) => {
			const { programChannel } = store.state.profile.me.student
			const diplomas = store.getters['global/filteredDiplomas'](programChannel, dualPath)
			return diplomas.find((d) => d['@id'] === diplomaId).diplomaChannels.findIndex((dc) => dc['@id'] === diplomaChannelId)
		}
	},
	getProgChanNameParam (state, getters, rootState) {
		const myFilteredArr = state.programChannelsList.filter((el) => {
			if (state.route.name === 'ast' && (el.key === 'ast2' || el.key === 'ast1')) {
				return false
			}
			return el.key === state.route.name
		})
		return myFilteredArr.length === 0 ? 'AST' : myFilteredArr[0].name
	},
	programChannelByIri (state) {
		return (iri) => state.programChannelsList.find((pc) => pc['@id'] === iri)
	},
	BCEProgramChannels (state) {
		return state.programChannelsList.find((pc) => pc.key.includes('bce'))
	},
	AST1 (state) {
		return state.programChannelsList.find((pc) => pc.key === 'ast1')
	},
	AST2 (state) {
		return state.programChannelsList.find((pc) => pc.key === 'ast2')
	},
	BCELIT (state) {
		return state.programChannelsList.find((pc) => pc.key === 'bce_lit')
	},
	BCEECO (state) {
		return state.programChannelsList.find((pc) => pc.key === 'bce_eco')
	},
	establishments (state) {
		return state.establishments
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}

<template>
	<div class="container">
		<a
			v-for="(img, index) in filteredMap"
			:key="`${img.label}${index}`"
			:href="img.link"
			target="_blank"
			rel="noopener noreferrer"
			:data-beaver-id="`imgLinkMap-${index}`"
		>
			<img v-if="img.media" :src="img.media.file" :alt="img.label" class="map" />
		</a>
		<div v-if="filteredMap && filteredMap.length > 0" class="separator" />
		<div
			class="paragraphs"
			:class="flexGapSupported ? '' : 'paragraphs-rg'">
			<h3 v-if="filteredText[0]">
				<span class="text-red">{{ filteredText[0].label }}</span>
			</h3>
			<div
				v-for="(text, index) in filteredText"
				:key="`${text.label}${index}`"
				class="details"
				:class="flexGapSupported ? '' : 'details-rg'"
				v-html="text.content"
				:data-beaver-id="`linkDetail-${index}`"
			/>
		</div>
		<div
			class="logos"
			:class="flexGapSupported ? '' : 'logos-rg'">
			<a
				v-for="(img, index) in filteredLogo"
				:key="`${img.label}${index}`"
				:href="img.link"
				target="_blank"
				rel="noopener noreferrer"
				:data-beaver-id="`linkLogosLogout-${index}`"
			>
				<img v-if="img.media" :src="img.media.file" :alt="img.label" />
			</a>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
	data () {
		return {}
	},
	computed: {
		...mapState('global', ['flexGapSupported']),
		...mapGetters('footer', [
			'filteredMap',
			'filteredText',
			'filteredLogo',
		]),
	},
}
</script>

<style scoped>
.container {
	width: 100%;
    background-color: #f4eee2;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: rgba(0, 0, 0, 0.9);
	padding: 20px;
}
.map {
	max-height: 145px;
}
.separator {
	width: 100px;
    height: 0;
    min-width: 100px;
    max-width: 100px;
    margin: 10px 0;
	border: 0.25px solid var(--grey-2);
	background: var(--grey-2);
}
.paragraphs ,.details {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 8px;
	text-align: center;
}
.paragraphs-rg > *:not(:last-child),
.details-rg > *:not(:last-child)  {
	margin-bottom: 8px;
}
.paragraphs /deep/ p:not(:last-of-type) {
	margin-bottom: 15px !important;
}
.logos{
	display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 15px;
    width: 100%;
    padding: 0 73px;
    row-gap: 10px;
    flex-wrap: wrap;
    column-gap: 29px
}
.logos-rg > *:not(:last-child)  {
	margin-bottom: 10px;
	margin-right: 29px;
}
</style>

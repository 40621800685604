<template>
	<div
		@click.stop
		class="navbar-notif"
		:class="flexGapSupported ? '' : 'navbar-notif-rg'">
		<div class="title">
			<p>Notifications</p>
			<div @click.stop="hideAlerts" class="icon">
				<CloseIcon />
			</div>
		</div>
		<div class="separator" />
		<notif-message />
		<div class="separator" />
		<div @click.stop.prevent="closePanel" class="see-all">
			<router-link
				to="/notifications"
				tag="a"
			>
				<p>Voir toutes mes notifications</p></router-link>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import CloseIcon from '@/assets/icons/CloseIcon.vue'
import NotifMessage from '@/components/Notifications/NotifMessage.vue'

export default {
	name: 'NavBarNotifs',
	data () {
		return {
		}
	},
	components: {
		CloseIcon,
		NotifMessage,
	},
	methods: {
		hideAlerts () {
			this.$parent.showAlerts = false
		},
		closePanel () {
			this.$parent.showAlerts = false
		},
	},
	computed: {
		...mapState('global', ['flexGapSupported']),
	},
}
</script>

<style scoped>
.navbar-notif {
	background: white;
	box-shadow: var(--shadow-card);
	padding: 30px 20px;
	display: flex;
	flex-direction: column;
	width: 282px;
	gap: 16px;
	cursor: default;
}
.navbar-notif-rg > *:not(:last-child)  {
	margin-bottom: 16px;
}
.icon /deep/ svg {
	cursor: pointer;
	height: 18px;
	width: 18px;
}
.title {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.title p {
	font-size: var(--text-size-5);
}
.separator {
	height: 1px;
	width: 100%;
	background-color: var(--light-grey-2);
}
.see-all {
	text-align: start;
}
a p {
    text-decoration : underline;
	font-family: "Raleway", sans-serif;
}
</style>

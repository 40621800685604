/* eslint-disable camelcase */
import authService from '@/services/auth.service'
import { HYDRA_DESCRIPTION } from '@/utils/api'
import postApi from '@/utils/postData'
import router from '@/router'
import tokenUtils from '@/utils/token'
import store from '@/store'
import errorMessageUtils from '@/utils/errorMessage.js'

const state = {
	token: localStorage.getItem('AUTH_TOKEN'),
	refresh_token: localStorage.getItem('REFRESH_TOKEN'),
	isSwitchUser: JSON.parse(localStorage.getItem('ISSWITCHUSER')),
	candidateEmail: localStorage.getItem('CANDIDATE_EMAIL'),
}

const SET_TOKEN = 'SET_TOKEN'
const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN'
const SET_ISSWITCHUSER = 'SET_ISSWITCHUSER'
const SET_CANDIDAT_EMAIL = 'SET_CANDIDAT_EMAIL'

const mutations = {
	[SET_TOKEN] (state, payload) {
		state.token = payload
		localStorage.setItem('AUTH_TOKEN', payload)
	},
	[SET_REFRESH_TOKEN] (state, payload) {
		state.refresh_token = payload
		localStorage.setItem('REFRESH_TOKEN', payload)
	},
	[SET_ISSWITCHUSER] (state, payload) {
		state.isSwitchUser = payload
		localStorage.setItem('ISSWITCHUSER', payload)
	},
	[SET_CANDIDAT_EMAIL] (state, payload) {
		state.candidateEmail = payload
		localStorage.setItem('CANDIDATE_EMAIL', payload)
	},
}

const actions = {
	setToken ({ commit }, { token, refresh_token }) {
		return new Promise((resolve) => {
			commit(SET_TOKEN, token)
			commit(SET_REFRESH_TOKEN, refresh_token)
			resolve()
		})
	},
	connection ({ dispatch }, { email, password }) {
		if (email && password) {
			postApi.post('api/authentication_token', { email, password })
				.then((res) => {
					dispatch('setToken', res.data)
						.then(() => router.push('/dashboard'))
				})
				.catch((err) => {
					const message = errorMessageUtils.getErrorMessage(err)
					store.commit('modal/SHOW_MODAL', {
						name: 'ModalError',
						props: {
							message,
						},
					})
				})
		}
	},
	switchUserConnection ({ dispatch, commit }, { candidateEmail, email, password }) {
		if (candidateEmail && email && password) {
			authService.switchUserConnection({ candidateEmail, email, password })
				.then((res) => {
					commit(SET_ISSWITCHUSER, true)
					commit(SET_CANDIDAT_EMAIL, candidateEmail)
					dispatch('setToken', res.data)
						.then(() => router.push('/dashboard'))
				})
				.catch((err) => {
					const message = errorMessageUtils.getErrorMessage(err)
					store.commit('modal/SHOW_MODAL', {
						name: 'ModalError',
						props: {
							message,
						},
					})
				})
		}
	},
	refresh ({
		state, dispatch,
	}) {
		return new Promise((resolve, reject) => {
			if (state.refresh_token === null) {
				reject('ko')
			}

			if (state.token && !tokenUtils.isTokenExpired(state.token)) {
				resolve('ok')
				return
			}

			postApi.post('api/token/refresh', { refresh_token: state.refresh_token })
				.then((res) => {
					dispatch('setToken', res.data)
						.then(() => router.push('/dashboard'))
					resolve('ok')
				})
				.catch((err) => {
					const message = errorMessageUtils.getErrorMessage(err)
					store.commit('modal/SHOW_MODAL', {
						name: 'ModalError',
						props: {
							message,
						},
					})
					reject(err)
				})
		})
	},
	disconnect ({ dispatch, commit }) {
		commit(SET_ISSWITCHUSER, false)
		commit(SET_CANDIDAT_EMAIL, '')
		dispatch('setToken', { token: '', refresh_token: '' })
			.then(() => router.push('/'))
	},
}

const getters = {
	isAuthenticated () {
		const authToken = state.token
		return authToken ? !tokenUtils.isTokenExpired(authToken) : false
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}

<template>
	<div class="card">
		<div class="questionExp"> Confirmez la suppression de votre rendez-vous </div>
		<div
				class="actions"
				:class="flexGapSupported ? '' : 'actions-rg'">
			<button
					class="red-button"
					@click="no"
					data-beaver-id="btnNo"
			>
				Non
			</button>
			<button
					class="white-button"
					@click="yes"
					data-beaver-id="btnYes"
			>
				Oui
			</button>

		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'ModalCancelOralTest',
	props: ['props'],
	methods: {
		no () {
			this.$parent.hideModal()
			this.$emit('no')
		},
		yes () {
			this.$props.props.yes()
			this.$emit('yes')
		},
	},
	computed: {
		...mapState('global', ['flexGapSupported']),
	},
}
</script>

<style scoped>
.card {
	position: relative;
	min-width: 590px;
	max-width: 590px;
	padding: 36px;
}

.actions {
	display: flex;
	justify-content: center;
	gap: 18px;
}
.actions-rg > *:not(:last-child)  {
	margin-right: 18px;
}

.questionExp {
	text-align: center;
	padding: 26px;
	line-height: 0.5em;
}
</style>

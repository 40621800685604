import store from '@/store'

export default {
	filterProgramChannel (parameter) {
		if (store.state.profile.me.student) {
			return parameter.find((param) => param.programChannels.includes(store.state.profile.me.student.programChannel))
		}
		return parameter.find((param) => {
			return param.programChannels.map((pc) => {
				return store.state.global.programChannelsList.includes(pc)
			})
		})
	},
}
